import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes, FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useURLParams } from '@celito.clients/hooks';
import {
  CustomButton,
  FormActionButtonsContainer,
  GridView,
  Icon,
  Stack,
} from '@celito.clients/shared';
import { Label } from '@fluentui/react-components';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import ReactPaginate from 'react-paginate';

import { ConfirmButton } from '../../components/wizard-container/confirm-button/confirm-button.component';
import { useFormEngineContext } from '../../hooks';
import { getFormActionsVisibilityRules } from '../../utils/helper';
import { associatedCurriculaColumns } from './config/associated-curricula-grid.config';
import { CourseAssociatedCurriculaViewProps } from './course-associated-curricula.model';
import classes from './course-associated-curricula.module.css';

export const CourseAssociatedCurriculaView = ({
  associatedCurricula,
  isLoading,
  paginationProps,
  attributeConfig,
  recordDetails,
  isSubmitting,
  viewDto,
  onSave,
  showFormActions = true,
  className,
  isCurriculaLoading,
  ...props
}: CourseAssociatedCurriculaViewProps): JSX.Element => {
  const { fieldsApiErrors } = useFormEngineContext();
  const isSubmitDisabled =
    isSubmitting ||
    (props.wizardStepsState.some((step) => step.isValid === false) &&
      (fieldsApiErrors?.fields?.length ?? 0) === 0);

  const { urlParams } = useURLParams();
  const {
    formState: { isDirty },
  } = useFormContext();
  const requestType = urlParams.get('requestType');
  const isUpdateRequest = requestType?.includes('Update');
  const isEditAction = !isUpdateRequest;

  const showSubmitConfirmationPopup = Boolean(
    viewDto?.view?.[0]?.showSubmitConfirmationPopup
  );

  const submitConfirmationPopupMessage =
    viewDto?.view?.[0]?.submitConfirmationPopupMessage;

  const { showSaveButton, showSubmitButton } = getFormActionsVisibilityRules(
    attributeConfig?.lifecycleStageGroups,
    recordDetails?.lifecycleStage as string,
    isEditAction ? 'edit' : 'update',
    props.mode
  );

  return (
    <div className={classes.container}>
      <Stack className={cn(classes.innerContainer, className)}>
        <Label size="large">{LocalizationString.ASSOCIATED_CURRICULA}</Label>

        <div className={classes.grid}>
          <GridView
            columns={associatedCurriculaColumns}
            items={associatedCurricula}
            disableDefaultSorting={true}
            disableSelection={true}
            enableShimmer={isCurriculaLoading}
            className={classes.gridView}
          />
          {paginationProps.totalPageCount > 1 && (
            <div className={classes.pagination}>
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <Icon
                    data-testid="icon-pagination-right"
                    iconName="ChevronRight16Regular"
                  />
                }
                forcePage={paginationProps.currentPage - 1}
                onPageChange={({ selected }) =>
                  paginationProps.handlePageUpdate(selected + 1)
                }
                previousLabel={
                  <Icon
                    data-testid="icon-pagination-left"
                    iconName="ChevronLeft16Regular"
                  />
                }
                pageCount={paginationProps.totalPageCount}
                marginPagesDisplayed={1}
                activeClassName={classes.active}
                disabledClassName={classes.disabled}
              />
            </div>
          )}
        </div>
      </Stack>

      {showFormActions && (
        <FormActionButtonsContainer>
          <CustomButton
            buttonTitle={LocalizationString.CANCEL}
            buttonType={ButtonTypes.Ghost}
            onClick={props.onCancel}
          />
          <div className={classes.formActionBtns}>
            <CustomButton
              buttonTitle={LocalizationString.PREVIOUS}
              leftIcon="Previous"
              buttonType={ButtonTypes.Ghost}
              onClick={props.goToPreviousStep}
            />
            <CustomButton
              buttonTitle={LocalizationString.NEXT}
              leftIcon="Next"
              buttonType={ButtonTypes.Ghost}
              onClick={props.goToNextStep}
            />

            {props.mode === FormEngineModeTypeEnum.Edit && showSaveButton && (
              <CustomButton
                buttonTitle="Save"
                leftIcon="Save"
                buttonType={ButtonTypes.Secondary}
                isLoading={isLoading}
                disabled={isLoading || isSubmitting || !isDirty}
                onSubmit={
                  !isLoading
                    ? () => onSave?.(false) as Promise<void>
                    : undefined
                }
              />
            )}
            {showSubmitButton && props.step === props.totalSteps - 1 && (
              <ConfirmButton
                buttonTitle={'Submit'}
                leftIcon="Submit"
                buttonType={ButtonTypes.Primary}
                isLoading={isSubmitting}
                disabled={isSubmitDisabled}
                onSubmit={
                  !isSubmitting
                    ? () => onSave?.(true) as Promise<void>
                    : undefined
                }
                showSubmitConfirmationPopup={showSubmitConfirmationPopup}
                dialogProps={{
                  primaryButtonText: LocalizationString.SUBMIT,
                  title: LocalizationString.SUBMIT,
                  description: submitConfirmationPopupMessage,
                  secondaryButtonText: LocalizationString.CANCEL,
                  iconSrc: WarningIcon,
                }}
              />
            )}
          </div>
        </FormActionButtonsContainer>
      )}
    </div>
  );
};
