import { FormEngineModeTypeEnum } from '@celito.clients/enums';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { getCoursesSelectorGridColumns } from '../config/course-selector.config';
import { getCurriculumsSelectorGridColumns } from '../config/curriculum-selector-config';
import { getDefaultColumnsSelectorGridcolumns } from '../config/default-selector.config';
import { getReferenceDocumentsSelectorGridColumns } from '../config/reference-documents-selector.config';
import { getSmartGroupsSelectorGridColumns } from '../config/smart-groups-selector.config';
import { getUserSelectorGridColumn } from '../config/user-selector.config';
import { ReferenceSelectorItem } from '../reference-selector.model';

export const transformToGridRow: (document: any) => ReferenceSelectorItem = (
  document
) => {
  return {
    ...document,
    label: (document.label as string) || '',
    name: (document.name as string) || '',
    key: (document.name as string) || '',
    followUpApprovalDueDate: (document.followUpApprovalDueDate as string) || '',
    effectiveDate: (document.effectiveDate as string) || '',
  };
};

export const getColumnsConfigByObjectName = (
  objectName: string,
  onRemoveItem?: (item: RowItem) => void,
  mode?: FormEngineModeTypeEnum,
  isPreviewTab?: boolean,
  showDownloadButton?: boolean,
  onDownloadItem?: (item: RowItem) => void,
  onColumnClick?: ColumnData['onColumnClick'],
  onUserView?: (name: string) => void
) => {
  let columns: ColumnData[] = [];
  switch (objectName) {
    case 'controlled_document__a':
      columns = getReferenceDocumentsSelectorGridColumns(
        onRemoveItem,
        isPreviewTab,
        showDownloadButton,
        onDownloadItem,
        onColumnClick
      );
      break;
    case 'course__a':
      columns = getCoursesSelectorGridColumns(
        onRemoveItem,
        isPreviewTab,
        onColumnClick
      );
      break;
    case 'smart_groups__a':
      columns = getSmartGroupsSelectorGridColumns(
        onRemoveItem,
        isPreviewTab,
        onColumnClick
      );
      break;
    case 'user__s':
      columns = getUserSelectorGridColumn(
        onRemoveItem,
        isPreviewTab,
        onUserView,
        onColumnClick
      );
      break;
    case 'curriculum__a':
      columns = getCurriculumsSelectorGridColumns(
        onRemoveItem,
        isPreviewTab,
        onColumnClick
      );
      break;
    default:
      columns = getDefaultColumnsSelectorGridcolumns(
        onRemoveItem,
        isPreviewTab,
        onColumnClick
      );
      break;
  }
  return onRemoveItem && mode !== FormEngineModeTypeEnum.View
    ? columns
    : columns.filter((column) => column.key !== 'actions');
};
