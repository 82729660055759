import { Divider, mergeClasses } from '@fluentui/react-components';
import cn from 'classnames';
import React, { createContext, useContext, useMemo } from 'react';

import { StepperProps, StepProps } from './stepper.model';
import { useStepperStyles } from './stepper.styles';

const StepperContext = createContext({
  activeStep: 0,
});

export const useStepper = () => useContext(StepperContext);

export const Stepper = ({ children, activeStep }: StepperProps) => {
  const styles = useStepperStyles();
  const size = React.Children.count(children);
  const contextValue = useMemo(() => ({ activeStep }), [activeStep]);

  return (
    <StepperContext.Provider value={contextValue}>
      <div
        className={styles.stepperContainer}
        style={{
          gridTemplateColumns: `repeat(${size}, 1fr)`,
        }}
      >
        {React.Children.map(children, (child, index) =>
          React.isValidElement(child) ? (
            <>
              {React.cloneElement(child as React.ReactElement<StepProps>, {
                ...child.props,
                index,
              })}
            </>
          ) : null
        )}
      </div>
    </StepperContext.Provider>
  );
};

export const Step = ({ children, index = 0 }: StepProps) => {
  const { activeStep } = useStepper();
  const styles = useStepperStyles();

  return (
    <div className={styles.stepContainer}>
      {index > 0 && <Divider appearance="brand" className={styles.divider} />}
      <div
        className={mergeClasses(
          styles.step,
          cn({
            [styles.stepActive]: activeStep === index,
          })
        )}
      >
        {index + 1}
      </div>
      <div>{children}</div>
    </div>
  );
};
