import { makeStyles } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const useRecordNavigationStyles = makeStyles({
  listControlsContainer: {
    paddingInline: '1rem',
    display: 'flex',
    marginTop: '-1.25rem',
    height: '1lh',
    color: 'white',
  },

  listControlButton: {
    color: Color.white,
    maxWidth: '1.6rem',
    minWidth: '1.6rem',
  },
});
