import { OperatorsEnum } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import {
  getFilterFields,
  getObjectMetadata,
  IFilterPanelFormData,
} from '@celito.clients/list-view-engine';
import { Filter } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { convertCustomFilter } from '../../in-house-input-select/utils';
import {
  getOnAddRuleFunc,
  getOnRemoveRuleFunc,
} from '../../rules-component/rules-component.component';
import { ICondition, IRule } from '../../rules-component/rules-component.model';
import { RulesComponentListViewFilter } from '../../rules-component/types/rules-component.types';
import {
  defineEmptyCondition,
  transformMultipleRulesIntoFilter,
} from '../../rules-component/utils/utils';
import { getRulesValidationSchema } from '../../rules-component/validation-schema/validation-schema';
import { previewRuleResults } from './services';
import { FilterColumnConfig, ObjectData, ObjectMetadata, User } from './types';
import { UserRulesPickerProps } from './user-rules-picker.model';
import { UserRulesPickerView } from './user-rules-picker.view';
import {
  transformRulesComponentListViewFilterToIRule,
  updateUserNameFieldForUserObject,
} from './utils';

interface UserRulesPickerControllerProps extends UserRulesPickerProps {}

const validationSchema = yup.object().shape({
  rules: getRulesValidationSchema(),
}) as yup.ObjectSchema<IFilterPanelFormData>;

export const UserRulesPickerController = ({
  onChange,
  objectName = 'user__s',
  value,
  error,
  onBlur,
  isTouched,
  ...props
}: UserRulesPickerControllerProps): JSX.Element => {
  const isInitialRender = useRef(true);
  const activeModule = useActiveModule();

  const [allColumns, setAllColumns] = useState<FilterColumnConfig[]>([]);
  const [objectMetadata, setObjectMetadata] = useState<ObjectMetadata | null>(
    null
  );
  const [objectData, setObjectData] = useState<ObjectData<User>>();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [isUserViewPanelOpen, setIsUserViewPanelOpen] = useState(false);
  const [filters, setFilters] = useState<RulesComponentListViewFilter[]>([]);

  const defaultReferenceFilter: Filter[] = [
    {
      attribute: 'isActive',
      operator: OperatorsEnum.EQUALS,
      value: true,
    },
  ];

  useEffect(() => {
    getObjectMetadata(objectName)
      .then((data) => {
        setObjectMetadata(data);
        setAllColumns(
          data.objectAttributeDefinitions
            .filter((col) => !!col.isFilterable)
            .filter(
              (col) =>
                !props.rulesObjectExcludeFilterFields?.some(
                  (field) => field === col.name
                )
            )
            .map((col) => ({
              text: col.label,
              key: col.name,

              dataType: col.dataType,
              name: col.name,
              columnName: col.columnName,
            }))
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  }, [objectName, props.rulesObjectExcludeFilterFields]);

  const loadGridData = useCallback(
    (filters?: RulesComponentListViewFilter[]) => {
      if (filters) {
        const newFilters = convertCustomFilter(
          filters[0],
          defaultReferenceFilter
        );
        setFilters(newFilters);
        setLoading(true);
        previewRuleResults(
          objectName,
          newFilters,
          activeModule ? [activeModule?.systemName] : []
        )
          .then((r: ObjectData<User> | undefined) => {
            setObjectData(r);
            setLoading(false);
          })
          .catch((_error) => {
            setLoading(false);
            raiseErrorToast(_error);
          });
      }
    },
    [objectMetadata, objectName]
  );

  const defineDefaultValues = (): IFilterPanelFormData => {
    return {
      rules: [
        {
          conditionType: 'all',
          isDefault: true,
          groups: [
            {
              conditions: [defineEmptyCondition()],
              conditionType: 'all',
            },
          ],
          label: '',
        },
      ],
    };
  };

  const methods = useForm<IFilterPanelFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defineDefaultValues(),
  });

  const { control, reset, watch } = methods;

  const watchedRules = watch('rules', []);

  const {
    fields: rules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'rules',
  });

  const filteringValueString = JSON.stringify(watchedRules);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (filteringValueString) {
        methods
          .trigger()
          .then((isValid) => isValid && onChange?.({ rules: watchedRules }));
      }
    }
  }, [filteringValueString]);

  const applyFilters = useCallback(
    (newFilters: RulesComponentListViewFilter, doApiCall = false) => {
      if (!newFilters && filters?.length === 0 && !doApiCall) {
        return;
      }
      setFilters([newFilters]);

      if (watchedRules) {
        let modifiedRules = watchedRules;
        if (
          watchedRules.some((rule) =>
            rule.groups.some((group) =>
              group.conditions.some(
                (condition) => condition.fact !== 'isActive'
              )
            )
          )
        )
          modifiedRules = modifiedRules.map((modified) => {
            return {
              ...modified, // Copy the existing properties of each modified item
              groups: [
                ...modified.groups, // Spread the existing groups
                {
                  conditionType: 'all',
                  conditions: [
                    {
                      fact: 'isActive',
                      operator: OperatorsEnum.EQUALS,
                      value: true,
                    },
                  ],
                },
              ],
            };
          });

        onChange?.({ rules: modifiedRules });
      }

      loadGridData([newFilters]);
    },
    [filters, watchedRules]
  );

  const removeIsActiveFromRules = (rules: IRule[]): IFilterPanelFormData => {
    const formattedRules = rules.map((rule) => ({
      ...rule,
      groups: rule.groups.filter(
        (group) =>
          !group.conditions.some((condition) => condition.fact === 'isActive')
      ),
    }));

    return { rules: formattedRules };
  };

  useEffect(() => {
    if (isInitialRender.current && allColumns.length > 0) {
      if (value) {
        const filtersApplied = JSON.parse(
          value
        ) as RulesComponentListViewFilter[];
        if (filtersApplied.length === 0) {
          setFilters([]);
          setLoading(false);
          isInitialRender.current = false;
          return;
        }
        loadGridData(filtersApplied);
        const rulesApplied =
          transformRulesComponentListViewFilterToIRule(filtersApplied);

        reset(removeIsActiveFromRules(rulesApplied.rules));
      }
      isInitialRender.current = false;
    }
  }, [allColumns, value]);

  const conditionFieldsData = useMemo(() => {
    const fieldData = getFilterFields(
      allColumns || [],
      objectMetadata?.objectAttributeDefinitions ?? []
    );
    updateUserNameFieldForUserObject(fieldData, objectName);
    return fieldData.filter((field) => field.value !== 'isActive');
  }, [allColumns, objectMetadata?.objectAttributeDefinitions]);

  const onApplyFilter: SubmitHandler<IFilterPanelFormData> = (
    data: IFilterPanelFormData
  ) => {
    const filters = transformMultipleRulesIntoFilter(
      data.rules,
      objectMetadata!
    );
    if (filters && filters?.[0]?.filtersToBeApplied?.[0])
      applyFilters(filters?.[0]?.filtersToBeApplied?.[0]);
  };

  const onUserView = (name: string) => {
    setIsUserViewPanelOpen(true);
    setUserName(name);
  };

  const onDismiss = () => {
    setIsUserViewPanelOpen(false);
  };

  return (
    <UserRulesPickerView
      objectData={objectData}
      loading={loading}
      filters={filters}
      objectMetadata={objectMetadata}
      methods={methods}
      conditionFieldsData={conditionFieldsData}
      onAddRule={getOnAddRuleFunc(append)}
      onRemoveRule={getOnRemoveRuleFunc(remove)}
      rules={rules}
      onApplyFilter={onApplyFilter}
      isUserViewPanelOpen={isUserViewPanelOpen}
      userName={userName}
      onDismiss={onDismiss}
      onUserView={onUserView}
      {...props}
    />
  );
};
