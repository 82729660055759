import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@fluentui/react-components';
import { DocumentRegular } from '@fluentui/react-icons';
import cn from 'classnames';
import { ChangeEvent, DragEvent, useState } from 'react';

import { Icon } from '../icon';
import { IconButton } from '../shared';
import { FilePickerProps } from './file-picker.model';
import { fieldPikerStyles } from './file-picker.styles';
import useFilePicker from './useFilePicker';

const FilePicker = <T extends boolean = false>({
  value,
  multiple,
  allowedExtensions = [],
  onChange,
  onError,
}: FilePickerProps<T>) => {
  const styles = fieldPikerStyles();

  const [isDragging, setIsDragging] = useState(false);

  const { files, handleFiles, removeFile } = useFilePicker<T>({
    value,
    multiple,
    allowedExtensions,
    onChange,
    onError,
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;
    handleFiles(selectedFiles);
  };

  const handleDrop = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = e.dataTransfer.files;
    handleFiles(droppedFiles);
  };

  const handleDragOver = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault();
    if (!isDragging) setIsDragging(true);
  };

  const handleDragEnter = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault();
    setIsDragging(false);
  };

  return (
    <div>
      {(multiple || (!multiple && !files.length)) && (
        <label
          tabIndex={0}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          htmlFor="file-upload"
          aria-label="Upload a file"
          className={cn(styles.label, { [styles.draggingBorder]: isDragging })}
          data-testid={`file-upload`}
        >
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            multiple={multiple}
            aria-label="Select files"
            accept={allowedExtensions.join(',')}
          />
          <Icon iconName="ArrowUpload24Regular" />
          {isDragging ? (
            <>
              <p className={styles.draggingLabel}>Drop the file here</p>
              <span>&nbsp;</span>
            </>
          ) : (
            <>
              <p>
                <span className={styles.blueText}>Choose file</span> or{' '}
                <span className={styles.strong}>drag here</span> to upload
              </p>
              {allowedExtensions.length > 0 ? (
                <span>
                  Supports:
                  {allowedExtensions.map((ext) => ext.toUpperCase()).join(', ')}
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </>
          )}
        </label>
      )}

      {files.length > 0 && (
        <div
          style={{
            padding: '20px',
            backgroundColor: '#fafafa',
            borderRadius: '12px',
          }}
        >
          <Table size="small">
            <TableHeader>
              <TableRow
                style={{
                  borderBottom: 'none',
                }}
              >
                <TableHeaderCell>File</TableHeaderCell>
                <TableHeaderCell
                  style={{
                    width: '3rem',
                  }}
                ></TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {files.map((file) => (
                <TableRow
                  key={file.name}
                  style={{
                    borderBottom: 'none',
                  }}
                >
                  <TableCell>
                    <TableCellLayout media={<DocumentRegular />}>
                      {file.name}
                    </TableCellLayout>
                  </TableCell>
                  <TableCell>
                    <TableCellLayout
                      style={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <IconButton
                        icon={<Icon color="red" iconName="Delete16Filled" />}
                        onClick={() => removeFile(file)}
                      />
                    </TableCellLayout>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default FilePicker;
