import { FormEngineModeTypeEnum, ObjectEnum } from '@celito.clients/enums';
import { Loader, Stack, StackItem } from '@celito.clients/shared';
import { LayoutType, Section } from '@celito.clients/types';
import React from 'react';
import { FormProvider } from 'react-hook-form';

import { useFormEngineContext } from '../../hooks';
import { getFormValues } from '../../utils/helper';
import { SingleLayoutContainer } from '../wizard-container/single-layout-container/single-layout-container.component';
import WizardContainer from '../wizard-container/wizard-container.component';
import WizardState from '../wizard-state/wizard-state.component';
import WizardSteps from '../wizard-steps/wizard-steps.component';
import { CompleteTaskView } from './custom/complete-view/complete-view.component';
import { FormWizardViewProps } from './form-wizard.model';
import classes from './form-wizard.module.css';

interface FormWizardCustomViewProps extends FormWizardViewProps {
  setStep: React.Dispatch<React.SetStateAction<number>> | undefined;
  setWizardConfig: React.Dispatch<React.SetStateAction<Section[] | undefined>>;
}

const FormWizardView: React.FC<FormWizardCustomViewProps> = (
  props
): JSX.Element => {
  const {
    children,
    dataTestId,
    layout = LayoutType.TWO_LAYOUT,
    viewName = '',
    recordName,
    stepsConfig = [],
    isLoaded = true,
    attributeConfig,
    objectName,
    mode,
    editRoute,
    wizardState,
    setWizardState,
    step,
    setStep,
    totalSteps,
    goToNextStep,
    goToPreviousStep,
    goToEditScreen,
    onStepClick,
    submitUrl,
    listViewName,
    showCancelPopup,
    labelFormat,
    viewDto,
    progressTabs = [],
    title,
    description,
    recordData,
    setRecordData,
    methods,
    wizardStepsState,
    setWizardStepsState,
    setWizardConfig,
    fetchRecordDetailsAPI,
    shouldRefetchTask,
    task,
    setTask,
    ...rest
  } = props;

  const { isWizardStateVisible: showWizardState } = useFormEngineContext();

  return !isLoaded && attributeConfig ? (
    <FormProvider {...methods}>
      {layout === LayoutType.TWO_LAYOUT ? (
        <Stack data-testid={dataTestId} className={classes.container} {...rest}>
          {(recordData?.name && objectName) || shouldRefetchTask ? (
            <CompleteTaskView
              recordData={recordData}
              objectName={objectName!}
              setRecordData={setRecordData}
              task={task}
              setTask={setTask}
              mode={props.mode}
            />
          ) : null}

          {showWizardState && (
            <StackItem className={classes.top_container}>
              <WizardState
                objectName={attributeConfig?.name as ObjectEnum}
                wizardState={wizardState}
                recordName={recordName}
                progressTabs={progressTabs}
                recordData={recordData}
                objectActionDefinitions={
                  attributeConfig?.objectActionDefinitions
                }
                objectDefinitions={attributeConfig}
                onRefetchRequest={fetchRecordDetailsAPI}
                task={task}
              />
            </StackItem>
          )}
          <Stack className={classes.column}>
            <StackItem className={classes.left_container}>
              <WizardSteps
                sections={stepsConfig}
                step={step}
                onStepClick={onStepClick}
                mode={mode}
                wizardStepsState={wizardStepsState}
                recordDetails={
                  recordData ? getFormValues(attributeConfig, recordData) : {}
                }
                setWizardConfig={setWizardConfig}
              />
            </StackItem>
            <div className={classes.divider}></div>
            <StackItem className={classes.right_container}>
              {attributeConfig && (
                <WizardContainer
                  step={step}
                  setStep={setStep}
                  sections={stepsConfig}
                  attributeConfig={attributeConfig}
                  objectName={objectName}
                  recordName={recordName}
                  mode={mode}
                  goToPreviousStep={goToPreviousStep}
                  goToNextStep={goToNextStep}
                  goToEditScreen={goToEditScreen}
                  totalSteps={totalSteps}
                  viewName={viewName}
                  submitUrl={submitUrl}
                  setWizardState={setWizardState}
                  editRoute={editRoute || ''}
                  listViewName={listViewName}
                  onStepClick={onStepClick}
                  showCancelPopup={showCancelPopup}
                  labelFormat={labelFormat}
                  viewDto={viewDto}
                  isCustomView={mode === FormEngineModeTypeEnum.CustomView}
                  recordData={recordData}
                  setRecordData={setRecordData}
                  wizardStepsState={wizardStepsState}
                  setWizardStepsState={setWizardStepsState}
                  isWizardStateVisible={showWizardState}
                />
              )}
            </StackItem>
          </Stack>
        </Stack>
      ) : (
        <Stack data-testid={dataTestId} className={classes.container} {...rest}>
          {(recordData?.name && objectName) || shouldRefetchTask ? (
            <CompleteTaskView
              recordData={recordData}
              objectName={objectName!}
              setRecordData={setRecordData}
              task={task}
              setTask={setTask}
              mode={props.mode}
            />
          ) : null}

          <StackItem className={classes.singleLayoutInnerContainer}>
            {attributeConfig && (
              <SingleLayoutContainer
                sections={stepsConfig}
                attributeConfig={attributeConfig}
                objectName={objectName}
                recordName={recordName}
                mode={mode}
                viewName={viewName}
                onStepClick={onStepClick}
                goToEditScreen={goToEditScreen}
                listViewName={listViewName}
                showCancelPopup={showCancelPopup}
                labelFormat={labelFormat}
                submitUrl={submitUrl}
                recordData={recordData}
                setRecordData={setRecordData}
              />
            )}
          </StackItem>
        </Stack>
      )}
    </FormProvider>
  ) : (
    <Stack className={classes.page_container}>
      <Loader fullPage />
    </Stack>
  );
};

export default FormWizardView;
