import { useCallbackPrompt, useLayout } from '@celito.clients/hooks';
import {
  editFormSubmitApi,
  getRecordDetailApi,
} from '@celito.clients/services';
import { LayoutRulesDataSchema } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEngineContextProvider } from 'libs/form-engine/src/lib/context';
import useFormApiErrorHandler from 'libs/form-engine/src/lib/hooks/use-form-api-error-handler';
import { useLayoutRules } from 'libs/form-engine/src/lib/hooks/useLayoutRules';
import {
  getFormValues,
  refactorFormData,
} from 'libs/form-engine/src/lib/utils/helper';
import { generateYupSchemaFromLayoutRules } from 'libs/form-engine/src/lib/utils/validator-generator';
import { FileDataProps } from 'libs/shared/src/lib/multi-file-upload/multi-file-upload.model';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { SingleLayoutContainerProps } from '../single-layout-container.model';
import { EditLayoutView } from './edit.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const EditLayoutController = (props: SingleLayoutContainerProps) => {
  const {
    sections = [],
    attributeConfig: objectDefinition,
    objectName,
    recordName,
    labelFormat,
    submitUrl,
  } = props;

  const { configureLayout } = useLayout();

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isRecordDataLoading, setIsRecordDataLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject>
  >({} as yup.ObjectSchema<yup.AnyObject>);

  const { handleFormApiError } = useFormApiErrorHandler();

  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    formState: { dirtyFields, isDirty },
  } = methods;
  const attachmentsPropName = 'attachments__a';
  // for system objects the attachment key name will be attachments__s
  const attachmentSystemPropName = 'attachments__s';
  const formData = watch();
  // using stringified version to avoid multiple re-renders
  const formDataStringified = JSON.stringify(formData);
  const { fieldsState, setFieldsState } = useLayoutRules(
    formData,
    sections?.[0] ?? []
  );
  const fieldsStateStringified = JSON.stringify(fieldsState);

  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified) as Record<
      string,
      LayoutRulesDataSchema
    >;

    const newSchema = generateYupSchemaFromLayoutRules(
      watch(),
      sections?.[0]?.fields ?? [],
      objectDefinition?.objectValidationRules ?? [],
      objectDefinition?.objectAttributeDefinitions ?? [],
      fieldsStateParsed
    );

    if (
      newSchema &&
      typeof newSchema === 'object' &&
      Object.keys(newSchema).length > 0
    ) {
      setValidationSchema(newSchema);
    }
  }, [
    formDataStringified,
    fieldsStateStringified,
    watch,
    sections?.[0]?.fields,
  ]);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    !!props.showCancelPopup && isDirty && !isCancelModalOpen
  );

  const toggleWarningModalBtnClick = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const toggleSaveModalBtnClick = () => {
    setIsSaveModalOpen(!isSaveModalOpen);
    reset({ ...getValues(), isDirty: false });

    if (isSaveModalOpen) {
      if (submitUrl) {
        navigate(`../${submitUrl}`);
        return;
      }
      navigate(-1);
    }
  };

  const toggleCancelModalBtnClick = (navigateBack?: boolean) => {
    setIsCancelModalOpen((prev) => !prev);
    if (navigateBack) {
      reset({ isDirty: false });
      confirmNavigation();
      navigate(-1);
    }
  };

  const onCancel = () => {
    if (!methods.formState.isDirty || !props.showCancelPopup) {
      onRouteBack();
    } else {
      setIsCancelModalOpen(true);
    }
  };

  const onRouteBack = () => {
    navigate(-1);
  };

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(onSave);
  };

  const callFormApi = async (payload: FormData) => {
    setIsDataSaved(true);
    try {
      const params = {
        ...(labelFormat && { recordName: payload?.get('name__s') }),
        ...(Object.keys(dirtyFields).length === 1 &&
          dirtyFields?.['is_active__s'] && {
            onlyIsActiveUpdated: true,
          }),
      };
      payload.delete('name__s');
      if (labelFormat) {
        payload.delete('label__s');
      }
      await editFormSubmitApi(objectName, recordName, payload, params);
      setValue(`deleted_${attachmentsPropName}`, '');
      setValue(`deleted_${attachmentSystemPropName}`, '');
      confirmNavigation();

      toggleSaveModalBtnClick();
    } catch (_error) {
      handleFormApiError(_error, sections, setError);
    } finally {
      setIsDataSaved(false);
    }
  };

  const callRecordDetailApi = async (
    objectName: string,
    recordName: string
  ) => {
    try {
      const response = await getRecordDetailApi(objectName, recordName);
      if (response?.title)
        configureLayout({
          pageTitle: '',
          enablePadding: false,
          headerTitle: (response?.title as string) || '',
        });

      reset(getFormValues(objectDefinition, response));
      props?.setRecordData?.(response);
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsRecordDataLoading(false);
    }
  };

  const onSave = async () => {
    await handleSubmit(
      (rawData) => {
        const data = refactorFormData(objectDefinition, rawData);
        data.file__a = watch('file__a');

        if (!data.file__a) {
          delete data?.['file__a'];
        }
        delete data?.['isDirty'];
        const attachments =
          watch(attachmentsPropName) || watch(attachmentSystemPropName);
        if (attachments?.length > 0) {
          data.secondary_file__a = attachments.filter(
            // only File type should be passed to secondary_file__a and will act as append in the DB
            (attachment: File | FileDataProps) => attachment instanceof File
          );
        }
        const markedForDeleteAttachments =
          watch(`deleted_${attachmentsPropName}`) ??
          watch(`deleted_${attachmentSystemPropName}`) ??
          [];

        delete data?.[attachmentsPropName];
        delete data?.[attachmentSystemPropName];
        delete data?.[`deleted_${attachmentsPropName}`];
        delete data?.[`deleted_${attachmentSystemPropName}`];
        const formData = new FormData();
        if (markedForDeleteAttachments.length > 0) {
          formData.append(
            'deletedDocumentIds',
            JSON.stringify(markedForDeleteAttachments)
          );
        }

        Object.keys(data).forEach((key) => {
          if (
            data[key] !== undefined &&
            data[key] !== null &&
            data[key] !== '' &&
            !Number.isNaN(data[key])
          ) {
            if (Array.isArray(data[key]) && key === 'secondary_file__a') {
              for (const item of data[key]) {
                formData.append(key, item);
              }
              return;
            }
            if (Array.isArray(data[key])) {
              return formData.append(key, JSON.stringify(data[key]));
            }
            formData.append(key, data[key]);
          }
        });
        callFormApi(formData);
      },
      (_err) => {
        toggleWarningModalBtnClick();
      }
    )();
  };

  useEffect(() => {
    setIsRecordDataLoading(true);
    if (objectName && recordName) callRecordDetailApi(objectName, recordName);
    configureLayout({
      pageTitle: '',
      enablePadding: false,
      headerTitle: '',
      showHeadingLoader: false,
    });
  }, []);

  return (
    <FormEngineContextProvider {...{ fieldsState, setFieldsState }}>
      <FormProvider {...methods}>
        <EditLayoutView
          {...{
            onCancel,
            attributeConfig: objectDefinition,
            control,
            onSave,
            handleSubmit,
            onFormSubmit,
            watch,
            setValue,
            trigger,
            sections,
            isSaveModalOpen,
            isWarningModalOpen,
            toggleWarningModalBtnClick,
            toggleSaveModalBtnClick,
            mode: props.mode,
            methods,
            isCancelModalOpen,
            toggleCancelModalBtnClick,
            isLoading: isDataSaved,
            showSaveBtn: isDirty,
            isRecordDataLoading: isRecordDataLoading,
            showPrompt,
            confirmNavigation,
            cancelNavigation,
          }}
          {...props}
        />
      </FormProvider>
    </FormEngineContextProvider>
  );
};
