import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import {
  AttributeTypeEnum,
  DocumentSubTypeObjectKeyEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import {
  ConfirmDialog,
  FieldSelector,
  InHouseInputSelect,
  Loader,
  TextField,
} from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { useRef, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';

import { FormMode } from '../../../document-templates/types';
import { ActionsFooter } from '../../components/actions';
import DocumentSubType from '../../components/document-sub-type';
import DocumentType from '../../components/document-type';
import { Field } from '../../components/field';
import { FollowUpFields } from '../../components/follow-up';
import { ActionsSetter } from '../../components/setter';
import { TComponentProps } from '../../types';
import { useController } from './controller';

function CreateDocumentTypePageContent(props: Readonly<TComponentProps>) {
  const {
    form,
    styles,
    fields,
    handleSubmit,
    navigation: { confirmNavigation, cancelNavigation, showPrompt },
    isSaveModalOpen,
    toggleSaveModalBtnClick,
    templateNames,
    onCancel,
    toggleCancelModalBtnClick,
    isCancelModalOpen,
    docTypeAttributes,
    docSubtypeAttributes,
    isLoading,
    isDataReady,
  } = useController(props);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isInList, setIsInList] = useState(false);

  const handleIsInList = (value: boolean) => {
    setIsInList(value);
  };

  // Show loading state while data is being fetched
  if (isLoading || !isDataReady) {
    return <Loader fullPage />;
  }

  if (!docTypeAttributes || !docSubtypeAttributes) {
    return <Loader fullPage />;
  }

  const handleInsertString = (currentValue: string, value: string): string => {
    if (inputRef.current) {
      const startPos = inputRef.current.selectionStart || 0;
      const endPos = inputRef.current.selectionEnd || 0;
      return (
        currentValue.substring(0, startPos) +
        value +
        currentValue.substring(endPos)
      );
    }
    return currentValue;
  };

  return (
    <FormProvider {...form}>
      <form className={styles.formContainer} noValidate>
        <DocumentType
          attributes={docTypeAttributes}
          handleIsInList={handleIsInList}
        />
        <DocumentSubType
          attributes={docSubtypeAttributes}
          isInList={isInList}
        />
        {fields.map((field) => {
          const { attribute } = field;
          if (
            !attribute?.dataTypeKeyForFE ||
            attribute?.dataTypeKeyForFE === AttributeTypeEnum.Reference
          )
            return null;

          if (attribute.name === 'templateName')
            return (
              <InHouseInputSelect
                key={field.name}
                label={docTypeAttributes.documentTemplate?.label}
                helperLabelText={docTypeAttributes.documentTemplate?.helpText}
                required={attribute.isMandatory}
                options={templateNames}
                selectedOptions={{
                  value: form.watch('templateName'),
                  text:
                    templateNames.find(
                      (tmp) => tmp.value === form.watch('templateName')
                    )?.text ?? '',
                }}
                errorMessage={form.formState.errors.templateName?.message}
                onOptionSelect={(_e, data) => {
                  if (data.optionValue) {
                    form.setValue('templateName', data.optionValue, {
                      shouldDirty: true,
                    });
                    form.trigger('templateName');
                  }
                }}
              />
            );

          if (attribute.name === 'labelFormat') {
            return (
              <Controller
                key={field.name}
                control={form.control}
                name="labelFormat"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Label Format"
                    value={field.value}
                    inputRef={inputRef}
                    name="labelFormat"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    errorMessage={error?.message}
                    helperLabelText={
                      'Label Format is inherited from the document level. Setting a value at this level will override the inherited Label Format.'
                    }
                    contentAfter={
                      <FieldSelector
                        marginTop={0}
                        onCopy={(value) => {
                          const newValue = handleInsertString(
                            field.value || '',
                            value
                          );
                          field.onChange(newValue);
                        }}
                        enableCopyClipboard={false}
                        objectName={ObjectEnum.CONTROLLED_DOCUMENT}
                      />
                    }
                  />
                )}
              />
            );
          }
          const attributeDetails =
            docSubtypeAttributes[
              attribute.name as DocumentSubTypeObjectKeyEnum
            ];

          const updatedAttribute = {
            ...attribute,
            label: attributeDetails?.label,
            helpText: attributeDetails?.helpText,
          };
          return <Field key={field.name} attribute={updatedAttribute} />;
        })}
        <FollowUpFields attributes={docTypeAttributes} />
      </form>
      <ActionsSetter actions={[]} />
      <ActionsFooter
        disableSubmit={isInList}
        mode={props.mode}
        handleSubmit={handleSubmit}
        onCancel={onCancel}
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SAVE_REQUEST
        )}`}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={
          props.mode === FormMode.CREATE
            ? LocalizationString.CREATE_REQUEST
            : LocalizationString.UPDATE_REQUEST
        }
        iconSrc={SaveIcon}
        description={
          props.mode === FormMode.CREATE
            ? LocalizationString.CREATED_DESCRIPTION
            : LocalizationString.UPDATED_DESCRIPTION
        }
      />
      <ConfirmDialog
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
    </FormProvider>
  );
}

function CreateDocumentTypePage(props: Readonly<TComponentProps>) {
  return <CreateDocumentTypePageContent {...props} />;
}

CreateDocumentTypePage.displayName = 'Create Document Type Page';

export default CreateDocumentTypePage;
