export enum ImportStepEnum {
  Upload = 'Upload',
  Select = 'Select',
  Review = 'Review',
  Import = 'Import',
  Result = 'Result',
}

export interface ImportOperation {
  label: string;
  name: string;
}

export interface ErrorState {
  file: string;
  metadata: string;
  importOperation: string;
  itemsErrors: string[];
  itemsDependencies: string[];
}
