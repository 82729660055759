import { ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  FormActionButtonsContainer,
  Loader,
  Stack,
} from '@celito.clients/shared';
import {
  Field,
  LayoutRulesDataSchema,
  ObjectAttributeType,
  Section,
} from '@celito.clients/types';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { FieldValues } from 'react-hook-form';

import { getFieldDataObject } from '../../../utils/attribute-config';
import {
  getFormValues,
  getUserActions,
  groupFieldsByRow,
} from '../../../utils/helper';
import { getLayoutRules } from '../../../utils/layout-rules';
import { ContextMenu } from '../../context-menu/context-menu-actions-component';
import { UserActions } from '../../form-wizard/form-wizard.model';
import FormContainer from '../../helper-components/form-container/form-container';
import DisplayView from '../../view-components/display-view/display-view.component';
import { getPageComponent } from '../page-component/page-component';
import { SectionView } from '../section/section';
import { ViewScreenProps } from './view-screen.model';
import classes from './view-screen.module.css';

const getFieldSection = (
  columnName: string,
  width: number,
  recordDetails: Record<string, unknown>,
  attributeConfig: ObjectAttributeType,
  objectName: string,
  index: number,
  fieldState: Record<string, LayoutRulesDataSchema>
) => {
  const {
    label = '',
    value,
    dataType,
    dataTypeKeyForFE,
  } = getFieldDataObject(columnName, recordDetails, attributeConfig);
  return (
    <SectionView
      width={width}
      key={index}
      style={{
        ...(fieldState[columnName].isHidden && {
          display: 'none',
        }),
      }}
    >
      <DisplayView
        label={label}
        value={value}
        dataType={dataType}
        objectName={objectName}
        dataTypeKeyForFE={dataTypeKeyForFE as string}
        objectAttributeDefinition={attributeConfig.objectAttributeDefinitions.find(
          (item) => item.name === columnName
        )}
        attributeConfig={attributeConfig}
        recordDetails={recordDetails}
      />
    </SectionView>
  );
};

const ViewScreenView: React.FC<ViewScreenProps> = (props) => {
  const {
    sections = [],
    attributeConfig,
    step,
    recordDetails,
    totalSteps,
    goToNextStep,
    goToPreviousStep,
    onCancel,
    isRecordDataLoading = false,
    callRecordDetailApi,
  } = props;

  const groupedFieldsByRow = groupFieldsByRow(sections[step]?.fields || []);

  const Row = ({
    fields,
    fieldsState,
  }: {
    fields: Field[];
    fieldsState: Record<string, LayoutRulesDataSchema>;
  }) => {
    if (fields.every((field) => !fieldsState[field.columnName]?.isVisible)) {
      return null;
    }

    return (
      <Stack className={classes.row}>
        {fields.map((field, index) => {
          if (!fieldsState[field.columnName]?.isVisible) {
            return null;
          }

          return getFieldSection(
            field.columnName,
            field.width,
            props.recordDetails!,
            props.attributeConfig,
            props.objectName!,
            index,
            fieldsState
          );
        })}
      </Stack>
    );
  };
  const sectionTypeLayout = (
    sections: Section[],
    onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => {
    if (sections[step]?.pageComponent) {
      return getPageComponent(sections[step].pageComponent, onCancel, props);
    }

    if (sections[step]?.fields.length > 0) {
      const fieldsState = getLayoutRules(
        getFormValues(attributeConfig, recordDetails) as FieldValues,
        sections[step]
      );

      return (
        <>
          <FormContainer className={classes.rowContainer}>
            {Object.entries(groupedFieldsByRow).map(([row, fields]) => (
              <Row key={row} fields={fields} fieldsState={fieldsState} />
            ))}
          </FormContainer>

          <FormActionButtonsContainer>
            <CustomButton
              buttonTitle="Cancel"
              buttonType={ButtonTypes.Ghost}
              onClick={onCancel}
            />
            <div className={classes.formActionBtns}>
              {step > 0 && (
                <CustomButton
                  buttonTitle="Previous"
                  leftIcon="Previous"
                  buttonType={ButtonTypes.Ghost}
                  onClick={goToPreviousStep}
                />
              )}
              {step < totalSteps - 1 && (
                <CustomButton
                  buttonTitle="Next"
                  leftIcon="Next"
                  buttonType={ButtonTypes.Ghost}
                  onClick={goToNextStep}
                />
              )}
            </div>
          </FormActionButtonsContainer>
        </>
      );
    }
  };

  const actions = getUserActions(
    (recordDetails?.recordUserActions ??
      recordDetails?.recordObjectUserActions) as UserActions[],
    props?.attributeConfig,
    props?.recordDetails as RowItem,
    props.viewDto,
    (recordDetails?.recordObjectUserActions as UserActions[])?.length > 0
  );

  return isRecordDataLoading ? (
    <Stack className={classes.ht}>
      <Loader fullPage />
    </Stack>
  ) : (
    <>
      {recordDetails?.name && actions.length ? (
        <div className={classes.headerButtonContainer}>
          <ContextMenu
            recordName={props?.recordDetails?.name as string}
            objectName={props?.attributeConfig?.name as string}
            actions={actions}
            version={props?.recordDetails?.version as string}
            response={props?.recordDetails}
            refetchRecordData={() =>
              callRecordDetailApi(props.objectName!, props.recordName!)
            }
            objectLabel={props?.attributeConfig?.label ?? ''}
          />
        </div>
      ) : null}
      <Stack className={classes.formContainer}>
        {sectionTypeLayout(sections, onCancel)}
      </Stack>
    </>
  );
};

export default ViewScreenView;
