import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes, FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useURLParams } from '@celito.clients/hooks';
import {
  CustomButton,
  FormActionButtonsContainer,
  PreviewPanel,
  Stack,
} from '@celito.clients/shared';
import { Field, LayoutRulesDataSchema } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { FieldValues, useFormContext } from 'react-hook-form';

import { getFieldSection } from '../../components/helper-components';
import FormContainer from '../../components/helper-components/form-container/form-container';
import { ConfirmButton } from '../../components/wizard-container/confirm-button/confirm-button.component';
import { useFormEngineContext } from '../../hooks';
import {
  getFormActionsVisibilityRules,
  groupFieldsByRow,
} from '../../utils/helper';
import { getLayoutRules } from '../../utils/layout-rules';
import { CurriculumPreviewPageProps } from './curriculum-preview-page.model';
import classes from './curriculum-preview-page.module.css';
import { getSectionData } from './utils';

interface CurriculumPreviewPageViewProps extends CurriculumPreviewPageProps {}

export const CurriculumPreviewPageView = ({
  onCancel,
  step,
  goToPreviousStep,
  onSave,
  isSubmitting,
  viewDto,
  wizardStepsState,
  watch,
  isLoading,
  isWizardStateVisible,
  ...props
}: CurriculumPreviewPageViewProps): JSX.Element => {
  const { urlParams } = useURLParams();
  const {
    formState: { isDirty },
  } = useFormContext();

  const previewableSections = props.sections!.filter(
    (section) => section.name !== 'preview_course_plan_section__a'
  );

  let formData = watch?.();
  if (props.mode === FormEngineModeTypeEnum.View) {
    formData = props.recordDetails;
  }

  const showSubmitConfirmationPopup = Boolean(
    viewDto?.view?.[0]?.showSubmitConfirmationPopup
  );

  const submitConfirmationPopupMessage =
    viewDto?.view?.[0]?.submitConfirmationPopupMessage;

  const { fieldsApiErrors } = useFormEngineContext();

  const isSubmitDisabled =
    isLoading ||
    isSubmitting ||
    (wizardStepsState.some((step) => step.isValid === false) &&
      (fieldsApiErrors?.fields?.length ?? 0) === 0);

  const Row = ({
    fields,
    fieldsState,
  }: {
    fields: Field[];
    fieldsState: Record<string, LayoutRulesDataSchema>;
  }) => {
    if (fields.every((field) => !fieldsState[field.columnName]?.isVisible)) {
      return null;
    }

    return (
      <Stack className={classes.row}>
        {fields.map((field, index) => {
          if (!fieldsState[field.columnName]?.isVisible) {
            return null;
          }

          return getFieldSection(
            field.columnName,
            field.width,
            props.recordDetails!,
            props.attributeConfig,
            props.objectName!,
            index,
            true
          );
        })}
      </Stack>
    );
  };
  const requestType = urlParams.get('requestType');
  const isUpdateRequest = requestType?.includes('Update');
  const isEditAction = !isUpdateRequest;

  const { showSaveButton, showSubmitButton } = getFormActionsVisibilityRules(
    props?.attributeConfig?.lifecycleStageGroups,
    props?.recordDetails?.lifecycleStage as string,
    isEditAction ? 'edit' : 'update'
  );

  return (
    <div className={classes.container}>
      <FormContainer>
        {previewableSections.map((section, stepNumber) => {
          const sectionData = getSectionData(
            props.sections,
            stepNumber,
            props.attributeConfig,
            props.recordDetails
          );

          const fieldsState = getLayoutRules(
            formData as FieldValues,
            sectionData
          );

          const groupedFieldsByRow = groupFieldsByRow(sectionData.fields || []);

          if (Object.keys(groupedFieldsByRow).length === 0) {
            return;
          }

          return (
            <div className={classes.previewContainer} key={section.label}>
              <PreviewPanel
                title={section.label}
                onEditPress={() => {
                  props.onStepClick(stepNumber);
                }}
              >
                <Stack
                  data-testid={`preview-${createTestAttribute(
                    section.label
                  )}-section`}
                  className={classes.rowContainer}
                >
                  {Object.entries(groupedFieldsByRow).map(([row, fields]) => (
                    <Row key={row} fields={fields} fieldsState={fieldsState} />
                  ))}
                </Stack>
              </PreviewPanel>
            </div>
          );
        })}
      </FormContainer>

      <FormActionButtonsContainer className={classes.formActionBtnsContainer}>
        <CustomButton
          buttonTitle="Cancel"
          buttonType={ButtonTypes.Ghost}
          onClick={onCancel}
          disabled={isLoading || isSubmitting}
        />

        <div className={classes.formActionBtns}>
          {step > 0 && (
            <CustomButton
              buttonTitle="Previous"
              leftIcon="Previous"
              buttonType={ButtonTypes.Ghost}
              onClick={goToPreviousStep}
              disabled={isLoading || isSubmitting}
            />
          )}
          {showSaveButton && (
            <CustomButton
              buttonTitle={'Save'}
              leftIcon="Save"
              buttonType={ButtonTypes.Secondary}
              isLoading={isLoading}
              disabled={isLoading || isSubmitting || !isDirty}
              onSubmit={
                onSave
                  ? () =>
                      onSave(
                        false,
                        {
                          skipVersionUpgrade: false,
                        },
                        {
                          ...(urlParams.get('requestType')
                            ? {
                                initiation_type__a:
                                  urlParams.get('requestType'),
                              }
                            : {}),
                        }
                      )
                  : undefined
              }
            />
          )}
          {showSubmitButton && (
            <ConfirmButton
              buttonTitle={LocalizationString.SUBMIT}
              buttonType={ButtonTypes.Primary}
              showSubmitConfirmationPopup={showSubmitConfirmationPopup}
              dialogProps={{
                primaryButtonText: LocalizationString.SUBMIT,
                title: LocalizationString.SUBMIT,
                description: submitConfirmationPopupMessage,
                secondaryButtonText: LocalizationString.CANCEL,
                iconSrc: WarningIcon,
              }}
              leftIcon={'Submit'}
              disabled={isSubmitDisabled}
              isLoading={isSubmitting}
              onSubmit={
                onSave
                  ? () =>
                      onSave(
                        true,
                        {
                          skipVersionUpgrade: false,
                        },
                        {
                          ...(urlParams.get('requestType')
                            ? {
                                initiation_type__a:
                                  urlParams.get('requestType'),
                                is_active__s: true,
                              }
                            : {}),
                        }
                      )
                  : undefined
              }
            />
          )}
        </div>
      </FormActionButtonsContainer>
    </div>
  );
};
