import { LocalizationString } from '@celito.clients/assets';
import { MetadataItem } from '@celito.clients/enums';
import {
  Field,
  FilePicker,
  Loader,
  Step,
  Stepper,
  Tree,
} from '@celito.clients/shared';
import {
  errorToast,
  extractApiError,
  raiseErrorToast,
} from '@celito.clients/utils';
import {
  Button,
  Caption1,
  Card,
  MessageBar,
  MessageBarBody,
  MessageBarGroup,
  MessageBarIntent,
  MessageBarTitle,
  Text,
  TreeItemValue,
} from '@fluentui/react-components';
import { useState } from 'react';
import { useMutation } from 'react-query';

import {
  postMetadataImport,
  postMetadataReadFile,
} from '../../services/metadata-structure';
import { adminUiExportImportStyles } from '../admin-ui-export-import.styles';
import {
  ErrorState,
  ImportOperation,
  ImportStepEnum,
} from './admin-ui-import-stepper.model';

const steps: ImportStepEnum[] = Object.values(ImportStepEnum);

const importOperations: ImportOperation[] = [
  { label: 'Insert', name: 'insert' },
  { label: 'Upsert', name: 'upsert' },
  { label: 'Update', name: 'update' },
];

const ImportStepEnumper = () => {
  const styles = adminUiExportImportStyles();

  const [errors, setErrors] = useState<ErrorState>({
    file: '',
    metadata: '',
    importOperation: '',
    itemsErrors: [],
    itemsDependencies: [],
  });
  const [importOperation, setImportOperation] =
    useState<ImportOperation | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [selectedMetadata, setSelectedMetadata] = useState<MetadataItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<Set<TreeItemValue>>(
    new Set()
  );
  const [openItems, setOpenItems] = useState<Set<TreeItemValue>>(new Set());
  const [currentStep, setCurrentStep] = useState({
    index: 0,
    name: steps[0],
  });

  const uploadFileMutation = useMutation(postMetadataReadFile, {
    onError: raiseErrorToast,
  });
  const uploadFileError = extractApiError(uploadFileMutation.error);

  const importMutation = useMutation(postMetadataImport, {
    onError: raiseErrorToast,
  });
  const importError = extractApiError(importMutation.error);

  const metadata = uploadFileMutation.data?.items ?? [];

  const handleNext = () => {
    if (!file) {
      setErrors((prev) => ({
        ...prev,
        file: LocalizationString.REQUIRED_MSG,
      }));
      return;
    }

    if (currentStep.name === ImportStepEnum.Upload) {
      const formData = new FormData();
      formData.append('file', file as Blob);
      uploadFileMutation.mutate(formData);
    }

    if (currentStep.name === ImportStepEnum.Select) {
      if (selectedMetadata.length === 0) {
        errorToast({
          title: LocalizationString.SELECT_ERR_TITLE,
          message: LocalizationString.IMPORT_ERR_MESSAGE,
        });
        return;
      }

      if (errors.itemsErrors.length > 0) {
        const itemErrors = (
          <div>
            {errors.itemsErrors.map((err) => (
              <div key={`toast-${err}`}>
                <Caption1>- {err}</Caption1>
              </div>
            ))}
          </div>
        );

        errorToast({
          title: LocalizationString.ERROR_TITLE,
          message: itemErrors,
        });
        return;
      }

      if (errors.itemsDependencies.length > 0) {
        const dependencies = (
          <div>
            {errors.itemsDependencies.map((dep) => (
              <div key={`toast-${dep}`}>
                <Caption1>- {dep}</Caption1>
              </div>
            ))}
          </div>
        );

        errorToast({
          title: LocalizationString.DEPENDENCY_ERROR_TITLE,
          message: dependencies,
        });
        return;
      }
    }

    if (currentStep.name === ImportStepEnum.Import) {
      handleImport();
      return;
    }

    setCurrentStep((prev) => ({
      index: prev.index + 1,
      name: steps[prev.index + 1],
    }));
  };

  const handlePrev = () => {
    setCurrentStep((prev) => ({
      index: prev.index - 1,
      name: steps[prev.index - 1],
    }));
  };

  const handleImport = () => {
    if (!importOperation) {
      setErrors((prev) => ({
        ...prev,
        importOperation: LocalizationString.REQUIRED_MSG,
      }));
      return;
    }

    const formData = new FormData();
    formData.append('file', file as Blob);
    formData.append('operation', importOperation.name);
    formData.append('items', JSON.stringify(selectedMetadata));
    importMutation.mutate(formData);
    setCurrentStep((prev) => ({
      index: prev.index + 1,
      name: steps[prev.index + 1],
    }));
  };

  const handleRestart = () => {
    importMutation.reset();
    setImportOperation(null);
    setCheckedItems(new Set());
    setOpenItems(new Set());
    setSelectedMetadata([]);
    setFile(null);
    setCurrentStep({
      index: 0,
      name: steps[0],
    });
  };

  return (
    <div className={styles.panel}>
      <Stepper activeStep={currentStep.index}>
        {steps.map((label) => (
          <Step key={label}>{label}</Step>
        ))}
      </Stepper>
      <Card className={styles.card}>
        {currentStep.name === ImportStepEnum.Upload && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.UPLOAD_FILE_DESCRIPTION}
            </Text>
            <div className={styles.cardBody}>
              <Field validationMessage={errors.file}>
                <FilePicker
                  value={file}
                  onChange={(data) => {
                    setErrors((prev) => ({ ...prev, file: '' }));
                    setFile(data);
                  }}
                  allowedExtensions={['.zip']}
                  onError={(error) => {
                    setErrors((prev) => ({ ...prev, file: error ?? '' }));
                  }}
                />
              </Field>
            </div>
          </>
        )}

        {currentStep.name === ImportStepEnum.Select && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.SELECT_IMPORT_DESCRIPTION}
            </Text>
            {uploadFileMutation.isLoading && (
              <div className={styles.cardBody}>
                <div className={styles.loadingSpinner}>
                  <Loader
                    labelPosition="below"
                    label={LocalizationString.LOADING_FILE_DATA}
                  />
                  <p>{LocalizationString.LOADING_FILE_DESCRIPTION}</p>
                </div>
              </div>
            )}

            {uploadFileMutation.isSuccess && (
              <Tree
                items={metadata}
                checkedItems={checkedItems}
                openItems={openItems}
                onCheckedChange={(checkedItems, selectedMetadata) => {
                  setCheckedItems(checkedItems);
                  setSelectedMetadata(selectedMetadata);
                }}
                onOpenChange={setOpenItems}
                onError={(itemsErrors, itemsDependencies) => {
                  setErrors((prev) => ({
                    ...prev,
                    itemsErrors,
                    itemsDependencies,
                  }));
                }}
                multiselect
              />
            )}

            {uploadFileMutation.isError && (
              <div className={styles.cardBody}>
                <div className={styles.loadingSpinner}>
                  <MessageBar intent={'error'} style={{ whiteSpace: 'unset' }}>
                    <MessageBarBody>
                      <MessageBarTitle>
                        {uploadFileError?.errorTitle}
                      </MessageBarTitle>
                      <div>
                        <Caption1>{uploadFileError?.errorMessage}</Caption1>
                      </div>
                    </MessageBarBody>
                  </MessageBar>
                </div>
              </div>
            )}
          </>
        )}

        {currentStep.name === ImportStepEnum.Review && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.IMPORT_REVIEW_DESCRIPTION}
            </Text>
            <Tree items={selectedMetadata} openItems={openItems} />
          </>
        )}

        {currentStep.name === ImportStepEnum.Import && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.IMPORT_DESCRIPTION}
            </Text>
            <div className={styles.cardBody}>
              <div className={styles.exportSection}>
                <Field
                  label={LocalizationString.IMPORT_OPERATION_LABEL}
                  size="small"
                  required
                  validationState={errors.importOperation ? 'error' : 'none'}
                  validationMessage={errors.importOperation}
                >
                  <div
                    style={{ display: 'flex', gap: '8px', paddingTop: '4px' }}
                  >
                    {importOperations.map((operation) => (
                      <Button
                        style={{
                          backgroundColor:
                            importOperation?.name === operation.name
                              ? '#f4f5fd'
                              : '',
                          borderColor:
                            importOperation?.name === operation.name
                              ? ''
                              : '#f4f5fd',
                        }}
                        key={operation.name}
                        onClick={() => {
                          setImportOperation(operation);
                          setErrors((prev) => ({
                            ...prev,
                            importOperation: '',
                          }));
                        }}
                      >
                        {operation.label}
                      </Button>
                    ))}
                  </div>
                </Field>
              </div>
            </div>
          </>
        )}

        {currentStep.name === ImportStepEnum.Result && (
          <>
            <Text className={styles.cardHeader}>
              {importOperation?.label} result
            </Text>
            <div className={styles.cardBody}>
              {importMutation.isLoading && (
                <div className={styles.loadingSpinner}>
                  <Loader
                    labelPosition="below"
                    label={`Running ${importOperation?.label} operation...`}
                  />
                </div>
              )}

              {importMutation.isSuccess && importMutation.data && (
                <MessageBarGroup style={{ display: 'grid', gap: '8px' }}>
                  {importMutation.data.results.map((result) => {
                    const intent =
                      result.result === 'partialSuccess'
                        ? 'warning'
                        : result.result;

                    return (
                      <MessageBar
                        key={result.item}
                        intent={intent as MessageBarIntent}
                        style={{ whiteSpace: 'unset' }}
                      >
                        <MessageBarBody>
                          <MessageBarTitle>{result.item}</MessageBarTitle>
                          <div>
                            <Caption1>
                              {importOperation?.label} {result.result}
                              {result.result !== 'success' && ': '}
                              {result.error}
                            </Caption1>
                          </div>
                        </MessageBarBody>
                      </MessageBar>
                    );
                  })}
                </MessageBarGroup>
              )}

              {importMutation.isError && (
                <div className={styles.cardBody}>
                  <div className={styles.loadingSpinner}>
                    <MessageBar
                      intent={'error'}
                      style={{ whiteSpace: 'unset' }}
                    >
                      <MessageBarBody>
                        <MessageBarTitle>
                          {importError?.errorTitle}
                        </MessageBarTitle>
                        <div>
                          <Caption1>{importError?.errorMessage}</Caption1>
                        </div>
                      </MessageBarBody>
                    </MessageBar>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className={styles.cardActions}>
          {currentStep.index > 0 &&
            currentStep.name !== ImportStepEnum.Result && (
              <Button
                className={styles.buttonPrev}
                appearance="subtle"
                onClick={handlePrev}
                data-testid="button-next"
              >
                {LocalizationString.PREVIOUS}
              </Button>
            )}
          {(currentStep.name !== ImportStepEnum.Review ||
            selectedMetadata.length > 0) &&
            currentStep.index < steps.length - 1 && (
              <Button
                className={styles.buttonNext}
                appearance="subtle"
                onClick={handleNext}
                data-testid="button-prev"
              >
                {LocalizationString.NEXT}
              </Button>
            )}
          {currentStep.name === ImportStepEnum.Result && (
            <Button
              className={styles.buttonNext}
              appearance="subtle"
              onClick={handleRestart}
              data-testid="button-prev"
            >
              {LocalizationString.RESTART}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ImportStepEnumper;
