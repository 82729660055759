import { Stack, TextField } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { AttributeTypeEnum } from '../../../enums/attributes-enum';
import { RuleTypeEnum } from '../../../enums/rules-type-enum';
import { ruleValue } from '../../../utils/form-validations';
import { ControlledTextFieldProps } from './controlled-textfield.model';
import classes from './controlled-textfield.module.css';

export const ControlledTextFieldView = <T extends FieldValues>({
  key,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { name } = props.attribute;
  const minLength = ruleValue(RuleTypeEnum.MIN, props?.attribute?.rules) as
    | number
    | undefined;
  const maxLength = ruleValue(RuleTypeEnum.MAX, props?.attribute?.rules) as
    | number
    | undefined;

  return (
    <Controller
      name={name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error },
      }) => (
        <Stack
          data-testid={`textfield-container-${createTestAttribute(
            props.attribute?.label
          )}`}
          className={classes.section}
        >
          <TextField
            data-testid={`input-field-${createTestAttribute(
              props.attribute?.label
            )}`}
            {...props}
            {...(props.attribute as unknown as Record<string, unknown>)}
            required={
              'isRequired' in props.attribute
                ? props.attribute.isRequired
                : props.attribute.isMandatory
            }
            disabled={!props.attribute?.isEditable}
            placeholder={props.attribute?.placeholder || ''}
            helperLabelText={props.attribute.helpText}
            type={props.type ?? 'text'}
            onChange={(e) => {
              if (props.attribute.dataType === AttributeTypeEnum.Number) {
                onChange(parseInt(e.target.value));
              } else {
                onChange(e.target.value);
              }
            }}
            value={
              props.attribute?.valueParser
                ? (props.attribute.valueParser(value) as string)
                : value
            }
            onBlur={(e) => {
              if (
                !props.attribute.fromFilters &&
                props.attribute.dataType === AttributeTypeEnum.Number
              ) {
                // if datatype is number, remove leading zeros
                onChange(parseInt(value));
              }
              onBlur();
            }}
            name={fieldName}
            errorMessage={error && error.message}
            {...{ minLength, maxLength }}
          />
        </Stack>
      )}
    />
  );
};
