import { makeStyles } from '@fluentui/react-components';

export const fieldPikerStyles = makeStyles({
  label: {
    padding: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'border-color 0.2s', // Smooth transition for border color
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.8rem',
    gap: '4px',
    border: '1px dashed var(--border-color)',

    ':focus': {
      border: '1px solid var(--colorStrokeFocus2)',
      outline: 'var(--strokeWidthThik) solid var(--colorTransparentStroke)',
      boxShadow: '0 0 0 var(--strokeWidthThin) var(--colorStrokeFocus2) inset',
    },
  },

  draggingBorder: {
    border: '2px dashed #1d39c4',
  },

  draggingLabel: {
    color: '#1d39c4',
    fontWeight: 600,
  },

  blueText: {
    fontWeight: 600,
    color: '#1d39c4',
  },
  strong: {
    fontWeight: 600,
  },
});
