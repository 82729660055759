import { OperatorsEnum } from '@celito.clients/enums';
import { InHouseInputSelect, Stack } from '@celito.clients/shared';
import { Relationship } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { OptionProps } from '@fluentui/react-components';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { AttributeTypeEnum } from '../../../enums/attributes-enum';
import { ControlledDropdownProps } from './controlled-dropdown.model';
import classes from './controlled-dropdown.module.css';

type IOptionProp = OptionProps & {
  dataType: string;
  key?: string;
  columnName?: string;
  operator?: OperatorsEnum;
  relationship?: Relationship;
};

export const ControlledDropDownView = <T extends FieldValues>({
  size = 'large',
  ...props
}: ControlledDropdownProps<T>) => {
  const getDropdownValue = (value?: boolean | string | OptionProps) => {
    if (typeof value === 'boolean') {
      return value ? 'active' : 'inactive';
    }
    return value;
  };

  const options = (() => {
    if (props.attribute.options) {
      if (Array.isArray(props.attribute.options)) {
        return props.attribute.options.map((item) => {
          const option: IOptionProp = {
            ...item,
            value: item.key,
            text: item.text,
            key: item.key,
            columnName: item.columnName,
            relationship: item.relationship,
          };
          return option;
        });
      } else {
        return [];
      }
    } else {
      return props.options as IOptionProp[];
    }
  })();

  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({
        field: { onBlur, onChange, value, name },
        fieldState: { error },
      }) => {
        const dropdownValue = getDropdownValue(value);

        const handleDropdownChange = (option?: IOptionProp) => {
          switch (props.attribute.dataType) {
            case AttributeTypeEnum.YesNo:
            case AttributeTypeEnum.ActiveInactive:
              onChange(option?.value === 'active');
              break;
            case AttributeTypeEnum.FixedList:
              onChange(option?.value);
              break;
            default:
              if (props?.attribute?.onChange) {
                onChange(props.attribute.onChange(option));
              } else {
                onChange(option);
              }
              break;
          }
        };

        const valueParsed = options.find(
          (option) =>
            option.value ===
            (props.attribute?.valueParser?.(dropdownValue) as string)
        );

        const valueYesNo = options.find(
          (option) => option.value === dropdownValue
        );

        const getErrorMessage = () => {
          const anyError = error as Record<string, unknown>;
          if (typeof anyError === 'object' && anyError !== null) {
            if (
              'text' in anyError &&
              anyError.text &&
              typeof anyError.text === 'object' &&
              'message' in anyError.text
            ) {
              return anyError.text.message as string;
            }
            return anyError.message as string;
          }
          return '';
        };

        return (
          <Stack
            data-testid={`dropdown-select-container-${createTestAttribute(
              props.attribute?.label
            )}`}
            className={classes.section}
          >
            <InHouseInputSelect
              options={options.map((option) => {
                return {
                  value: option.value ?? '',
                  text: option.text ?? '',
                };
              })}
              isDropdownOnly={
                props.attribute.dataType !== AttributeTypeEnum.FixedList
              }
              label={props.attribute?.label}
              errorMessage={
                props.attribute.errorParser
                  ? (props.attribute.errorParser(error) as string)
                  : error && getErrorMessage()
              }
              required={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
              helperLabelText={props.attribute?.helpText}
              data-testid={`dropdown-select-${createTestAttribute(
                props.attribute?.label
              )}`}
              {...(props.attribute as Record<string, unknown>)}
              selectedOptions={
                props.attribute.dataType === AttributeTypeEnum.YesNo ||
                props.attribute.dataType === AttributeTypeEnum.ActiveInactive ||
                props.attribute.dataType === AttributeTypeEnum.FixedList
                  ? {
                      value: valueYesNo?.value as string,
                      text: valueYesNo?.text as string,
                    }
                  : valueParsed
                  ? {
                      value: valueParsed?.value as string,
                      text: valueParsed?.text as string,
                    }
                  : {
                      value: (dropdownValue as OptionProps)?.value as string,
                      text: (dropdownValue as OptionProps)?.text as string,
                    }
              }
              onBlur={onBlur}
              onOptionSelect={(_ev, data) => {
                handleDropdownChange({
                  text: data.optionText ?? '',
                  value: data.optionValue,
                  key: data.optionValue,
                  dataType:
                    options.find(({ value }) => value === data.optionValue)
                      ?.dataType ?? '',
                  operator:
                    options.find(({ value }) => value === data.optionValue)
                      ?.operator ?? undefined,
                  relationship: options.find(
                    ({ value }) => value === data.optionValue
                  )?.relationship,
                  columnName: options.find(
                    ({ value }) => value === data.optionValue
                  )?.columnName,
                });
              }}
              disabled={!props.attribute.isEditable}
            />
          </Stack>
        );
      }}
    />
  );
};
