import { AttributeTypeEnum } from '@celito.clients/enums';
import {
  useActiveModule,
  useConfigureLayout,
  useEffectWithDependencyMonitor,
  useQueryParams,
} from '@celito.clients/hooks';
import { logger } from '@celito.clients/services';
import {
  getHomeUrl,
  getULHomePageUrl,
  isObjectEmpty,
} from '@celito.clients/utils';
import { type AxiosError } from 'axios';
import { useFormEngineContext } from 'libs/form-engine/src/lib/hooks';
import { GridViewProps } from 'libs/shared/src/lib/grid-view-new/src';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';
import {
  IFilterView,
  RulesComponentListViewFilter,
} from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ListViewModuleType } from './config/list-view.config';
import uiConfig from './config/ui-config';
import { ListViewContext } from './context';
import { ListViewProps } from './list-view.model';
import ListViewUi from './list-view.ui';
import { getObjectData, getObjectMetadata, getViewMetadata } from './services';
import { ErrorData, ObjectMetadata, SortConfig, ViewMetadata } from './types';
import {
  getFiltersFromQueryParams,
  getFilterViewFromQueryParams,
  getGridColumnDataFromObjectMetaData,
  getGridRowDataFromObjectMetaData,
  setFiltersInQueryParams,
  setFilterViewInQueryParams,
  transformObjectMetadata,
} from './utils';
import { getFiltersToApply } from './utils/create-record-filter';
import { exportFile } from './utils/export-file.util';
import { getDefaultFiltersAndRecordData } from './utils/get-default-filters-and-record-data';
import { getNumberOfFilters } from './utils/number-of-fillters.util';
import {
  getIsSortedAscending,
  getSortOrder,
  isReferenceSortSupported,
} from './utils/sort.util';

interface ListViewControllerProps extends ListViewProps {}

const ListViewController = (props: ListViewControllerProps): JSX.Element => {
  const { configureLayout } = useConfigureLayout();
  const { outboundRelationships } = useFormEngineContext();
  const { getSearchParams, setUrlParams, clearUrlParams, searchParams } =
    useQueryParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const isInitialLoadRef = useRef(true);
  const [createRoute, setCreateRoute] = useState<string | null>(null);
  const [error, setError] = useState<ErrorData>({
    isError: false,
    errorMessage: '',
  });

  const [rawColumnData, setRawColumnData] = useState<ObjectMetadata | null>(
    null
  );
  const [viewMetadata, setViewMetadata] = useState<ViewMetadata | null>(null);

  const viewData = viewMetadata?.viewDto.view[0];

  const [columnData, setColumnData] = useState<GridViewProps['columns']>([]);
  const [rowData, setRowData] = useState<GridViewProps['items']>([]);
  const [pageTitle, setPageTitle] = useState<string>();
  const abortController = useRef(new AbortController());
  const [extraGridProps, setExtraGridProps] = useState<
    Omit<GridViewProps, 'columns' | 'items'>
  >({
    disableSelection: true,
    disableDefaultSorting: true,
    updateEmptyCells: true,
  });
  // initially set this to the itemsPerRow until the API response
  const [totalItems, setTotalItems] = useState(uiConfig.itemsPerRow);
  const [objectName, setObjectName] = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const queryParamFilters = getFiltersFromQueryParams(getSearchParams);
  const [filters, setFilters] = useState<
    RulesComponentListViewFilter | undefined
  >(queryParamFilters);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>();
  const [openWorkFlowModal, setOpenWorkFlowModal] = useState<boolean>(false);
  const { pathname } = useLocation();
  const activeModule = useActiveModule();

  const handleWorkflowModalOpen = () => {
    setOpenWorkFlowModal(!openWorkFlowModal);
  };

  const errorHandler = (_error: unknown) => {
    const error = _error as AxiosError;
    setError({
      isError: true,
      errorMessage: error.message,
    });
  };

  const handleSubRowToggle = useCallback((itemKey: string | number) => {
    setRowData((prevItems) =>
      prevItems.map((item) => {
        if (item.key === itemKey && item.subGridData) {
          return {
            ...item,
            subGridData: {
              ...item.subGridData,
              isSubGridOpen: !item.subGridData.isSubGridOpen,
            },
          };
        }
        return item;
      })
    );
  }, []);

  const totalItemsInRow = viewData?.defaultPageSize || uiConfig.itemsPerRow;

  const showShimmeringRows = useCallback(() => {
    // show shimmering rows
    setRowData(uiConfig.getLoadingRowData(totalItemsInRow));
  }, [totalItemsInRow]);

  const isShimmering = uiConfig.getLoadedRowData(rowData).length === 0;

  const shouldNotGetLatestVersion = [
    ListViewModuleType.LIBRARY_CONTROLLED_DOCUMENT,
    ListViewModuleType.LIBRARY_COURSE_VIEW,
    ListViewModuleType.LIBRARY_CURRICULUM_VIEW,
    ListViewModuleType.LIBRARY_QUIZ_VIEW,
  ].includes(props.viewName as ListViewModuleType);

  const selectedFilterView = useMemo(
    () =>
      getFilterViewFromQueryParams(
        viewMetadata?.viewDto.view[0].filterViews,
        getSearchParams
      ),
    [getSearchParams, viewMetadata?.viewDto.view]
  );

  const fetchRowData = useCallback(
    async (
      objName: string,
      pageNumber: number,
      customFilters?: RulesComponentListViewFilter,
      sortConf?: SortConfig,
      overrideFilterView?: RulesComponentListViewFilter,
      signal?: AbortSignal | undefined
    ) => {
      try {
        if (!viewMetadata || !rawColumnData) {
          return;
        }

        showShimmeringRows();
        const {
          defaultFilters,
          recordData: recordDataFilter,
          defaultSortConfig,
        } = getDefaultFiltersAndRecordData(
          viewData,
          rawColumnData,
          getSearchParams
        );

        const referenceFilter = rawColumnData.objectAttributeDefinitions.find(
          (attr) =>
            attr?.dataType === AttributeTypeEnum.Reference &&
            attr?.relationship?.objectName === props.objectName
        );

        const filtersToApply = getFiltersToApply(
          selectedFilterView,
          props.recordName,
          props.recordVersion,
          referenceFilter,
          props.objectName,
          viewData?.objectName,
          outboundRelationships
        );

        const response = await getObjectData(
          objName,
          {
            limit: viewMetadata.viewDto.view[0].defaultPageSize,
            page: pageNumber,
          },
          {
            sortConfig: sortConf,
            defaultFilters:
              overrideFilterView ?? filtersToApply ?? defaultFilters,
            filters: customFilters || filters,
            defaultSortConfig,
            recordDataFilter,
          },
          shouldNotGetLatestVersion,
          signal
        );

        if (!response) return;

        setRowData(
          getGridRowDataFromObjectMetaData(
            rawColumnData,
            response,
            viewMetadata,
            handleSubRowToggle
          )
        );
        setExtraGridProps((prev) => ({
          ...prev,
          itemsPerRow: viewMetadata.viewDto.view[0].defaultPageSize,
          totalItems: response.total,
        }));
        setTotalItems(response.total);
      } catch (e) {
        errorHandler(e);
      }
    },
    [
      filters,
      getSearchParams,
      handleSubRowToggle,
      rawColumnData,
      selectedFilterView?.filtersToBeApplied,
      shouldNotGetLatestVersion,
      showShimmeringRows,
      viewData,
      viewMetadata,
      props.objectName,
      selectedFilterView,
    ]
  );

  const onColumnClick: ColumnData['onColumnClick'] = useCallback(
    (_ev: React.MouseEvent<HTMLElement, MouseEvent>, column: ColumnData) => {
      if (column?.data?.isSortableColumn) {
        setColumnData((prev) =>
          prev.map((c) => {
            if (c.key === column.key) {
              return {
                ...c,
                isSortedAscending: getIsSortedAscending(
                  c.isSorted,
                  c.isSortedAscending
                ),
                isSorted: true,
              };
            }
            return {
              ...c,
              isSorted: false,
            };
          })
        );

        const sortConf: SortConfig = {
          attribute: column.data?.name,
          order: getSortOrder(column.isSorted, column.isSortedAscending),
          ...(isReferenceSortSupported(
            column?.data?.dataType as AttributeTypeEnum
          ) && {
            referencedTableColumnName: column?.data?.columnNameToBePicked,
          }),
        };
        setSortConfig(sortConf);

        fetchRowData(objectName, currentPageNumber, filters, sortConf);
      }
    },
    [currentPageNumber, fetchRowData, filters, objectName]
  );

  const handlePageChange = (selectedPage: number) => {
    setCurrentPageNumber(selectedPage + 1);
    fetchRowData(objectName, selectedPage + 1, filters, sortConfig);
  };

  const handleFilterViewChange = (filterView: IFilterView) => {
    setFilterViewInQueryParams(
      filterView,
      viewData?.filterViews,
      setUrlParams,
      clearUrlParams
    );
    setCurrentPageNumber(1);

    fetchRowData(
      objectName,
      1,
      filters,
      sortConfig,
      filterView.filtersToBeApplied[0],
      abortController.current.signal
    );
  };

  const applyFilters = useCallback(
    (newFilters: RulesComponentListViewFilter) => {
      setFilters(newFilters);

      // Set filters in query params
      if (isObjectEmpty(newFilters)) {
        clearUrlParams('filters');
      } else {
        setFiltersInQueryParams(newFilters, setUrlParams);
      }

      setCurrentPageNumber(1);

      fetchRowData(objectName, 1, newFilters, sortConfig);
    },

    [clearUrlParams, fetchRowData, objectName, setUrlParams, sortConfig]
  );

  // A cancel button will be shown if specified in the view metadata
  const onCancelPress = () => {
    // FIXME: cancel route should come from BE
    const isULModule = pathname.includes('lms__a');

    navigate(isULModule ? getULHomePageUrl() : getHomeUrl());
  };

  useEffectWithDependencyMonitor(
    (changedDeps) => {
      if (changedDeps.pathname?.before && changedDeps.pathname?.after) {
        setError({
          isError: false,
          errorMessage: '',
        });

        setSortConfig(undefined);
        setFilters(undefined);
      }
    },
    [pathname],
    ['pathname']
  );

  // Initial loading of row data
  useEffect(() => {
    const controller = new AbortController();

    logger.debug(`[${new Date().toISOString}] FETCHING LIST VIEW DATA`, {
      isInitialLoadRef: isInitialLoadRef,
      isLoading: isLoading,
      isShimmering: isShimmering,
    });

    if (isInitialLoadRef.current && !isLoading && isShimmering) {
      fetchRowData(
        objectName,
        1,
        filters,
        undefined,
        undefined,
        controller.signal
      );
      isInitialLoadRef.current = false;
    }

    return () => {
      controller.abort();
    };
  }, [fetchRowData, filters, isLoading, isShimmering, objectName]);

  const searchParamsString = searchParams.toString();

  const viewName = props.viewName;

  useEffectWithDependencyMonitor(
    (changedDeps) => {
      if (Object.keys(changedDeps).includes('viewName')) {
        return;
      }

      if (!Object.keys(changedDeps).includes('searchParamsString')) {
        return;
      }

      fetchRowData(
        objectName,
        1,
        filters,
        undefined,
        undefined,
        abortController.current.signal
      );
      return () => {
        abortController.current.abort();
        abortController.current = new AbortController();
      };
    },
    [searchParamsString, filters, viewName, objectName],
    ['searchParamsString', 'filters', 'viewName', 'objectName']
  );

  useEffectWithDependencyMonitor(
    (changedDeps) => {
      // Load everything when viewName changes
      if (Object.keys(changedDeps).includes('viewName')) {
        setIsLoading(true);
        isInitialLoadRef.current = true;
        setCurrentPageNumber(1);
        setTotalItems(0);

        getViewMetadata(props.viewName)
          .then((pageMetadata) => {
            const vwData = pageMetadata.viewDto?.view[0];

            setObjectName(vwData.objectName);
            setViewMetadata(pageMetadata);

            if (
              vwData?.allowCreateOfNewRecord &&
              vwData?.createRecordViewName
            ) {
              // If create route present, the add/create button is shown in the header
              // redirects to the provided URL
              setCreateRoute(`create/${vwData.createRecordViewName}`);
            } else {
              setCreateRoute(null);
            }

            Promise.all([getObjectMetadata(vwData.objectName)])
              .then((results) => {
                setRawColumnData(results[0]);
                setPageTitle(
                  !isEmpty(vwData.label)
                    ? vwData.label
                    : pageMetadata.viewDto?.label
                );
                showShimmeringRows();
                setIsLoading(false);
              })
              .catch(errorHandler);
          })
          .catch(
            () => navigate('/*') // This will match the root catch-all route
          );
      }
    },
    [props.viewName, configureLayout, getSearchParams],
    ['viewName', 'configureLayout', 'getSearchParams']
  );

  useEffect(() => {
    const recordName = searchParams.get('recordname');
    const objectName = searchParams.get('objectname');
    if (!props.dontSetHeader && pageTitle && !props.objectName)
      configureLayout({
        pageTitle,
        showBackButton: !!recordName && !!objectName,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- searchParams.toString() is added for deep comparison
  }, [searchParamsString, pageTitle]);

  useEffect(() => {
    if (!viewMetadata || !rawColumnData) {
      return;
    }

    const columnDataFromObjectMetaData = getGridColumnDataFromObjectMetaData(
      transformObjectMetadata(rawColumnData),
      viewMetadata,
      onColumnClick
    );

    setColumnData((prev) => {
      const prevSortMap: Record<string, any> = prev.reduce((result, column) => {
        result[column.key] = {
          isSorted: column.isSorted,
          isSortedAscending: column.isSortedAscending,
        };
        return result;
      }, {} as Record<string, any>);
      return columnDataFromObjectMetaData.map((column) => ({
        ...column,
        isSorted: prevSortMap[column.key]?.isSorted,
        isSortedAscending: prevSortMap[column.key]?.isSortedAscending,
      }));
    });
  }, [fetchRowData, objectName, onColumnClick, rawColumnData, viewMetadata]);

  const exportFileHandler = exportFile({
    viewData,
    rawColumnData,
    getSearchParams,
    objectName,
    filters,
    sortConfig,
    shouldNotGetLatestVersion,
    viewMetadata,
    pageTitle,
    moduleName: activeModule?.systemName,
    viewName: props.viewName,
  });

  return (
    <ListViewContext.Provider
      value={{
        fetchRowData,
        onColumnClick,
        showShimmeringRows,
        currentPageNumber,
        filters,
        sortConfig,
        selectedFilterView,
        handleFilterViewChange,
      }}
    >
      <ListViewUi
        isLoading={isLoading}
        columnData={columnData}
        rowData={rowData}
        extraGridProps={extraGridProps}
        error={error}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        itemsPerRow={viewData?.defaultPageSize || uiConfig.itemsPerRow}
        createRoute={createRoute}
        applyFilters={applyFilters}
        viewMetadata={viewMetadata}
        numberOfFilters={getNumberOfFilters(filters)}
        currentPageNumber={currentPageNumber}
        objectName={objectName}
        exportFile={exportFileHandler}
        onCancelPress={onCancelPress}
        objectMetadata={rawColumnData}
        createRecordViewType={
          viewMetadata?.viewDto?.view[0]?.createRecordViewType
        }
        handleWorkflowModalOpen={handleWorkflowModalOpen}
        openWorkFlowModal={openWorkFlowModal}
        {...props}
      />
    </ListViewContext.Provider>
  );
};

export default ListViewController;
