import { DocumentSubTypeObjectKeyEnum } from '@celito.clients/enums';
import { Field, TextField } from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { debounce, errorToast } from '@celito.clients/utils';
import { textFieldStyles } from 'libs/shared/src/lib/text-field/src/text-field.styles';
import { useMemo, useRef } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';

import { FormMode } from '../../document-templates/types';
import { DocumentSubTypeService } from '../../services/document-sub-type';
import { TDataTypesResponse } from '../../services/types';
import { TFieldValues as TF } from '../screens/edit/controller';
import Select from './picker/select';

type DocumentSubTypeProps = {
  attributes: Record<DocumentSubTypeObjectKeyEnum, ObjectAttributeDefinition>;
  isInList: boolean;
};

export default function DocumentSubType({
  attributes,
  isInList,
}: Readonly<DocumentSubTypeProps>) {
  const [
    documentTypeName,
    documentSubTypeCode,
    documentSubTypeName,
    newDocumentType,
    subTypesDisabled,
    formMode,
  ] = useWatch<TF>({
    name: [
      'documentTypeName',
      'documentSubTypeCode',
      'documentSubTypeName',
      'newDocumentType',
      'subTypesDisabled',
      'formMode',
    ],
  }) as [string, string, string, boolean, boolean, string];
  const { setValue, reset, getValues } = useFormContext<TF>();
  const documentSubTypeDataRef = useRef<TDataTypesResponse>();
  const styles = textFieldStyles();

  const loadOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        if (!documentTypeName || newDocumentType) return [];
        const response = await DocumentSubTypeService.getDocumentSubTypes(
          documentTypeName,
          search
        );
        documentSubTypeDataRef.current = response.data;
        const { data } = documentSubTypeDataRef.current;
        return data.map((type) => {
          return {
            value: type.code,
            label: type.label,
            name: type.name,
            isDisabled: formMode === FormMode.CREATE,
          };
        }, 500);
      }),
    [documentTypeName, newDocumentType, setValue, formMode]
  );
  return (
    <>
      <Controller<TF>
        name={'documentSubType'}
        shouldUnregister
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message;
          return (
            <div>
              <Field
                size="large"
                label={attributes.label?.label}
                helperTextLabel={attributes.label?.helpText}
                className={styles.field}
                validationMessage={errorMessage}
                validationState={errorMessage ? 'error' : 'none'}
                required={isInList}
              >
                <Select
                  label={'document-sub-type'}
                  errorMessage={errorMessage}
                  placeholder={
                    formMode === FormMode.EDIT
                      ? 'Create or Edit...'
                      : 'Create...'
                  }
                  key={documentTypeName}
                  loadOptions={loadOptions}
                  value={
                    field.value
                      ? {
                          label: field.value,
                          value: documentSubTypeCode,
                          isDisabled: false,
                          name: documentSubTypeName,
                        }
                      : undefined
                  }
                  onCreateOption={(inputValue) => {
                    setValue('documentSubTypeCode', '');
                    setValue('documentSubTypeCodeIsDisabled', false);
                    field.onChange(inputValue);

                    setValue('newDocumentSubType', true);
                  }}
                  isDisabled={subTypesDisabled}
                  onChange={(inputValue, { action }) => {
                    try {
                      if (action === 'select-option') {
                        const value = yup
                          .object()
                          .shape({
                            label: yup.string().required(),
                            value: yup.string().required(),
                            name: yup.string().required(),
                          })
                          .validateSync(inputValue);
                        field.onChange(value.label);
                        setValue('documentSubTypeCode', value.value);
                        setValue('documentSubTypeName', value.name);
                        setValue('documentSubTypeCodeIsDisabled', true);
                        setValue('newDocumentSubType', false);
                        if (formMode === FormMode.EDIT) {
                          const selectedData =
                            documentSubTypeDataRef.current?.data?.find(
                              (subType) => subType.name === value.name
                            );
                          if (selectedData) {
                            const templateName =
                              selectedData.documentTemplate.name ?? '';
                            reset({
                              ...getValues(),
                              isRawFileDownloadAllowed:
                                selectedData.isRawFileDownloadAllowed,
                              isAdminReviewRequired:
                                selectedData.isAdminReviewRequired,
                              isTrainable: selectedData.isTrainable,
                              templateName,
                              effectivenessPeriodInDays:
                                selectedData.effectivenessPeriodInDays,
                              isFollowUpRequired: Boolean(
                                selectedData.isFollowUpRequired
                              ),
                              followUpTimePeriodInMonths:
                                selectedData.followUpTimePeriodInMonths,
                              followUpTriggerPeriodInDays:
                                selectedData.followUpTriggerPeriodInDays,
                            });
                          }
                        }
                      } else if (action === 'clear') {
                        field.onChange('');
                        setValue('documentSubTypeCode', '');
                        setValue('documentSubTypeName', '');
                        setValue('documentSubTypeCodeIsDisabled', false);
                      }
                    } catch (e) {
                      errorToast({
                        message: 'Something Wrong',
                      });
                    }
                  }}
                />
              </Field>
            </div>
          );
        }}
      />
      <Controller<TF>
        name={`documentSubTypeCode`}
        shouldUnregister
        render={({ field, fieldState }) => {
          return (
            <CodeField
              {...{ field, fieldState }}
              attribute={attributes.code}
              disabled={false}
            />
          );
        }}
      />
    </>
  );
}

const CodeField = ({
  field,
  fieldState: { error },
  disabled,
  attribute,
}: {
  field: ControllerRenderProps<TF>;
  fieldState: ControllerFieldState;
  disabled: boolean;
  attribute: ObjectAttributeDefinition;
}) => {
  const [documentTypeCodeIsDisabled, subTypesDisabled, documentSubType] =
    useWatch<TF>({
      name: [
        'documentSubTypeCodeIsDisabled',
        'subTypesDisabled',
        'documentSubType',
      ],
    });
  const errorMessage = error?.message;
  return (
    <div>
      <TextField
        value={typeof field.value === 'string' ? field.value : ''}
        onChange={(e) => {
          field.onChange(e.currentTarget.value);
        }}
        required={!!documentSubType}
        label={attribute?.label}
        helperLabelText={attribute?.helpText}
        disabled={
          !!documentTypeCodeIsDisabled || !!subTypesDisabled || disabled
        }
        errorMessage={errorMessage}
      />
    </div>
  );
};
