import {
  QuizPreviewPage,
  QuizQuestionsPage,
  RelatedCourse,
} from '../../../custom-pages';
import { ControlledDocumentPreviewPage } from '../../../custom-pages/controlled-document-preview-page/controlled-document-preview-page.component';
import { CourseAssociatedCurriculaPage } from '../../../custom-pages/course-associated-curricula/course-associated-curricula.component';
import { CoursePreviewPage } from '../../../custom-pages/course-preview-page';
import { CurriculumPreviewPage } from '../../../custom-pages/curriculum-preview-page';
import { FollowUpPage } from '../../../custom-pages/follow-up-page/follow-up-page.component';
import { RelatedObjects } from '../../../custom-pages/related-objects/related-objects.component';
import { CustomPageProps } from '../../../types/common-types';
import { PageComponentEnum } from './page-component.enum';

export const getPageComponent = (
  pageComponent: PageComponentEnum | null,
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  props: CustomPageProps
) => {
  switch (pageComponent) {
    case PageComponentEnum.ADDQUESTIONS:
    case PageComponentEnum.EDITQUESTIONS:
    case PageComponentEnum.VIEWQUESTIONS:
      return <QuizQuestionsPage {...props} />;
    case PageComponentEnum.PREVIEW_QUIZ:
      return <QuizPreviewPage {...props} />;
    case PageComponentEnum.FOLLOW_UP_VIEW:
      return <FollowUpPage {...props} />;
    case PageComponentEnum.RELATED_TRAINING_PLAN:
      return <RelatedCourse {...props} />;
    case PageComponentEnum.CONTROLLED_DOCUMENT_PREVIEW:
      return <ControlledDocumentPreviewPage {...props} />;
    case PageComponentEnum.COURSE_PREVIEW:
      return <CoursePreviewPage {...props} />;
    case PageComponentEnum.CURRICULUM_PREVIEW:
      return <CurriculumPreviewPage {...props} />;
    case PageComponentEnum.COURSE_ASSOCIATED_CURRICULA:
      return <CourseAssociatedCurriculaPage {...props} />;
    default:
      return (
        <RelatedObjects
          {...props}
          key={pageComponent}
          pageComponent={pageComponent}
        ></RelatedObjects>
      );
  }
};
