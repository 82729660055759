import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useRecordNavigation } from '@celito.clients/provider';
import { useBreadcrumbs } from '@celito.clients/shared';
import { useEffect } from 'react';

import { RelatedObjectsProps } from './related-objects.model';
import { RelatedObjectsView } from './related-objects.view';

interface RelatedObjectsControllerProps extends RelatedObjectsProps {}

export const RelatedObjectsController = (
  props: RelatedObjectsControllerProps
): JSX.Element => {
  const { breadcrumbs } = useBreadcrumbs();

  const { setIsRelatedObject } = useRecordNavigation();
  useEffect(() => {
    const recordNavId = breadcrumbs.join('_') || '';
    if (props?.recordName && props.objectName) {
      setIsRelatedObject(recordNavId, true);
    }
    if (props.mode === FormEngineModeTypeEnum.View) {
      return;
    }

    props.setWizardStepsState((prev) =>
      prev.map((prevStep) =>
        prevStep.number === props.step
          ? { ...prevStep, isValid: true }
          : prevStep
      )
    );
  }, []);

  return <RelatedObjectsView {...props} />;
};
