import { useRecordNavigation } from '@celito.clients/provider';
import { Button } from '@fluentui/react-components';
import { Cell, flexRender } from '@tanstack/react-table';
import cn from 'classnames';
import {
  ConditionalTooltip,
  Icon,
  useBreadcrumbs,
} from 'libs/shared/src/lib/shared';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { ColumnMeta } from '../../grid-view.model';
import { RowItem } from '../../types';
import getRenderValue from './helpers/getRenderValue';
import isFieldEmpty from './helpers/isFieldEmpty';
import classes from './table-cell.styles.module.css';

interface TableCellProps {
  dataTestId?: string;
  cell: Cell<RowItem, unknown>;
  showPlaceholderIfEmpty: boolean;
}

const TableCell = ({ cell, showPlaceholderIfEmpty }: TableCellProps) => {
  const navigate = useNavigate();

  const tdRef = useRef<HTMLTableCellElement | null>(null);
  const dataContainerRef = useRef<HTMLDivElement | null>(null);
  const textContainerRef = useRef<HTMLDivElement | null>(null);
  const recordNavigation = useRecordNavigation();
  const { addRecord, isRelatedObject, navItems, setCurrentIndex } =
    recordNavigation;
  const { addBreadcrumb, breadcrumbs } = useBreadcrumbs();

  const { isFixed = false, showTooltip = false } = (cell.column.columnDef
    .meta || {}) as ColumnMeta;

  const hasSubRow =
    Array.isArray(cell.row.original.subRow) &&
    cell.row.original.subRow.length > 0 &&
    cell.column.id === cell.row.original.subRowToggleFieldName;

  const renderVal = getRenderValue(cell);
  const isAccessorColumn = 'accessorKey' in cell.column.columnDef;
  const showPlaceholder =
    showPlaceholderIfEmpty && isAccessorColumn && isFieldEmpty(renderVal);

  const getBreadcrumbLabel = (
    rowData: Record<string, string> | undefined,
    cell: Cell<RowItem, unknown>
  ): string => {
    return (
      rowData?.objectRecordLabel ??
      rowData?.objectRecordTitle ??
      rowData?.objectRecordName ??
      rowData?.label ??
      rowData?.title ??
      rowData?.name ??
      rowData?.courseLabel ??
      rowData?.courseTitle ??
      rowData?.courseName ??
      rowData?.course ??
      cell.row.original.label ??
      ''
    );
  };

  const onClickHandler: MouseEventHandler<HTMLTableCellElement> = (e) => {
    const { onClickNavigateRoute, rowData } = cell.row.original;

    // Only allow onclick handler to work on top level elements
    // not inner elements as they might contain modals, popup,etc.
    // which may have buttons of their own, which will trigger this
    // onclick handler also (if stopPropagation is not done)
    const isTopLevelElement =
      e.target === tdRef.current ||
      e.target === dataContainerRef.current ||
      e.target === textContainerRef.current;

    if (isTopLevelElement && onClickNavigateRoute) {
      const url = addBreadcrumb({
        label: getBreadcrumbLabel(rowData, cell),
        path: onClickNavigateRoute ?? '',
      });

      const currentUrl = window.location.pathname;
      const modifiedUrl = currentUrl.split('/view')[0];
      const combinedUrl = `${modifiedUrl}${url.replace('..', '')}`;

      const recordNavId = breadcrumbs.join('_');
      if (navItems[recordNavId]?.records.length > 0) {
        const currentIndexOfRecord = navItems[recordNavId]?.records.findIndex(
          (record) => record === combinedUrl
        );
        setCurrentIndex(recordNavId, currentIndexOfRecord);
      }

      navigate(url);
    }
  };

  useEffect(() => {
    const { onClickNavigateRoute, rowData } = cell.row.original;
    if (rowData) {
      const url = addBreadcrumb({
        label: getBreadcrumbLabel(rowData, cell),
        path: onClickNavigateRoute ?? '',
      });

      const currentUrl = window.location.pathname;
      const modifiedUrl = currentUrl.split('/view')[0];
      const combinedUrl = `${modifiedUrl}${url.replace('..', '')}`;

      const recordNavId = breadcrumbs.join('_');
      if (isRelatedObject(recordNavId)) {
        addRecord(recordNavId, combinedUrl);
      }
    }
  }, []);

  return (
    <td
      ref={tdRef}
      data-testid="grid-body-cell"
      className={cn(classes.tableCell, {
        [classes.isFixed]: isFixed,
      })}
      style={{
        width: cell.column.getSize(),
      }}
      onClick={onClickHandler}
    >
      <ConditionalTooltip
        containerRef={tdRef}
        dataWrapperRef={dataContainerRef}
        textWrapperRef={textContainerRef}
        textWrapperTestId="grid-body-cell-value"
        tooltipContent={renderVal}
        extraConditions={showTooltip}
      >
        {hasSubRow && (
          <Button
            appearance="transparent"
            onClick={cell.row.getToggleExpandedHandler()}
            icon={
              <Icon
                iconName="CaretRight24Filled"
                className={cn(classes.subRowIcon, {
                  [classes.rotate]: cell.row.getIsExpanded(),
                })}
              />
            }
          />
        )}
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
        {showPlaceholder && '-'}
      </ConditionalTooltip>
    </td>
  );
};

export default TableCell;
