import { LocalizationString } from '@celito.clients/assets';
import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
} from '@fluentui/react-components';
import {
  bundleIcon,
  DatabaseArrowDownFilled,
  DatabaseArrowDownRegular,
  DatabaseArrowUpFilled,
  DatabaseArrowUpRegular,
} from '@fluentui/react-icons';
import { useState } from 'react';

import ExportStepper from './admin-ui-export/admin-ui-export-stepper';
import { StepperEnum } from './admin-ui-export-import.model';
import ImportStepper from './admin-ui-import/admin-ui-import-stepper';

const ExportIcon = bundleIcon(DatabaseArrowUpFilled, DatabaseArrowUpRegular);
const ImportIcon = bundleIcon(
  DatabaseArrowDownFilled,
  DatabaseArrowDownRegular
);

export const AdminUiExportImportView = () => {
  const [selectedTab, setSelectedTab] = useState<TabValue>(StepperEnum.EXPORT);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  return (
    <>
      <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
        <Tab
          data-testid="tab-export"
          icon={<ExportIcon />}
          value={StepperEnum.EXPORT}
        >
          {LocalizationString.EXPORT}
        </Tab>
        <Tab
          data-testid="tab-import"
          icon={<ImportIcon />}
          value={StepperEnum.IMPORT}
        >
          {LocalizationString.IMPORT}
        </Tab>
      </TabList>
      {selectedTab === StepperEnum.EXPORT && <ExportStepper />}
      {selectedTab === StepperEnum.IMPORT && <ImportStepper />}
    </>
  );
};
