import { SortConfig } from '@celito.clients/list-view-engine';
import { ReferenceSelector, Stack } from '@celito.clients/shared';
import {
  createTestAttribute,
  getReferencePickerType,
} from '@celito.clients/utils';
import { ReferenceSelectorItem } from 'libs/shared/src/lib/reference-selector/src/reference-selector.model';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { ControlledReferenceSelectorProps } from './controlled-reference-selector.model';
import classes from './controlled-reference-selector.module.css';

interface ControlledReferenceSelectorViewProps<T extends FieldValues>
  extends ControlledReferenceSelectorProps<T> {
  getReferencePickerFiltersByObjectName: (
    objectName: string,
    recordDetails: Record<string, any> | undefined
  ) => RulesComponentListViewFilter;
}

const getReferencePickerSortConfigByObjectName = (
  objectName: string
): SortConfig | undefined => {
  switch (objectName) {
    case 'smart_groups__a':
      return {
        attribute: 'modified_at_utc__s',
        order: 'DESC',
      };
    default:
      return undefined;
  }
};

export const ControlledReferencePickerView = <T extends FieldValues>(
  props: ControlledReferenceSelectorViewProps<T>
) => {
  const { name } = props.attribute;
  const roles = (props.attribute?.roles || []).map((role: string) => {
    return { name: role };
  });

  return (
    <Controller
      name={name as Path<T>}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const objectName = props?.attribute?.relationship?.objectName || '';

        const handleOnRowReorder = (
          draggedIndex: number,
          targetRowIndex: number
        ) => {
          const data = value?.slice() ?? [];
          data?.splice(targetRowIndex, 0, data.splice(draggedIndex, 1)[0]);
          onChange([...data]);
        };

        return (
          <Stack
            data-testid={`reference-selector-container-${createTestAttribute(
              props.attribute?.label
            )}`}
            className={classes.section}
          >
            <ReferenceSelector
              objectName={objectName}
              label={props?.attribute?.label}
              referencePickerProps={{
                required: props.attribute?.isRequired,
                onOptionSelect: (_, data) => null,
                referencePicker: {
                  defaultReferenceFilter:
                    props.attribute.defaultReferenceFilter,
                  filters: props.getReferencePickerFiltersByObjectName(
                    objectName,
                    props?.recordDetails
                  ),
                  sortConfig:
                    getReferencePickerSortConfigByObjectName(objectName),
                  pickerType: getReferencePickerType(objectName),
                  peoplePickerRoles: roles,
                },
                helperLabelText: props.attribute?.helpText,
                multiselect: true,
                errorMessage: error?.message,
                disabled: !props.attribute.isEditable,
              }}
              onSelectorChange={(data) => {
                onChange(data);
              }}
              onRemoveItem={(item) => {
                onChange(
                  value.filter(
                    (selectedItem: ReferenceSelectorItem) =>
                      selectedItem.name !== item.name
                  )
                );
              }}
              selectedItems={props.watch ? props.watch(name as Path<T>) : []}
              onRowReorder={handleOnRowReorder}
              getLatestVersionOnly={props.attribute.getLatestVersionOnly}
              mode={props.mode}
              excludeSelfAttachedId={
                props.attribute.getAllVersions
                  ? props?.recordDetails?.uuid
                  : props?.recordDetails?.name
              }
              getAllVersions={props.attribute.getAllVersions}
            />
          </Stack>
        );
      }}
    />
  );
};
