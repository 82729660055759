import {
  createTestAttribute,
  getDependenciesLabels,
  getItem,
  getSelectedMetadata,
  transformMetadataToTreeItems,
} from '@celito.clients/utils';
import {
  FlatTree,
  FlatTreeItem,
  TreeItemLayout,
  TreeItemValue,
  useHeadlessFlatTree_unstable,
} from '@fluentui/react-components';

import { Dependencies, Errors } from './components/tree-item-info';
import { TreeProps } from './tree.model';
import { treeStyles } from './tree.styles';

const Tree = ({
  items,
  checkedItems = new Set(),
  openItems = new Set(),
  multiselect,
  onCheckedChange,
  onOpenChange,
  onError,
}: TreeProps) => {
  const styles = treeStyles();

  const treeItems = transformMetadataToTreeItems(items);
  const flatTree = useHeadlessFlatTree_unstable(treeItems, {
    defaultOpenItems: openItems,
    defaultCheckedItems: checkedItems,
    onOpenChange: (e, data) => {
      onOpenChange?.(data.openItems);
    },
    selectionMode: multiselect ? 'multiselect' : undefined,
    onCheckedChange: (e, data) => {
      let errors: string[] = [];
      const dependenciesLabels = new Set<string>();
      const checkedItems = new Set<TreeItemValue>();
      data.checkedItems.forEach((value, key) => {
        if (value === true) {
          checkedItems.add(key);
        }
      });

      const selectedMetadata = getSelectedMetadata(items, checkedItems);
      checkedItems.forEach((key) => {
        const item = getItem(key as string, items);

        if (item.errors?.length) {
          errors = errors.concat(item.errors);
        }

        if (item.dependencies?.length) {
          const { labels, indexes } = getDependenciesLabels(
            item.dependencies,
            items
          );

          indexes.forEach((index, i) => {
            if (!checkedItems.has(index)) {
              dependenciesLabels.add(labels[i]);
            }
          });
        }
      });

      onCheckedChange?.(checkedItems, selectedMetadata);
      onError?.(errors, Array.from(dependenciesLabels));
    },
  });

  return (
    <FlatTree
      {...flatTree.getTreeProps()}
      appearance="subtle"
      aria-label="Select"
      className={styles.tree}
    >
      {Array.from(flatTree.items(), (flatTreeItem) => {
        const { text, dependencies, errors, ...treeItemProps } =
          flatTreeItem.getTreeItemProps();
        return (
          <FlatTreeItem {...treeItemProps} key={flatTreeItem.value}>
            <TreeItemLayout
              data-testid={`tree-item-${createTestAttribute(text)}`}
              className={styles.treeItemLayout}
            >
              <span>{text}</span>
              <div className={styles.info}>
                {checkedItems.has(flatTreeItem.value as string) && (
                  <>
                    <Dependencies
                      dependencies={dependencies ?? []}
                      checkedItems={checkedItems}
                      items={items}
                    />
                    <Errors errors={errors ?? []} />
                  </>
                )}
              </div>
            </TreeItemLayout>
          </FlatTreeItem>
        );
      })}
    </FlatTree>
  );
};

export default Tree;
