import {
  LayoutRulesDataSchema,
  ObjectAttributeType,
} from '@celito.clients/types';

import { getFieldDataObject } from '../../../utils/attribute-config';
import DisplayView from '../../view-components/display-view/display-view.component';
import { SectionView } from '../../wizard-container/section/section';

export const getFieldSection = (
  columnName: string,
  width: number,
  recordDetails: Record<string, unknown>,
  attributeConfig: ObjectAttributeType,
  objectName: string,
  index: number,
  isPreviewTab = false,
  fieldsState?: Record<string, LayoutRulesDataSchema>,
  dataTestId?: string
) => {
  const {
    label = '',
    value,
    dataType,
    dataTypeKeyForFE,
  } = getFieldDataObject(columnName, recordDetails, attributeConfig);

  return (
    <SectionView
      width={width}
      key={index}
      style={fieldsState?.[columnName]?.isHidden ? { display: 'none' } : {}}
    >
      <DisplayView
        label={label}
        value={value}
        dataType={dataType}
        objectName={objectName}
        objectAttributeDefinition={attributeConfig.objectAttributeDefinitions.find(
          (item) => item.columnName === columnName || item.name === columnName
        )}
        attributeConfig={attributeConfig}
        isPreviewTab={isPreviewTab}
        recordDetails={recordDetails}
        dataTypeKeyForFE={dataTypeKeyForFE}
        dataTestId={dataTestId}
      />
    </SectionView>
  );
};
