import { StringList } from '@celito.clients/shared';
import { AxiosError, isAxiosError } from 'axios';
import { isEqual } from 'lodash';

import { APIError } from '../../types/api-error-type';
import { errorToast } from './toast';

export const raiseErrorToast = (error: unknown) => {
  if (isAxiosError(error) || error instanceof AxiosError) {
    const err = error as AxiosError<APIError>;

    if (err.code) {
      if (isEqual(err.code, 'ERR_CANCELED')) return;

      const errorMessageBasedOnCode = getErrorMessageBasedOnCode(err.code);

      if (errorMessageBasedOnCode) {
        errorToast({
          message: errorMessageBasedOnCode,
        });
        return;
      }
    }

    if (err.response) {
      const { errorMessage, errorTitle: title } = err?.response?.data ?? {};

      let message: string | JSX.Element =
        typeof errorMessage === 'string'
          ? errorMessage
          : `Unhandled ${typeof errorMessage} type of error message in response data.`;

      if (Array.isArray(errorMessage)) {
        message = <StringList as="ul" content={errorMessage} />;
      }

      const defaultTimeout = 3000;
      // If message is not array then use default timeout,
      // otherwise add 1 second per error message in the array.
      const timeout = Array.isArray(errorMessage)
        ? defaultTimeout + errorMessage.length * 1000
        : defaultTimeout;

      errorToast({ title, message }, { timeout });
    } else {
      errorToast({
        message: err.message,
      });
    }
  } else if (error instanceof Error) {
    //Type error, eval error, range error, reference error, syntax error
    errorToast({
      message: error.message,
    });
  } else {
    errorToast({
      message: 'An unexpected issue occured',
    });
  }
};

export const extractApiError = (error: unknown) => {
  if (error instanceof AxiosError || isAxiosError(error)) {
    const apiError = error.response?.data as APIError;
    return apiError;
  }
  return undefined;
};

const getErrorMessageBasedOnCode = (errorCode: string | undefined) => {
  if (!errorCode) return undefined;

  let errorMessage;
  switch (errorCode) {
    case 'ERR_NETWORK':
      errorMessage = 'Network Connection problem';
      break;
    case 'ERR_INVALID_URL':
      errorMessage = 'Invalid URL';
      break;
    case 'ETIMEDOUT':
      errorMessage = 'Connection Timed Out';
      break;
    case 'ERR_FR_TOO_MANY_REDIRECTS':
      errorMessage = 'Too many redirections';
      break;
    default:
      errorMessage = undefined;
  }

  return errorMessage;
};
