import { ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  FormActionButtonsContainer,
  Loader,
  Stack,
} from '@celito.clients/shared';
import {
  Field,
  LayoutRulesDataSchema,
  ObjectAttributeType,
  Section,
} from '@celito.clients/types';
import { AttributeTypeEnum } from 'libs/form-engine/src/lib/enums/attributes-enum';
import { getFieldDataObject } from 'libs/form-engine/src/lib/utils/attribute-config';
import {
  getFormActionPermission,
  getFormValues,
  getUserActions,
} from 'libs/form-engine/src/lib/utils/helper';
import { getLayoutRules } from 'libs/form-engine/src/lib/utils/layout-rules';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { FieldValues } from 'react-hook-form';

import { ContextMenu } from '../../../context-menu/context-menu-actions-component';
import { UserActions } from '../../../form-wizard/form-wizard.model';
import DisplayView from '../../../view-components/display-view/display-view.component';
import { SectionView } from '../../section/section';
import { ViewLayoutProps } from './view.model';
import classes from './view.module.css';

const getFieldSection = (
  columnName: string,
  width: number,
  recordDetails: Record<string, unknown>,
  attributeConfig: ObjectAttributeType,
  objectName: string,
  index: number,
  fieldState: Record<string, LayoutRulesDataSchema>
) => {
  const {
    label = '',
    value,
    dataType,
    dataTypeKeyForFE,
  } = getFieldDataObject(columnName, recordDetails, attributeConfig);

  return (
    <SectionView
      width={width}
      key={index}
      style={{
        ...(fieldState[columnName].isHidden && {
          display: 'none',
        }),
      }}
    >
      <DisplayView
        label={label}
        value={value}
        dataType={(dataTypeKeyForFE ?? dataType) as AttributeTypeEnum}
        objectName={objectName}
        objectAttributeDefinition={attributeConfig.objectAttributeDefinitions.find(
          (item) => item.name === columnName
        )}
        attributeConfig={attributeConfig}
        recordDetails={recordDetails}
      />
    </SectionView>
  );
};

const ViewLayoutView: React.FC<ViewLayoutProps> = (props) => {
  const {
    sections = [],
    recordDetails,
    attributeConfig,
    objectName = '',
    goToEditScreen,
    onCancel,
    isLoadingRecord,
    callRecordDetailApi,
    ...rest
  } = props;

  const sectionTypeLayout = (sections: Section[]) => {
    if (sections[0]?.fields.length > 0) {
      const fieldsState = getLayoutRules(
        getFormValues(attributeConfig, recordDetails) as FieldValues,
        sections[0]
      );

      const { showEditButton } = getFormActionPermission(
        recordDetails,
        attributeConfig
      );

      return (
        <>
          <Stack className={classes.rowContainer}>
            <div className={classes.container}>
              {(sections[0]?.fields || []).map(
                (field: Field, index: number) => {
                  if (!fieldsState[field.columnName]?.isVisible) {
                    return null;
                  }

                  return (
                    <>
                      {getFieldSection(
                        field.columnName,
                        field.width,
                        recordDetails,
                        attributeConfig,
                        objectName,
                        index,
                        fieldsState
                      )}
                    </>
                  );
                }
              )}
            </div>
          </Stack>
          <FormActionButtonsContainer>
            <CustomButton
              buttonTitle="Cancel"
              buttonType={ButtonTypes.Ghost}
              onClick={onCancel}
            />
            <CustomButton
              buttonTitle="Edit"
              leftIcon="Edit"
              buttonType={ButtonTypes.Primary}
              onClick={goToEditScreen}
              disabled={!showEditButton}
            />
          </FormActionButtonsContainer>
        </>
      );
    }
  };

  if (isLoadingRecord) {
    return (
      <div className={classes.loader_wrapper}>
        <Loader fullPage />
      </div>
    );
  }

  const actions = getUserActions(
    (recordDetails?.recordUserActions ??
      recordDetails?.recordObjectUserActions) as UserActions[],
    props?.attributeConfig,
    recordDetails as RowItem,
    props.viewDto,
    (recordDetails?.recordObjectUserActions as UserActions[])?.length > 0
  );

  return (
    <>
      {recordDetails?.name && actions.length ? (
        <div className={classes.headerButtonContainer}>
          <ContextMenu
            recordName={recordDetails?.name as string}
            objectName={props?.attributeConfig?.name}
            actions={actions}
            version={recordDetails?.version as string}
            response={recordDetails}
            refetchRecordData={() =>
              callRecordDetailApi(objectName, recordDetails?.name as string)
            }
          />
        </div>
      ) : null}
      <Stack className={classes.formContainer} {...rest}>
        {sectionTypeLayout(sections)}
      </Stack>
    </>
  );
};

export default ViewLayoutView;
