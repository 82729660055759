import { makeStyles } from '@fluentui/react-components';

export const treeStyles = makeStyles({
  tree: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '12px 8px',
  },

  treeItemLayout: {
    '>.fui-TreeItemLayout__main': {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingRight: '12px',
      alignItems: 'center',
    },
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
