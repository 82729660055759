import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { logger } from '@celito.clients/services';

import {
  msalConfig,
  msalScopes,
  presenceScope,
} from '../../../../apps/web-client/src/authConfig';
import { postLoginLog } from '../hooks/useLoginLog';

class MsalInstanceManager {
  private static instance: MsalInstanceManager | null = null;
  private msalInstance: PublicClientApplication;

  constructor() {
    this.msalInstance = new PublicClientApplication(msalConfig);
  }

  async initMsal() {
    try {
      await this.msalInstance.initialize();

      const result = await this.msalInstance.handleRedirectPromise();

      // event arg type should be EventType of msal-browser.
      // But it doesn't work for event.payload.account for some reason.
      this.msalInstance.addEventCallback((event: any) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS
        ) {
          const account = event.payload.account;

          this.msalInstance.setActiveAccount(account);
        }
      });

      const activeAccount = this.msalInstance.getActiveAccount();

      if (activeAccount === null) {
        if (result === null) {
          await this.msalInstance.loginRedirect({
            scopes: [msalScopes],
            prompt: 'consent',
          });
        }

        if (result) {
          const accessToken = await this.getTokenSilently(false);

          postLoginLog({
            isSuccessful: true,
            accessToken,
            requestId: result.requestId,
            userDetails: {
              userName: result.account?.name,
              oid: result.account?.idTokenClaims?.oid,
              tenantId: result.account?.tenantId,
            },
          });
        }
      }

      return this.msalInstance;
    } catch (err) {
      logger.error(err as Error, {
        origin: 'MsalInstanceManager',
      });
    }
  }

  public static getInstance(): MsalInstanceManager {
    if (this.instance === null) {
      this.instance = new MsalInstanceManager();
    }

    return this.instance;
  }

  public getMsalInstance(): PublicClientApplication {
    return this.msalInstance;
  }

  public async getTokenSilently(isGraphRequest?: boolean) {
    const accounts = this.msalInstance.getAllAccounts();
    const account = accounts[0];

    const tokenRequest = {
      scopes: [isGraphRequest ? presenceScope : msalScopes],
      account,
      forceRefresh: false,
    };

    try {
      const { accessToken } = await this.msalInstance.acquireTokenSilent(
        tokenRequest
      );

      return accessToken;
    } catch (error) {
      this.msalInstance.loginRedirect();

      return error as string;
    }
  }
}

export default MsalInstanceManager;
