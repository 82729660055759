import { ElementRef, RefObject } from 'react';
import { FieldValues } from 'react-hook-form';

import { HookFormProps } from '../../../types';

export type ControlledPickerProps<T extends FieldValues = FieldValues> =
  HookFormProps<T> & {
    recordDetails: Record<string, unknown>;
    closePickerOnScrollParentRef?: RefObject<ElementRef<'div'>>;
    excludeUsers?: string[];
    defaultParent?: string;
  };

export enum MappingTypeEnum {
  OneToOne = 'OneToOne',
  OneToMany = 'OneToMany',
  ManyToOne = 'ManyToOne',
  ManyToMany = 'ManyToMany',
}

export type SelectedUserType = {
  label: string;
  name: string;
};
