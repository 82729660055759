import { OperatorsEnum, ReferencePickerType } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { getReferencePickerType, raiseErrorToast } from '@celito.clients/utils';
import { SelectionEvents } from '@fluentui/react-combobox';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RulesComponentListViewFilter } from '../rules-component/types/rules-component.types';
import {
  IChangeData,
  InHouseInputSelectProps,
  IOption,
} from './in-house-input-select.model';
import { InHouseInputSelectView } from './in-house-input-select.view';
import { ICommonData, useGetReferenceData } from './services';
import { getSelectedOptions } from './services/selected-options';
import { convertCustomFilter, getText, removeDuplicates } from './utils';

export const InHouseInputSelectController = (
  props: InHouseInputSelectProps
) => {
  const [isLoadingSelectedOptions, setIsLoadingSelectedOptions] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [selectedOptionsFromApi, setSelectedOptionsFromApi] = useState<
    IOption[]
  >([]);

  const { referencePicker, exclude } = props;
  const activeModule = useActiveModule();
  const rolesList =
    referencePicker?.peoplePickerRoles?.map((role) => role.name) ?? [];

  const {
    objectData: {
      data: referenceData,
      isLoading,
      isFetching,
      hasNextPage,
      fetchNextPage,
    },
    setFilters,
  } = useGetReferenceData(
    activeModule ? [activeModule?.systemName] : [],
    rolesList,
    referencePicker?.filterByExactRoles ?? false,
    referencePicker?.objectName,
    referencePicker?.filters,
    referencePicker?.defaultReferenceFilter,
    referencePicker?.sortConfig,
    referencePicker?.getLatestVersionOnly,
    referencePicker?.showSystemUser,
    referencePicker?.getAllVersions
  );

  const shouldUpdateApiOptions = () =>
    (Array.isArray(props.selectedOptions) &&
      props.selectedOptions.length > 0) ||
    (!Array.isArray(props.selectedOptions) && props.selectedOptions);

  useEffect(() => {
    if (referencePicker?.objectName && shouldUpdateApiOptions()) {
      const isArray = Array.isArray(props.selectedOptions);

      const options = Array.isArray(props.selectedOptions)
        ? props.selectedOptions?.map((selected) => selected.value || selected)
        : props.selectedOptions?.value ?? props.selectedOptions;

      const selectedFilter: RulesComponentListViewFilter = {
        conditions: {
          all: [
            {
              any: [
                {
                  attribute: getAttributeNameToFilter(props?.valueDefinition),
                  value: options,
                  operator: isArray ? OperatorsEnum.IN : OperatorsEnum.EQUALS,
                },
              ],
            },
          ],
        },
      };
      setIsLoadingSelectedOptions(true);

      getSelectedOptions(
        referencePicker.objectName,
        selectedFilter,
        referencePicker.getSelectedOptionsInformation
      )
        .then((resp) => {
          if (resp.data) {
            let hasMe = false;

            if (Array.isArray(props.selectedOptions)) {
              hasMe = props.selectedOptions.some(
                (selected) =>
                  selected.value === '@me' ||
                  (selected as unknown as string) === '@me'
              );
            } else
              hasMe =
                (typeof props?.selectedOptions === 'string' &&
                  props.selectedOptions === '@me') ||
                props?.selectedOptions?.value === '@me';

            const optionsFromApi = resp.data
              ?.map((opt) => {
                return {
                  value: opt.name,
                  text: opt.label,
                };
              })
              .filter((o) => o.value !== user?.name);

            if (hasMe) {
              optionsFromApi.unshift({ value: '@me', text: 'Me' });
            }

            props?.setParentValue?.(resp.data[0]);
            setSelectedOptionsFromApi(optionsFromApi);
          }
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        })
        .finally(() => setIsLoadingSelectedOptions(false));
    }
  }, [referencePicker?.objectName, props.selectedOptions]);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const [highligtedOption, setHighligtedOption] = useState<
    IOption | undefined
  >();

  useEffect(() => {
    let newSelecteOptions: IOption[] = [];

    const needOptionsFromApi = Array.isArray(props.selectedOptions)
      ? !props.selectedOptions?.[0]?.text
      : !props.selectedOptions?.text;

    if (referencePicker?.objectName && needOptionsFromApi) {
      newSelecteOptions = shouldUpdateApiOptions()
        ? selectedOptionsFromApi
        : [];
    } else {
      newSelecteOptions = [];

      if (Array.isArray(props.selectedOptions))
        newSelecteOptions = props.selectedOptions;
      else if (props.selectedOptions?.value)
        newSelecteOptions = [props.selectedOptions];
    }

    setSelectedOptions(newSelecteOptions);
  }, [props.selectedOptions, selectedOptionsFromApi]);

  const [customSearch, setCustomSearch] = useState('');

  const dropdownRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const multiselectRef = useRef<HTMLDivElement>(null);
  const inputTextRef = useRef<HTMLInputElement | null>(null);
  const inputTextTagRef = useRef<HTMLInputElement | null>(null);
  const tagContainerRef = useRef<HTMLDivElement | null>(null);
  const prevWidthRef = useRef(0);

  const { user } = useContext(UserContext);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    positionOfDropdDownOptionsOnOpening();
  }, [selectedOptions.length, customSearch]);

  const positionOfDropdDownOptionsOnOpening = () => {
    if (multiselectRef.current && dropdownRef.current) {
      const dropdownHeight = dropdownRef.current.getBoundingClientRect().height;

      const { top, left, width, height, bottom } =
        multiselectRef.current.getBoundingClientRect();

      dropdownRef.current.style.left = `${left}px`;
      dropdownRef.current.style.width = `${width}px`;

      const remainingSpace = window.innerHeight - bottom;

      if (remainingSpace < dropdownHeight)
        dropdownRef.current.style.top = `${top - dropdownHeight - 3}px`;
      else dropdownRef.current.style.top = `${top + height + 3}px`;
    }
  };

  useEffect(() => {
    if (isOptionsOpen) positionOfDropdDownOptionsOnOpening();

    setHighligtedOption(undefined);
  }, [isOptionsOpen]);

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  useEffect(() => {
    const positionOfDropdDownOptions = () => {
      positionOfDropdDownOptionsOnOpening();
    };

    positionOfDropdDownOptions();

    const handleResize = () => {
      positionOfDropdDownOptions();
    };

    const handleWheel = (event: WheelEvent) => {
      const divElement = dropdownRef.current;

      if (event.clientY) {
        if (divElement && !divElement.contains(event.target as Node))
          setIsOptionsOpen(false);
      }

      if (event.ctrlKey) {
        positionOfDropdDownOptions();
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('wheel', handleWheel);
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !multiselectRef?.current?.contains(event.target as Node)
    ) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    const type = getReferencePickerType(props.referencePicker?.objectName);

    const searchFilter: RulesComponentListViewFilter = {
      conditions: {
        all: [
          type === ReferencePickerType.LABEL_OR_TITLE ||
          type === ReferencePickerType.LABEL_TITLE
            ? {
                any: [
                  {
                    attribute: 'label__s',
                    value: customSearch,
                    operator: OperatorsEnum.CONTAINS,
                  },
                  {
                    attribute: 'title__a',
                    value: customSearch,
                    operator: OperatorsEnum.CONTAINS,
                  },
                ],
              }
            : {
                all: [
                  {
                    attribute:
                      type === ReferencePickerType.TITLE
                        ? 'title__a'
                        : type === ReferencePickerType.TITLE__S
                        ? 'title__s'
                        : 'label__s',
                    value: customSearch,
                    operator: OperatorsEnum.CONTAINS,
                  },
                ],
              },
        ],
      },
    };

    const debounceHandler = setTimeout(() => {
      setFilters(
        convertCustomFilter(
          referencePicker?.filters,
          referencePicker?.defaultReferenceFilter,
          customSearch ? searchFilter : undefined
        )
      );
    }, 500);

    return () => {
      clearTimeout(debounceHandler);
    };
  }, [customSearch]);

  const handleCustomSearch = (customSearch: string) => {
    setCustomSearch(customSearch);
  };

  const toggleDropdown = (isOpen = false) => {
    setIsOptionsOpen(isOpen);
  };

  const onOptionSelect = (
    _ev: SelectionEvents,
    optionText: string,
    optionValue: string,
    data?: ICommonData
  ) => {
    setHighligtedOption(undefined);

    if (props.onOptionSelect)
      if (props.multiselect) {
        const isRemoval = selectedOptions.some(
          (selected) => selected.value === optionValue
        );
        const newSelectedOptions = isRemoval
          ? selectedOptions.filter((selected) => selected.value !== optionValue)
          : [
              ...selectedOptions,
              { text: optionText, value: optionValue, data: data },
            ];

        setSelectedOptions(newSelectedOptions);

        props.onOptionSelect(_ev, {
          optionText,
          optionValue,
          selectedOptions: newSelectedOptions,
          data,
        });
      } else {
        const isRemoval = selectedOptions.some(
          (selected) => selected.value === optionValue
        );

        const selectedOps: IOption | false = !isRemoval && {
          text: optionText,
          value: optionValue,
        };

        const comboboxProps: IChangeData = {
          optionText: isRemoval ? '' : optionText,
          optionValue: isRemoval ? '' : optionValue,
          selectedOptions: selectedOps || [],
          data,
        };

        setSelectedOptions(selectedOps ? [selectedOps] : []);

        props.onOptionSelect(_ev, comboboxProps);

        setIsOptionsOpen(false);

        inputTextTagRef.current?.focus();
      }
    handleCustomSearch('');
  };

  const onInput = () => {
    const input = inputTextRef.current;
    if (input && input.parentElement && tagContainerRef.current) {
      const parentWidth = tagContainerRef.current.getBoundingClientRect().width;
      const inputWidth = input.getBoundingClientRect().width;

      prevWidthRef.current = input.value.length * 10;
      if (inputWidth < parentWidth - 84) {
        input.style.width = input.value.length + 'ch';
      } else if (
        prevWidthRef.current < inputWidth &&
        prevWidthRef.current < parentWidth
      ) {
        input.style.width = input.value.length * 11 + 'px';
      }
    }
  };

  useEffect(() => {
    if (highligtedOption && dropdownRef.current) {
      const selectedElement = dropdownRef.current.querySelector(
        `[data-value="${highligtedOption.value}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [highligtedOption]);

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    options?: IOption[]
  ) => {
    if (event.key === 'Tab') setIsOptionsOpen(false);
    else if (event.key === 'Enter' && highligtedOption) {
      event.preventDefault();
      onOptionSelect(event, highligtedOption.text, highligtedOption.value);
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      let index =
        options?.findIndex((opt) => opt.value === highligtedOption?.value) ??
        -1;

      index = event.key === 'ArrowDown' ? index + 1 : index - 1;

      const newSelectedOption = options?.[index];

      setHighligtedOption(newSelectedOption);
    } else if (!props.isDropdownOnly)
      if (event.key === 'Backspace' && inputTextRef.current?.value === '') {
        const optionValue = selectedOptions[selectedOptions.length - 1];

        onOptionSelect(
          event,
          optionValue?.text ?? '',
          optionValue?.value ?? ''
        );
      }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!dropdownRef.current) {
      setCustomSearch('');
      setHighligtedOption(undefined);
    }
  };

  const opt = useMemo(() => {
    return referenceData?.pages.reduce((acc, page) => {
      return [...acc, ...page];
    }, []);
  }, [referenceData]);

  // building the reference options or prop options
  const referenceOptions: IOption[] =
    props.options.length > 0
      ? props.options
      : opt
          ?.filter((option) => !exclude?.includes(option?.name))
          .filter((opt) => opt !== null)
          .map((option) => {
            const { label, title, name } = option;

            let value = referencePicker?.attributeNameToFilter
              ? option[referencePicker.attributeNameToFilter]
              : name;

            if (props.valueDefinition === 'title__a') value = title;
            else if (props.valueDefinition === 'label__s') value = label;

            return {
              data: option,
              value,
              text: getText(label, title, referencePicker?.pickerType) ?? '',
            };
          }) ?? [];

  const getAttributeNameToFilter = (valueDefinition?: string) => {
    if (valueDefinition === 'label__s') {
      return 'label__s';
    }
    return 'name__s';
  };

  const hasMeExpression = (
    options: IOption[],
    enableMeExpression?: boolean
  ) => {
    if (!enableMeExpression) return options;

    return [
      { value: '@me', text: 'Me' },
      ...options.filter((o) => o.value !== user?.name), // Remove current user, once me is used,
    ];
  };

  const definedOptions = hasMeExpression(
    removeDuplicates(referenceOptions),
    props.referencePicker?.enableMeExpression
  );

  return (
    <InHouseInputSelectView
      {...{
        ...props,
        options: definedOptions,
        toggleDropdown,
        onOptionSelect,
        isOptionsOpen,
        selectedOptions,
        dropdownRef,
        handleCustomSearch,
        customSearch,
        multiselectRef,
        inputTextRef,
        inputTextTagRef,
        tagContainerRef,
        onInput,
        onKeyDown,
        onBlur,
        highligtedOption,
        isLoadingOptions:
          props.isLoadingOptions ||
          isLoadingSelectedOptions ||
          isLoading ||
          (isFetching && !!referencePicker?.objectName),
        lastElementRef,
        isFetching,
      }}
    />
  );
};
