import { LocalizationString } from '@celito.clients/assets';
import { MetadataItem } from '@celito.clients/enums';
import { Field, Loader, Step, Stepper, Tree } from '@celito.clients/shared';
import { errorToast, raiseErrorToast } from '@celito.clients/utils';
import {
  Body1Strong,
  Button,
  Card,
  Link,
  Text,
  TreeItemValue,
} from '@fluentui/react-components';
import { FolderZipRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { useMutation } from 'react-query';

import {
  postMetadataFile,
  useGetMetadataStructure,
} from '../../services/metadata-structure';
import { adminUiExportImportStyles } from '../admin-ui-export-import.styles';
import { ExportStepEnum } from './admin-ui-export-stepper.model';

const steps: ExportStepEnum[] = Object.values(ExportStepEnum);
const fileFormats = [{ name: 'zip', icon: FolderZipRegular }];

const ExportStepper = () => {
  const styles = adminUiExportImportStyles();
  const [errors, setErrors] = useState({ fileFormat: '' });
  const [fileFormat, setFileFormat] = useState('');
  const [selectedMetadata, setSelectedMetadata] = useState<MetadataItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<Set<TreeItemValue>>(
    new Set()
  );
  const [openItems, setOpenItems] = useState<Set<TreeItemValue>>(new Set());
  const [currentStep, setCurrentStep] = useState({
    index: 0,
    name: steps[0],
  });
  const { data, isLoading, isError, error, isSuccess } =
    useGetMetadataStructure();

  const exportMutation = useMutation(postMetadataFile, {
    onError: raiseErrorToast,
  });

  if (isError) {
    raiseErrorToast(error);
  }

  const handleNext = () => {
    if (
      selectedMetadata.length === 0 &&
      currentStep.name === ExportStepEnum.Select
    ) {
      errorToast({
        title: LocalizationString.SELECT_ERR_TITLE,
        message: LocalizationString.EXPORT_ERR_MESSAGE,
      });
      return;
    }

    setCurrentStep((prev) => ({
      index: prev.index + 1,
      name: steps[prev.index + 1],
    }));
  };

  const handlePrev = () => {
    setCurrentStep((prev) => ({
      index: prev.index - 1,
      name: steps[prev.index - 1],
    }));
  };

  const handleExport = () => {
    if (!fileFormat) {
      setErrors({ fileFormat: LocalizationString.REQUIRED_MSG });
      return;
    }
    exportMutation.mutate({ items: selectedMetadata });
  };

  const handleRestart = () => {
    exportMutation.reset();
    setFileFormat('');
    setCheckedItems(new Set());
    setOpenItems(new Set());
    setSelectedMetadata([]);
    setCurrentStep({
      index: 0,
      name: steps[0],
    });
  };

  return (
    <div className={styles.panel}>
      <Stepper activeStep={currentStep.index}>
        {steps.map((label) => (
          <Step key={label}>{label}</Step>
        ))}
      </Stepper>
      <Card className={styles.card}>
        {currentStep.name === ExportStepEnum.Select && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.SELECT_EXPORT_DESCRIPTION}
            </Text>
            {isLoading && (
              <div className={styles.cardBody}>
                <div className={styles.loadingSpinner}>
                  <Loader
                    labelPosition="below"
                    label={LocalizationString.LOADING_PLACEHOLDER}
                  />
                </div>
              </div>
            )}

            {isSuccess && (
              <Tree
                items={data?.items || []}
                openItems={openItems}
                checkedItems={checkedItems}
                onCheckedChange={(checkedItems, selectedMetadata) => {
                  setCheckedItems(checkedItems);
                  setSelectedMetadata(selectedMetadata);
                }}
                onOpenChange={setOpenItems}
                multiselect
              />
            )}

            {isError && (
              <div className={styles.cardBody}>
                <div className={styles.loadingSpinner}>
                  <Text weight="bold">{LocalizationString.ERROR}</Text>
                  <Text>{LocalizationString.SOMETHING_WENT_WRONG}</Text>
                </div>
              </div>
            )}
          </>
        )}

        {currentStep.name === ExportStepEnum.Review && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.REVIEW_DESCRIPTION}
            </Text>
            <Tree items={selectedMetadata} openItems={openItems} />
          </>
        )}

        {currentStep.name === ExportStepEnum.Export && (
          <>
            <Text className={styles.cardHeader}>
              {LocalizationString.EXPORT_DESCRIPTION}
            </Text>
            {!exportMutation.data && (
              <div className={styles.cardBody}>
                <div className={styles.exportSection}>
                  <Field
                    label={LocalizationString.EXPORT_FORMAT_LABEL}
                    size="small"
                    required
                    validationState={errors.fileFormat ? 'error' : 'none'}
                    validationMessage={errors.fileFormat}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        paddingTop: '4px',
                      }}
                    >
                      {fileFormats.map((format) => (
                        <Button
                          style={{
                            backgroundColor:
                              fileFormat === format.name ? '#f4f5fd' : '',
                            borderColor:
                              fileFormat === format.name ? '' : '#f4f5fd',
                          }}
                          key={format.name}
                          onClick={() => {
                            setFileFormat(format.name);
                            setErrors({ fileFormat: '' });
                          }}
                          icon={<format.icon />}
                        >
                          .{format.name}
                        </Button>
                      ))}
                    </div>
                  </Field>

                  <Button
                    appearance="primary"
                    disabled={!data || exportMutation.isLoading}
                    onClick={handleExport}
                  >
                    {LocalizationString.EXPORT}
                  </Button>
                </div>
                {exportMutation.isLoading && (
                  <div className={styles.loadingSpinner}>
                    <Loader label={`Generating your ${fileFormat} file...`} />
                  </div>
                )}
              </div>
            )}

            {exportMutation.isSuccess && (
              <div className={styles.cardBody}>
                <div className={styles.download}>
                  <Body1Strong>
                    {LocalizationString.EXPORT_COMPLETE}
                  </Body1Strong>
                  <Button
                    appearance="primary"
                    onClick={() =>
                      (window.location.href = exportMutation.data.url)
                    }
                  >
                    {LocalizationString.DOWNLOAD}
                  </Button>
                  <div>
                    {LocalizationString.EXPORT_DOWNLOAD_MESSAGE}
                    <Link href={exportMutation.data.url} download inline>
                      {LocalizationString.EXPORT_DOWNLOAD_LINK}
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {exportMutation.isError && (
              <div className={styles.cardBody}>
                <div className={styles.loadingSpinner}>
                  <Text weight="bold">{LocalizationString.ERROR}</Text>
                  <Text>{LocalizationString.SOMETHING_WENT_WRONG}</Text>
                </div>
              </div>
            )}
          </>
        )}

        <div className={styles.cardActions}>
          {currentStep.index > 0 && !exportMutation.data && (
            <Button
              className={styles.buttonPrev}
              appearance="subtle"
              onClick={handlePrev}
              data-testid="button-next"
            >
              {LocalizationString.PREVIOUS}
            </Button>
          )}
          {(currentStep.name !== ExportStepEnum.Review ||
            selectedMetadata.length > 0) &&
            currentStep.index < steps.length - 1 && (
              <Button
                className={styles.buttonNext}
                appearance="subtle"
                onClick={handleNext}
                data-testid="button-prev"
              >
                {LocalizationString.NEXT}
              </Button>
            )}
          {exportMutation.data &&
            currentStep.name === ExportStepEnum.Export && (
              <Button
                className={styles.buttonNext}
                appearance="subtle"
                onClick={handleRestart}
                data-testid="button-prev"
              >
                {LocalizationString.RESTART}
              </Button>
            )}
        </div>
      </Card>
    </div>
  );
};

export default ExportStepper;
