import { useRecordNavigation } from '@celito.clients/provider';
import { Button } from '@fluentui/react-components';
import { ChevronLeftFilled, ChevronRightFilled } from '@fluentui/react-icons';

import { useBreadcrumbs } from '../shared';
import { useRecordNavigationStyles } from './record-navigation.styles';

export const RecordNavigation = () => {
  const { navItems, goToNext, goToPrevious } = useRecordNavigation();
  const { breadcrumbs } = useBreadcrumbs();

  const navigationId = breadcrumbs.slice(0, -1).join('_');
  const navigationRecord = navItems[navigationId];
  const records = navigationRecord?.records || [];

  const styles = useRecordNavigationStyles();

  if (records.length < 1) {
    return null;
  }

  return (
    <div className={styles.listControlsContainer}>
      <Button
        className={styles.listControlButton}
        icon={<ChevronLeftFilled />}
        appearance="transparent"
        size="large"
        onClick={() => goToPrevious(navigationId)}
        disabled={navigationRecord.isFirst}
      />
      <div>{navigationRecord.position}</div>
      <Button
        className={styles.listControlButton}
        icon={<ChevronRightFilled />}
        appearance="transparent"
        size="large"
        onClick={() => goToNext(navigationId)}
        disabled={navigationRecord.isLast}
      />
    </div>
  );
};
