import {
  FormEngineModeTypeEnum,
  ROUTES,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import { ESignModalProvider, UserProvider } from '@celito.clients/provider';
import { lazy } from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import Layout from '../layouts';
import AddOneTimeAssignmentScreen from '../screens/add-one-time-assignment/add-one-time-assignment.component';
import AdminSettingsDocumentDataEditScreen from '../screens/admin-settings/document-data/edit/edit-document-data.screen';
import AdminSettingsDocumentDataViewScreen from '../screens/admin-settings/document-data/view/view-document-data.screen';
import DocumentTemplatesScreen from '../screens/admin-settings/document-templates/screens/edit/document-templates.screen';
import DocumentTemplatesView from '../screens/admin-settings/document-templates/screens/view/document-templates-view.screen';
import { FormMode } from '../screens/admin-settings/document-templates/types';
import DocumentTypeLayout from '../screens/admin-settings/document-type/component';
import DocumentTypeCreateScreen from '../screens/admin-settings/document-type/screens/edit/component';
import DocumentTypeListScreen from '../screens/admin-settings/document-type/screens/list/component';
import DocumentTypeViewScreen from '../screens/admin-settings/document-type/screens/view/component';
import ControlledDocumentViewEditScreen from '../screens/controlled-document/component';
import DelegationForm from '../screens/delegation/delegation-form/delegation-form.screen';
import DelegationList from '../screens/delegation/delegation-list/delegation-list.screen';
import VersionHistory from '../screens/doc-version-history/doc-version-history.screen';
import DocumentViewScreen from '../screens/document/document.screen';
import ESignature from '../screens/e-signature/e-signature.screen';
import EmailExportRedirect from '../screens/email-export-redirect/email-export-redirect';
import ILCTrainingAssignmentListScreen from '../screens/ilc-training-assignment/ilc-training-assignment-list-screen';
import ListViewScreen from '../screens/list-view-screen/list-view-screen.component';
import ObjectInitiationViewScreen from '../screens/object-initiation-screen/object-initiation.screen';
import OverdueTrainingAssignmentListScreen from '../screens/overdue-training-assignment/overdue-training-assignment-list-screen';
import PageNotFound from '../screens/page-not-found/page-not-found.screen';
import AdminUiExportImport from '../screens/Platform/admin-ui-screen/admin-ui-export-import/admin-ui-export-import.component';
import SearchScreen from '../screens/search/search-result/search-result.screen';
import TakingAssignmentScreen from '../screens/taking-assingment/taking-assingment.component';
import TakingQuizScreen from '../screens/taking-quiz/taking-quiz.component';
import TrainingAssignmentListScreen from '../screens/training-assignment-list/training-assignment-list-screen';
import TrainingMatrixScreen from '../screens/training-matrix/training-matrix.screen';
import UserLearningScreen from '../screens/user-learning/user-learning.component';
import AddUserScreen from '../screens/user-mgmt/add-user/add-user.screen';
import AddPermissionGroupScreen from '../screens/user-mgmt/permissions/add-group/add-group.screen';
import EditPermissionGroupScreen from '../screens/user-mgmt/permissions/edit-group/edit-group.screen';
import ViewPermissionGroupScreen from '../screens/user-mgmt/permissions/view-group/view-group.screen';
import UserManagementScreen from '../screens/user-mgmt/user/user.screen';
import ViewUserScreen from '../screens/user-mgmt/view-user/view-user.screen';
import WopiEditorScreen from '../screens/wopi-editor/wopi-editor.screen';

const HomeScreen = lazy(() => import('../screens/home/home.screen'));

const PermissionSet = lazy(
  () => import('../screens/user-mgmt/permission-set/permission-set.component')
);

const Roles = lazy(() => import('../screens/user-mgmt/roles/roles.component'));

const AdminUiScreen = lazy(
  () => import('../screens/Platform/admin-ui-screen/admin-ui.component')
);

const AdminUiNewObjectScreen = lazy(
  () =>
    import(
      '../screens/Platform/admin-ui-screen/admin-ui-object/admin-ui-new-object.component'
    )
);

const AdminUiObjects = lazy(
  () =>
    import(
      '../screens/Platform/admin-ui-screen/admin-ui-objects-list/admin-ui-objects-list.component'
    )
);

const AdminUiNavigation = lazy(
  () =>
    import(
      '../screens/Platform/admin-ui-screen/admin-ui-navigation/admin-ui-navigation.component'
    )
);

const AdminUiPicklist = lazy(
  () =>
    import(
      '../screens/Platform/admin-ui-screen/admin-ui-picklist/admin-ui-picklist.component'
    )
);

const NotificationForm = lazy(
  () =>
    import(
      '../screens/settings/notifications/notification-form/notification-form.screen'
    )
);

const WorkflowScreen = lazy(
  () => import('../screens/workflow/workflow.screen')
);

const ReportingScreen = lazy(
  () => import('../screens/reporting-screen/reporting-screen.component')
);

const CertificateForm = lazy(
  () =>
    import(
      '../screens/settings/certificate/certificate-form/certificate-form.screen'
    )
);

const CertificateViewPage = lazy(
  () =>
    import(
      '../screens/settings/certificate/certificate-view-page/certificate-view-page.screen'
    )
);

const NodeEditorPage = lazy(
  () =>
    import('../screens/workflow/node-page-header/node-page-header-component')
);

const DashboardListViewScreen = lazy(
  () => import('../screens/Platform/report/dashboard/dashboard.component')
);

const ChartListViewScreen = lazy(
  () => import('../screens/Platform/report/chart/chart.component')
);

const ProtectedRoutes = () => {
  const { layoutRef } = useLayout();
  const { mode } = useParams();
  return (
    <UserProvider>
      <ReactFlowProvider>
        <ESignModalProvider>
          <ESignature />
          <Routes>
            <Route path={ROUTES.WOPI_EDITOR} element={<WopiEditorScreen />} />

            <Route
              path={`${ROUTES.LAYOUT}/*`}
              element={
                <Layout ref={layoutRef}>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path={ROUTES.EMAIL_EXPORT_REDIRECT}
                element={<EmailExportRedirect />}
              />
              <Route path={ROUTES.SEARCH} element={<SearchScreen />} />
              <Route
                path={ROUTES.CONTROLLED_DOCS_CUSTOM_VIEW}
                element={
                  <ControlledDocumentViewEditScreen
                    mode={FormEngineModeTypeEnum.View}
                  />
                }
              />
              <Route
                path={ROUTES.CONTROLLED_DOCS_CUSTOM_EDIT}
                element={
                  <ControlledDocumentViewEditScreen
                    mode={FormEngineModeTypeEnum.Edit}
                  />
                }
              />
              <Route
                path={ROUTES.CONTROLLED_DOCS_LAYOUT}
                element={<DocumentTypeLayout />}
              >
                <Route
                  path={ROUTES.VIEW_ADMIN_DOCUMENT_TYPE}
                  element={<DocumentTypeViewScreen />}
                />
                <Route
                  path={ROUTES.CREATE_ADMIN_DOCUMENT_TYPE}
                  element={<DocumentTypeCreateScreen mode={FormMode.CREATE} />}
                />
                <Route
                  path={ROUTES.LIST_ADMIN_DOCUMENT_TYPE}
                  element={<DocumentTypeListScreen />}
                />
                <Route
                  path={ROUTES.EDIT_ADMIN_DOCUMENT_TYPE}
                  element={<DocumentTypeCreateScreen mode={FormMode.EDIT} />}
                />
              </Route>
              <Route index path={ROUTES.HOME_VIEW} element={<HomeScreen />} />

              <Route path={ROUTES.PERMISSION_SET} element={<PermissionSet />} />

              <Route path={ROUTES.ROLES} element={<Roles />} />

              <Route path={ROUTES.CONFIGURATION} element={<AdminUiScreen />} />
              <Route
                path={`${ROUTES.CONFIGURATION_OBJECTS}`}
                element={<AdminUiObjects />}
              />
              <Route
                path={`${ROUTES.CONFIGURATION_NAVIGATION}`}
                element={<AdminUiNavigation />}
              />
              <Route
                path={`${ROUTES.CONFIGURATION_PICKLIST}`}
                element={<AdminUiPicklist />}
              />
              <Route
                path={`${ROUTES.CONFIGURATION_EXPORT_IMPORT}`}
                element={<AdminUiExportImport />}
              />

              <Route
                path={`${ROUTES.CONFIGURATION_OBJECTS}/:objectName?/:tab`}
                element={<AdminUiNewObjectScreen />}
              />
              <Route path={ROUTES.TAKING_QUIZ} element={<TakingQuizScreen />} />
              <Route
                path={ROUTES.MY_TEAMS_ASSIGNMENTS_LIST_VIEW}
                element={
                  <TrainingAssignmentListScreen
                    trainingAssignmentType={
                      TrainingAssignmentTypeEnum.MyTeamsAssignments
                    }
                  />
                }
              />
              <Route
                path={ROUTES.COMPANY_ASSIGNMENTS_LIST_VIEW}
                element={
                  <TrainingAssignmentListScreen
                    trainingAssignmentType={
                      TrainingAssignmentTypeEnum.CompanyAssignments
                    }
                  />
                }
              />
              <Route
                path={ROUTES.ILC_ASSIGNMENTS_LIST_VIEW}
                element={
                  <ILCTrainingAssignmentListScreen
                    trainingAssignmentType={
                      TrainingAssignmentTypeEnum.ILCAssignments
                    }
                  />
                }
              />
              <Route
                path={ROUTES.OVERDUE_ASSIGNMENTS_LIST_VIEW}
                element={
                  <OverdueTrainingAssignmentListScreen
                    trainingAssignmentType={
                      TrainingAssignmentTypeEnum.OverdueAssignment
                    }
                  />
                }
              />
              <Route
                path={ROUTES.DOCUMENT_VIEW}
                element={<DocumentViewScreen />}
              />
              <Route
                path={ROUTES.TAKING_ASSINGMENTS}
                element={<TakingAssignmentScreen />}
              />
              <Route
                path={ROUTES.ADD_ONE_TIME_ASSIGNMENT}
                element={<AddOneTimeAssignmentScreen />}
              />

              <Route
                path={ROUTES.VIEW_ADMIN_DOCUMENT_TEMPLATE}
                element={<DocumentTemplatesView />}
              />
              <Route
                path={ROUTES.CREATE_ADMIN_DOCUMENT_TEMPLATE}
                element={<DocumentTemplatesScreen mode={FormMode.CREATE} />}
              />
              <Route
                path={ROUTES.EDIT_ADMIN_DOCUMENT_TEMPLATE}
                element={<DocumentTemplatesScreen mode={FormMode.EDIT} />}
              />

              <Route
                path={ROUTES.VIEW_ADMIN_DOCUMENT_DATA}
                element={<AdminSettingsDocumentDataViewScreen />}
              />
              <Route
                path={ROUTES.EDIT_ADMIN_DOCUMENT_DATA}
                element={<AdminSettingsDocumentDataEditScreen />}
              />

              <Route
                path={ROUTES.CREATE_NOTIFICATION}
                element={<NotificationForm mode="create" />}
              />
              <Route
                path={ROUTES.EDIT_NOTIFICATION}
                element={<NotificationForm mode="edit" />}
              />
              <Route
                path={ROUTES.VIEW_NOTIFICATION}
                element={<NotificationForm mode="view" />}
              />
              <Route path={ROUTES.USER} element={<UserManagementScreen />} />
              <Route path={ROUTES.ADD_USER} element={<AddUserScreen />} />

              <Route path={ROUTES.VIEW_USER} element={<ViewUserScreen />} />
              <Route
                path={ROUTES.ADD_PERMISSION_GROUP}
                element={<AddPermissionGroupScreen />}
              />
              <Route
                path={ROUTES.EDIT_PERMISSION_GROUP}
                element={<EditPermissionGroupScreen />}
              />
              <Route
                path={ROUTES.VIEW_PERMISSION_GROUP}
                element={<ViewPermissionGroupScreen />}
              />
              <Route path={ROUTES.WORKFLOW} element={<WorkflowScreen />} />
              <Route path={ROUTES.LIST_VIEW} element={<ListViewScreen />} />

              <Route
                path={ROUTES.REPORTING_VIEW}
                element={<ReportingScreen />}
              />
              <Route
                path={ROUTES.DASHBOARD_LIST_VIEW}
                element={<DashboardListViewScreen />}
              />
              <Route
                path={ROUTES.CHART_LIST_VIEW}
                element={<ChartListViewScreen />}
              />
              <Route
                path={ROUTES.VERSIONHISTORY}
                element={<VersionHistory />}
              />
              <Route path={ROUTES.DELEGATION} element={<DelegationList />} />
              <Route
                path={ROUTES.CREATE_DELEGATION}
                element={
                  <DelegationForm mode={FormEngineModeTypeEnum.Create} />
                }
              />
              <Route
                path={ROUTES.EDIT_DELEGATION}
                element={<DelegationForm mode={FormEngineModeTypeEnum.Edit} />}
              />
              <Route
                path={ROUTES.WORKFLOW_NODE_EDITOR}
                element={<NodeEditorPage />}
              />
              <Route
                path={ROUTES.CUSTOM_LIST_VIEW}
                element={<ObjectInitiationViewScreen />}
              />
              <Route
                path={ROUTES.EDIT_CERTIFICATE}
                element={<CertificateForm />}
              />
              <Route
                path={ROUTES.VIEW_CERTIFICATE}
                element={<CertificateViewPage mode="view" />}
              />
              <Route
                path={ROUTES.PREVIEW_CERTIFICATE}
                element={<CertificateViewPage mode="preview" />}
              />

              <Route
                path={ROUTES.TRAINING_MATRIX}
                element={<TrainingMatrixScreen />}
              />

              <Route path="*" element={<PageNotFound showFooter={false} />} />
            </Route>

            <Route
              path={`${ROUTES.LAYOUT}/*`}
              element={
                <Layout
                  ref={layoutRef}
                  initTitleAsLoading={mode === FormEngineModeTypeEnum.Edit}
                >
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path={ROUTES.USER_LEARNING}
                element={<UserLearningScreen />}
              />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ESignModalProvider>
      </ReactFlowProvider>
    </UserProvider>
  );
};

export default ProtectedRoutes;
