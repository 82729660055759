import { brandLogoWhite2x } from '@celito.clients/assets';
import { DateFormat, SpinnerSize } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { useTheme } from '@celito.clients/theme';
import { ModuleNamesEnum } from '@celito.clients/types';
import {
  createTestAttribute,
  doesElementHaveEllipses,
  formatDate,
  isDateValid,
} from '@celito.clients/utils';
import { Badge, CounterBadge, Tooltip } from '@fluentui/react-components';
import cn from 'classnames';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from '../../breadcrumb/breadcrumb';
import { FluentIconNames, Icon } from '../../icon';
import { RecordNavigation } from '../../record-navigation/record-navigation';
import { ConditionalWrapper, Loader, useBreadcrumbs } from '../../shared';
import {
  HeaderSidebar,
  NavLink,
  PrimaryDropdown,
  SecondaryDropdown,
} from './components';
import { NavLinkMultiple } from './components/nav-link-multiple';
import { HeaderProps, INavLinkGroup } from './header.model';
import classes from './header.module.css';
import { headerStyles } from './header.styles';
import getPageTitle from './helpers/get-page-title';
import { NavigationConfigItem, NavigationLink } from './types';

export interface HeaderViewProps extends HeaderProps {
  activeDropdownItem: NavigationConfigItem | undefined;
  activeNavItem: NavigationLink | undefined;
  activeSubNavItem: NavigationLink | undefined | null;
  showPrimaryDropdown: boolean;
  showSecondaryDropdown: boolean;
  isSidebarOpen: boolean;
  isTabletView: boolean;
  isMobileView: boolean;
  handleDropdownLinkClick: (selectedPage: NavigationConfigItem) => void;
  handleNavLinkClick: (selectedLinkKey?: string) => void;
  toggleSidebar: (value?: boolean) => void;
  togglePrimaryDropdown: () => void;
  toggleSecondaryDropdown: () => void;
  getNavLinkGroups: () => INavLinkGroup[];
  showHomeTitle: boolean;
  showHeaderTooltip: boolean;
  setShowHeaderTooltip: (value: boolean) => void;
  isUnreadMessageAvailable: boolean;
}

const HeaderView = (props: HeaderViewProps): JSX.Element => {
  const {
    activeDropdownItem,
    HeaderNavLinkComponent,
    headerRightActionsConfig,
    CustomLogoComponent,
    isTabletView,
    isMobileView,
    isSidebarOpen,
    toggleSidebar,
    dataTestId,
    pageHeading,
    user,
    isPageHeadingLoading,
    showHeaderTooltip,
    setShowHeaderTooltip,
    checkInCheckOutIcon,
    screenActionsContainerRef,
    isUnreadMessageAvailable,
    initTitleAsLoading = false,
  } = props;

  const initialLoadingStateRef = useRef(isPageHeadingLoading);
  const isLoadingStateUpdatedRef = useRef(false);
  const activeModule = useActiveModule();
  const { breadcrumbs } = useBreadcrumbs();

  const { cssVariables } = useTheme();

  const styles = headerStyles();

  const titleRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [hiddenButtonIndices, setHiddenButtonIndices] = useState<number[]>([]);

  useEffect(() => {
    if (titleRef.current) {
      if (doesElementHaveEllipses(titleRef.current)) {
        setShowHeaderTooltip(true);
      } else {
        setShowHeaderTooltip(false);
      }
    }
  }, [pageHeading]);

  useEffect(() => {
    if (initialLoadingStateRef.current !== isPageHeadingLoading) {
      isLoadingStateUpdatedRef.current = true;
    }
  }, [isPageHeadingLoading]);

  useLayoutEffect(() => {
    const measureOverflow = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 1000) {
        setHiddenButtonIndices([]);
      } else if (containerRef.current) {
        const container = containerRef.current;
        const buttons = Array.from(container.querySelectorAll('button'));
        const containerRect = container.getBoundingClientRect();

        const hiddenIndices = buttons.reduce(
          (indices: number[], button, index) => {
            const buttonRect = button.getBoundingClientRect();

            if (
              buttonRect.right > containerRect.right ||
              buttonRect.left < containerRect.left
            ) {
              // Button is fully overflowed on the right or left
              indices.push(index);
            }
            return indices;
          },
          []
        );

        setHiddenButtonIndices(hiddenIndices);
      }
    };

    // Delay the measurement to allow the layout to stabilize
    const timerId = setTimeout(measureOverflow, 100);

    window.addEventListener('resize', measureOverflow);

    return () => {
      clearTimeout(timerId);
      window.removeEventListener('resize', measureOverflow);
    };
  }, [activeDropdownItem]);

  const isInitialLoad = isLoadingStateUpdatedRef.current === false;

  const showInitialLoading = initTitleAsLoading && isInitialLoad;
  const pageTitle = getPageTitle(props, isInitialLoad);

  return (
    <div
      className={styles.container}
      style={{ ...cssVariables }}
      data-testid={dataTestId}
    >
      <div className={styles.headerExtraOverflow} />
      <div className={classes.headerContainer}>
        {/* Mobile sidebar hamburger */}
        {isTabletView && (
          <button
            className={classes.hamburgerNavIconBtn}
            onClick={() => toggleSidebar()}
          >
            <Icon
              iconName="Navigation20Filled"
              className={classes.hamburgerNavIcon}
            />
          </button>
        )}
        {isSidebarOpen && <HeaderSidebar {...props} />}

        {/* Logo */}
        <Link to={activeDropdownItem?.link ?? '/'}>
          {CustomLogoComponent ? (
            <CustomLogoComponent />
          ) : (
            <img
              src={brandLogoWhite2x}
              data-testid="img-logo"
              alt="logo"
              className={styles.logo}
            />
          )}
        </Link>

        {/* Dropdown */}
        {!isMobileView && <PrimaryDropdown {...props} />}

        {/* Navigation links menuContainer-- #Do not remove the id, is used for dynamic overflow*/}
        {!isTabletView && (
          <div ref={containerRef} className={classes.navigationContainer}>
            {activeDropdownItem?.navigationLinks
              .filter((navLink) => !navLink.isHidden)
              .map((navLink, index) => {
                return (
                  <NavLink
                    index={index}
                    hiddenButtonIndices={hiddenButtonIndices}
                    key={`${navLink.systemName}-${index}`}
                    HeaderNavLinkComponent={HeaderNavLinkComponent}
                    navLink={navLink}
                    {...props}
                  />
                );
              })}
          </div>
        )}

        {hiddenButtonIndices.length > 0 && (
          <NavLinkMultiple
            icon={
              <Icon
                iconName="MoreHorizontal24Regular"
                className={styles.moreIconColor}
              />
            }
            menuItems={activeDropdownItem?.navigationLinks
              .filter((navLink) => !navLink.isHidden)
              .filter((navLink, index) =>
                hiddenButtonIndices.some((item) => item === index)
              )}
            handleNavLinkClick={props.handleNavLinkClick}
          />
        )}

        {/* Pushes all the content to the right */}
        <div className={classes.rightSideContent} />

        {/* Secondary Dropdown */}
        {!isMobileView && <SecondaryDropdown {...props} />}

        {/* Right Actions */}
        <div className={classes.rightSideActionsContainer}>
          {headerRightActionsConfig.map((rightActionItem, index) =>
            rightActionItem.isMenu ? (
              activeDropdownItem?.settingLinks?.subLinks &&
              activeDropdownItem?.settingLinks?.subLinks.length > 0 && (
                <NavLinkMultiple
                  key={index}
                  icon={
                    <Icon
                      variant={rightActionItem.iconVariant}
                      iconName={rightActionItem.iconName as FluentIconNames}
                      className={cn(
                        classes.rightSideActionIcon,
                        rightActionItem.className
                      )}
                    />
                  }
                  menuItems={activeDropdownItem?.settingLinks?.subLinks?.filter(
                    (navLink) => !navLink.isHidden
                  )}
                  handleNavLinkClick={props.handleNavLinkClick}
                />
              )
            ) : (
              <button
                data-testid={`button-${createTestAttribute(
                  rightActionItem.dataTestId
                )}`}
                onClick={rightActionItem.onClick}
                className={classes.rightSideActionBtn}
                key={index}
              >
                {rightActionItem.CustomIcon ? (
                  <rightActionItem.CustomIcon user={user} />
                ) : (
                  <Fragment>
                    <Badge appearance="ghost">
                      <Icon
                        variant={rightActionItem.iconVariant}
                        iconName={rightActionItem.iconName as FluentIconNames}
                        className={cn(
                          classes.rightSideActionIcon,
                          rightActionItem.className
                        )}
                      />
                    </Badge>
                    {rightActionItem.iconName === 'Alert20Regular' &&
                    isUnreadMessageAvailable ? (
                      <CounterBadge
                        count={0}
                        dot
                        className={classes.badgeIcon}
                        color="danger"
                      />
                    ) : null}
                  </Fragment>
                )}
              </button>
            )
          )}
        </div>
      </div>

      <div className={classes.headerBottomContainer}>
        {isPageHeadingLoading || showInitialLoading ? (
          <div className={classes.pageHeadingLoaderContainer}>
            <div className={classes.pageHeadingLoader}>
              <Loader
                color="white"
                withLabel={false}
                size={SpinnerSize.large}
              />
            </div>
          </div>
        ) : (
          <>
            <ConditionalWrapper
              Wrapper={({ children }) => (
                <Tooltip relationship="description" content={pageTitle ?? ''}>
                  {children as JSX.Element}
                </Tooltip>
              )}
              condition={showHeaderTooltip}
            >
              <div className={classes.titleContainer}>
                <div className={styles.pageTitleContainer}>
                  {breadcrumbs.length > 0 &&
                  activeModule?.systemName !== ModuleNamesEnum.ADMIN ? (
                    <Breadcrumb items={breadcrumbs} />
                  ) : (
                    <h1
                      data-testid="typography-heading"
                      className={styles.heading}
                      ref={titleRef}
                    >
                      {pageTitle}
                    </h1>
                  )}
                </div>
                <RecordNavigation />
                {checkInCheckOutIcon?.checkoutTime !== null &&
                checkInCheckOutIcon?.checkoutTime !== undefined
                  ? checkInCheckOutIcon?.isLocked && (
                      <Tooltip
                        content={`The document is locked by ${
                          checkInCheckOutIcon?.checkoutBy || ''
                        } ${
                          isDateValid(
                            checkInCheckOutIcon?.checkoutTime as unknown as string
                          )
                            ? formatDate(
                                (checkInCheckOutIcon?.checkoutTime as unknown as string) ||
                                  '',
                                DateFormat.DateTime
                              )
                            : ''
                        }`}
                        relationship="label"
                        positioning="below-start"
                      >
                        <span className={classes.tooltipIcon}>
                          <Icon
                            iconName="LockClosed24Regular"
                            className={classes.lockIconContainer}
                          />
                        </span>
                      </Tooltip>
                    )
                  : null}
              </div>
            </ConditionalWrapper>

            {(function ScreenActionsContainer() {
              return (
                <div
                  ref={screenActionsContainerRef}
                  //FIXME: Make this not absolutely positioned
                  style={{
                    display: 'flex',
                    gap: 20,
                    position: 'fixed',
                    justifyContent: 'end',
                    right: 29,
                    top: 80,
                  }}
                />
              );
            })()}
          </>
        )}
        {isMobileView && (
          <div className={classes.mobileViewDropdownsContainer}>
            <PrimaryDropdown {...props} />
            <SecondaryDropdown {...props} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderView;
