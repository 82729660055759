import { ApiMethodType, OperatorsEnum } from '@celito.clients/enums';
import { SortConfig } from '@celito.clients/list-view-engine';
import { performFormRequest, performRequest } from '@celito.clients/network';
import { FilterCriteria } from '@celito.clients/types';
import { AxiosResponse } from 'axios';
import Endpoints from 'libs/core/src/config/endpoint';
import { concat, isEqual, pick } from 'lodash';

import { FormMode } from '../document-templates/types';
import { TFieldValues } from '../document-type/screens/edit/controller';
import {
  TDataTypesResponse,
  TGetDocumentTypeListReponse,
  TGetDocumentTypeResponse,
  TUpdateDocumentTypeResopnse,
} from './types';

const objectName = 'document_type__a';

interface GetObjectDataOptions {
  filters?: FilterCriteria[];
}

const localStorageKey = 'DOCUMENT_TYPE';
export class DocumentTypeService {
  data: TFieldValues;
  /**
   * Document Type Name
   */
  documentTypeNameFromParams?: string;

  constructor(data: TFieldValues, documentTypeNameFromParams?: string) {
    this.data = data;
    this.documentTypeNameFromParams = documentTypeNameFromParams;
  }

  readonly submitForm = async () => {
    if (isEqual(this.data.formMode, FormMode.EDIT)) {
      await this.updateDocumentType();
    } else {
      await this.createDocumentType();
    }
  };

  generatePayload = () => {
    const payload = Object.assign(
      pick(
        this.data,
        concat(
          [
            'isTrainable',
            'isRawFileDownloadAllowed',
            'isAdminReviewRequired',
            'effectivenessPeriodInDays',
            'isFollowUpRequired',
            'documentTemplate',
            'isGoverningDocumentRequired',
          ],
          (() => {
            if (this.data.isFollowUpRequired)
              return [
                'followUpTimePeriodInMonths',
                'followUpTriggerPeriodInDays',
              ];
            return [];
          })()
        )
      ),
      {
        isActive: true,
        documentTemplate: this.data.templateName,
        labelFormat: this.data.labelFormat || null,
        documentType: {
          label: this.data.documentType,
          code: this.data.documentTypeCode,
          name: !this.data.newDocumentType
            ? this.data.documentTypeName
            : undefined,
        },
        ...(this.data.documentSubType
          ? {
              documentSubType: {
                label: this.data.documentSubType,
                code: this.data.documentSubTypeCode,
                name: !this.data.newDocumentSubType
                  ? this.data.documentSubTypeName
                  : undefined,
              },
            }
          : {}),
      }
    );
    return payload;
  };

  createDocumentType = async () => {
    const payload = this.generatePayload();
    const response = (await performFormRequest(
      'document-type/save',
      ApiMethodType.POST,
      payload
    )) as AxiosResponse<TUpdateDocumentTypeResopnse, never>;
    return response.status === 200;
  };

  updateDocumentType = async (recordName?: string) => {
    const payload = this.generatePayload();
    const response = (await performFormRequest(
      'document-type/update',
      ApiMethodType.POST,
      payload,
      { recordName }
    )) as AxiosResponse<TUpdateDocumentTypeResopnse, never>;
    return response.status === 200;
  };

  static readonly getDocumentTypes = async (search: string) => {
    const response = (await performRequest(
      `${Endpoints.OBJECT_DATA_URL}/${objectName}/`,
      ApiMethodType.POST,
      {
        filters: [
          {
            attribute: 'label__s',
            operator: OperatorsEnum.CONTAINS,
            value: search,
          },
        ],
      } satisfies GetObjectDataOptions,
      {
        limit: 10,
        page: 1,
        getLatestVersionOnly: true,
      }
    )) as AxiosResponse<TDataTypesResponse, never>;
    return response;
  };
  static readonly getDocumentType = async (documentTypeName: string) => {
    const response = (await performRequest(
      `${Endpoints.OBJECT_DATA_URL}/${objectName}/${documentTypeName}`,
      ApiMethodType.GET,
      {
        limit: 10,
        page: 1,
        getLatestVersionOnly: true,
      }
    )) as AxiosResponse<TGetDocumentTypeResponse, never>;
    return response;
  };
  /**
   *
   * @returns list of document types for the list view
   */
  static readonly getDocumentTypeList = async (payload?: {
    documentTypeFilterSort?: {
      sort: SortConfig;
    };
    documentSubTypeFilterSort?: {
      sort: SortConfig;
    };
  }) => {
    const response = (await performRequest(
      '/document-type/',
      ApiMethodType.POST,
      payload ?? {}
    )) as AxiosResponse<TGetDocumentTypeListReponse, never>;
    return response;
  };
  static readonly toggleDocumentType = async (
    recordName: string,
    isActive: boolean,
    toggleDocumentType = true
  ) => {
    const actionPath = toggleDocumentType
      ? // Spelling is intentionally wrong
        'docuemnt_type_action__a'
      : 'document_sub_type_action__a';

    const response = (await performRequest(
      `${Endpoints.EXECUTE_ACTION}/${
        isActive ? 'deactivate' : 'activate'
      }_${actionPath}?record_name=${recordName}`,
      ApiMethodType.POST,
      {}
    )) as AxiosResponse<TUpdateDocumentTypeResopnse, never>;
    return response.status === 200;
  };

  static readonly setLocalDocumentType = async (
    data: TGetDocumentTypeResponse
  ) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };
  static readonly getLocalDocumentType = async () => {
    const localString = localStorage.getItem(localStorageKey);
    if (localString) return JSON.parse(localString) as TGetDocumentTypeResponse;
  };
}
