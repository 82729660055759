import { makeStyles, tokens } from '@fluentui/react-components';

export const adminUiExportImportStyles = makeStyles({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '550px',
    height: 'calc(-2rem + 100%)',
    marginInline: 'auto',
    paddingTop: '16px',
    '@media (max-width: 768px)': {
      width: '100%',
      paddingTop: '12px',
    },
  },

  card: {
    padding: 0,
    gap: 0,
    height: '100%',
  },

  cardHeader: {
    backgroundColor: '#f4f5fd',
    padding: '12px',
    fontWeight: '600',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '12px',
    gap: '12px',
    overflowY: 'auto',
  },

  loadingSpinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBlock: 'auto',
  },

  cardActions: {
    display: 'flex',
    gap: '12px',
    padding: '4px 12px',
    justifyContent: 'space-between',
    borderTop: `1px solid ${tokens.colorNeutralBackground5}`,
  },

  buttonPrev: {
    marginRight: 'auto',
    color: tokens.colorBrandBackground,
  },

  buttonNext: {
    marginLeft: 'auto',
    color: tokens.colorBrandBackground,
  },

  exportSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'end',
    justifyItems: 'end',
  },

  download: {
    display: 'grid',
    justifyItems: 'center',
    gap: '8px',
    marginBlock: 'auto',
  },
});
