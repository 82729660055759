import { initAxios } from '@celito.clients/network';
import { logger } from '@celito.clients/services';
import { ErrorOrUnauthorized } from '@celito.clients/shared';
import { MsalInstanceManager } from '@celito.clients/singleton';
import { usePrevious } from 'libs/core/src/hooks/useEffectWithDependencyMonitor';
import { Suspense, useEffect } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router';

import { Loader } from '../../../../libs/shared/src/lib/loader/src';
import RouteConfig from '../routes';
import styles from './app.module.css';

const msalSingletonInstance = MsalInstanceManager.getInstance();

export const App = () => {
  const location = useLocation();
  const [from] = usePrevious([location.pathname], ['/']);

  useEffect(() => {
    initAxios();
    logger.init();

    const isInitialized = sessionStorage.getItem('msalInitialized');

    if (!isInitialized) {
      msalSingletonInstance.initMsal();
      sessionStorage.setItem('msalInitialized', 'true');
    }
  }, []);

  useEffect(() => {
    logger.info('Location changed', {
      type: 'navigation',
      from,
      to: location.pathname,
    });
  }, [location.pathname, from]);

  return (
    <Suspense
      fallback={
        <div className={styles.container}>
          <Loader withLabel />
        </div>
      }
    >
      <RouteConfig />
    </Suspense>
  );
};

export default withErrorBoundary(App, {
  onError(error, info) {
    logger.error(error, { origin: 'ErrorBoundary', ...info });
  },
  fallback: (
    <div
      style={{
        display: 'grid',
        placeContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <ErrorOrUnauthorized />
    </div>
  ),
});
