import {
  ObjectAttributeDefinition,
  ObjectAttributeType,
} from '@celito.clients/types';
import { useMemo } from 'react';

import {
  TUseObjectDefinitionArgs,
  useObjectDefinition,
} from '../useObjectDefinition';

export const useObjectAttributeDefinitions = <
  K extends string | number | symbol,
  T = ObjectAttributeType
>({
  ...props
}: TUseObjectDefinitionArgs<T>) => {
  const {
    data: objectDefinition,
    isLoading,
    error,
  } = useObjectDefinition({
    ...props,
    queryOptions: {
      enabled: true,
    },
  });

  const data = useMemo(() => {
    if (!objectDefinition?.objectAttributeDefinitions) {
      return {} as Record<K, ObjectAttributeDefinition>;
    }

    return objectDefinition.objectAttributeDefinitions.reduce(
      (acc, attribute: ObjectAttributeDefinition) => {
        acc[attribute.key as K] = attribute;
        return acc;
      },
      {} as Record<K, ObjectAttributeDefinition>
    );
  }, [objectDefinition]);

  return { data, isLoading, error };
};
