import { ApiMethodType, MetadataItem } from '@celito.clients/enums';
import { performFormRequest, performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';
import { useQuery } from 'react-query';

interface IMetadataStructureResponse {
  items: MetadataItem[];
}

interface ImportResult {
  item: string;
  result: string;
  error: string;
}
interface IMetadataImportResponse {
  results: ImportResult[];
}
interface IMetadataStructureDto {
  items: MetadataItem[];
}

interface IMetadatExportResponse {
  url: string;
}

export async function postMetadataFile(payload: IMetadataStructureDto) {
  const respone = await performRequest<IMetadatExportResponse>(
    `${Endpoints.METADATA_FILE}`,
    ApiMethodType.POST,
    payload
  );

  return respone.data;
}

export async function postMetadataReadFile(payload: FormData) {
  const respone = await performFormRequest(
    `${Endpoints.METADATA_READ_FILE}`,
    ApiMethodType.POST,
    payload
  );

  return respone.data as IMetadataStructureResponse;
}

export async function postMetadataImport(formData: FormData) {
  const respone = await performFormRequest(
    `${Endpoints.METADATA_IMPORT_DATA}`,
    ApiMethodType.POST,
    formData
  );

  return respone.data as IMetadataImportResponse;
}

export function useGetMetadataStructure() {
  async function getMetadataStructure() {
    const respone = await performRequest<IMetadataStructureResponse>(
      `${Endpoints.METADATA_STRUCTURE}`,
      ApiMethodType.GET
    );

    return respone.data;
  }

  return useQuery(['metadata-structure'], getMetadataStructure);
}
