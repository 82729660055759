import { makeStyles, tokens } from '@fluentui/react-components';

export const useStepperStyles = makeStyles({
  stepperContainer: {
    display: 'grid',
    width: '100%',
  },

  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    gap: '4px',
    height: 'fit-content',
  },

  step: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'transparent',
    borderRadius: '100%',
    padding: 0,
    minWidth: '2lh',
    minHeight: '2lh',
    height: '2lh',
    width: '2lh',
    backgroundColor: '#e8e8e8',
  },

  stepActive: {
    color: tokens.colorNeutralForegroundInverted2,
    backgroundColor: tokens.colorBrandBackground,
  },

  divider: {
    position: 'absolute',
    top: '1.2lh',
    width: 'auto',
    left: 'calc(-50% + 1.4lh)',
    right: 'calc(50% + 1.4lh)',
    '::before': {
      borderTop: `2px solid ${tokens.colorBrandBackground}`,
    },
    '::after': {
      borderTop: `2px solid ${tokens.colorBrandBackground}`,
    },
  },
});
