import { ApiMethodType, ObjectEnum } from '@celito.clients/enums';
import { SortConfig } from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import { getModuleUsers } from '@celito.clients/services';
import { useEndpointStatesAndFunctions } from '@celito.clients/shared';
import { Filter } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';
import { useInfiniteQuery } from 'react-query';

import { RulesComponentListViewFilter } from '../../rules-component/types/rules-component.types';
import { convertCustomFilter } from '../utils';

export interface ICommonData {
  name: string;
  label: string;
  [key: string]: any;
}

interface IData {
  limit: number;
  page: number;
  total: number;
  data: ICommonData[];
}

export function useGetReferenceData(
  modules: string[],
  roles: string[],
  filterByExactRoles: boolean,
  objectName?: string,
  filters?: RulesComponentListViewFilter,
  defaultReferenceFilter?: Filter[] | RulesComponentListViewFilter[],
  sortConfig?: SortConfig,
  getLatestVersionOnly?: boolean,
  showSystemUser?: boolean,
  getAllVersions?: boolean
) {
  const {
    currentPage,
    setPaginationConfig,
    paginationConfig,
    setCurrentPage,
    setFilters,
    filters: stateFilters,
  } = useEndpointStatesAndFunctions();

  const fetchReferenceData = async ({ pageParam = 1 }) => {
    if (!objectName) return [];

    let response;
    const requestBody: Record<string, any> = {
      filters:
        stateFilters ?? convertCustomFilter(filters, defaultReferenceFilter),
      sort: sortConfig ?? undefined,
    };

    if (objectName === ObjectEnum.USER) {
      response = await getModuleUsers<ICommonData>(requestBody, {
        modules,
        roles,
        filterByExactRoles,
        includeSystemUser: showSystemUser,
      });
    } else {
      response = await performRequest<IData>(
        `${Endpoints.OBJECT_DATA_URL}/${objectName}`,
        ApiMethodType.POST,
        requestBody,
        {
          limit: 10,
          page: pageParam,
          ...(getAllVersions
            ? { getAllVersions: true }
            : getLatestVersionOnly === false || getLatestVersionOnly === true
            ? { getLatestVersionOnly: getLatestVersionOnly }
            : { getMaxMajorVersionOnly: true }),
        }
      );
    }

    setPaginationConfig({
      totalItems: response.data.total,
      itemsPerRow: response.data.limit,
    });

    setCurrentPage(pageParam);

    return response.data.data;
  };

  return {
    objectData: useInfiniteQuery({
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      queryKey: ['reference-picker-data', stateFilters, objectName, roles],
      queryFn: ({ pageParam }) => fetchReferenceData({ pageParam }),
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.reduce((acc, page) => {
          return [...acc, ...page];
        });

        return allData?.length < paginationConfig.totalItems
          ? allPages.length + 1
          : undefined;
      },
    }),
    filters,
    currentPage,
    setFilters,
  };
}
