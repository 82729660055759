import { OperatorsEnum } from '@celito.clients/enums';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { isMajorVersion } from '@celito.clients/utils';
import {
  IFilterView,
  ISimplifedFilter,
} from 'libs/shared/src/lib/rules-component/types/rules-component.types';

interface OutboundRelationships {
  [key: string]: {
    column: string;
    result: unknown[];
  };
}

export function getFiltersToApply(
  selectedFilterView?: IFilterView,
  recordName?: string,
  recordVersion?: string,
  referenceFilter?: ObjectAttributeDefinition,
  objectName?: string,
  viewObjectName?: string,
  outboundRelationships?: OutboundRelationships
) {
  let filtersToApply = JSON.parse(
    JSON.stringify(selectedFilterView?.filtersToBeApplied[0] ?? {})
  );

  let newFilter: ISimplifedFilter | undefined;

  if (referenceFilter) {
    newFilter = {
      attribute: referenceFilter.name,
      operator: OperatorsEnum.IN,
      value:
        recordVersion && isMajorVersion(recordVersion)
          ? [{ name: recordName, version: recordVersion }]
          : [recordName],
    };
  } else if (viewObjectName && outboundRelationships?.[viewObjectName]) {
    newFilter = {
      attribute: outboundRelationships[viewObjectName].column,
      operator: OperatorsEnum.IN,
      value: outboundRelationships[viewObjectName].result,
    };
  }

  if (!filtersToApply.conditions) {
    if (referenceFilter)
      filtersToApply.conditions = {
        all: [
          {
            all: [],
          },
        ],
      };
    else filtersToApply = [];
  }

  const firstCondition = filtersToApply?.conditions?.all?.[0];

  if (newFilter) {
    if (firstCondition?.all) {
      firstCondition?.all.push(newFilter);
    } else if (firstCondition?.any) {
      firstCondition.any.push(newFilter);
    }
  }

  return filtersToApply;
}
