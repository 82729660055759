import { Dependency, MetadataItem } from '@celito.clients/enums';
import { getDependenciesLabels } from '@celito.clients/utils';
import {
  Caption1,
  Caption1Strong,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  TreeItemValue,
} from '@fluentui/react-components';
import { ErrorCircle24Filled, Info24Filled } from '@fluentui/react-icons';

export const Dependencies = ({
  dependencies,
  checkedItems,
  items,
}: {
  dependencies: Dependency[];
  checkedItems: Set<TreeItemValue>;
  items: MetadataItem[];
}) => {
  const { labels, indexes } = getDependenciesLabels(dependencies, items);

  const dependenciesLabels = labels.filter((label, i) => {
    return !checkedItems.has(indexes[i]);
  });

  if (dependenciesLabels.length === 0) {
    return null;
  }

  return (
    <Popover withArrow>
      <PopoverTrigger data-testid="tree-item-dependency">
        <Info24Filled tabIndex={0} color="orange" />
      </PopoverTrigger>
      <PopoverSurface tabIndex={0} style={{ padding: '12px' }}>
        <Caption1Strong>Dependencies:</Caption1Strong>
        <Caption1>
          {dependenciesLabels.map((label) => (
            <div key={label}>- {label}</div>
          ))}
        </Caption1>
      </PopoverSurface>
    </Popover>
  );
};

export const Errors = ({ errors }: { errors: string[] }) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <Popover withArrow>
      <PopoverTrigger data-testid="tree-item-error">
        <ErrorCircle24Filled tabIndex={0} color="red" />
      </PopoverTrigger>
      <PopoverSurface tabIndex={0} style={{ padding: '12px' }}>
        <Caption1Strong>Errors:</Caption1Strong>
        <Caption1>
          {errors.map((e) => (
            <div key={e}>- {e}</div>
          ))}
        </Caption1>
      </PopoverSurface>
    </Popover>
  );
};
