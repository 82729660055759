import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { getCoursePageUrl } from '@celito.clients/utils';
import { Link } from 'react-router-dom';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { CustomButton } from '../../../shared';
import classes from '../reference-selector.module.css';

export const getDefaultColumnsSelectorGridcolumns = (
  onItemDelete?: (item: RowItem) => void,
  isPreviewTab?: boolean,
  onColumnClick?: ColumnData['onColumnClick']
) => {
  const courseGridColumns: ColumnData[] = [
    {
      name: 'Label',
      isResizable: true,
      key: 'label',
      maxWidth: 200,
      minWidth: 100,
      fieldName: 'label',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item: RowItem) => (
        <Link
          to={getCoursePageUrl(item.name, item.version)}
          className={classes.hyperlink}
          target="_blank"
        >
          {item.label}
        </Link>
      ),
    },
    {
      name: 'Name',
      isResizable: true,
      key: 'name',
      maxWidth: 120,
      minWidth: 100,
      fieldName: 'name',
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'actions',
      minWidth: 75,
      maxWidth: 100,
      name: LocalizationString.ACTIONS,
      fieldName: 'actions',
      isResizable: true,
      onRender: (item?: RowItem, index?: number, column?: ColumnData) => (
        <CustomButton
          as="button"
          buttonType={ButtonTypes.Destructive}
          customStyles={classes.delete_btn}
          leftIcon="Delete"
          title="Delete"
          onClick={isPreviewTab ? undefined : () => onItemDelete?.(item!)}
          disabled={isPreviewTab}
        />
      ),
    },
  ];
  return courseGridColumns;
};
