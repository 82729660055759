import { LayoutRulesDataSchema } from '@celito.clients/types';
import { createContext } from 'react';

import {
  ApiValidationError,
  WizardStepState,
} from '../components/form-wizard/form-wizard.model';
import { FormEngineContextType } from '../types';

export const FormEngineContext = createContext<FormEngineContextType>({
  fieldsState: {},
});

interface FormEngineContextProviderProps {
  children: React.ReactNode;
  fieldsState: Record<string, LayoutRulesDataSchema>;
  setFieldsState?: React.Dispatch<
    React.SetStateAction<Record<string, LayoutRulesDataSchema>>
  >;
  fetchAttributeConfig?: (objectName: string) => Promise<void>;
  isWizardStateVisible?: boolean;
  isCompleterBarVisible?: boolean;
  fieldsApiErrors?: ApiValidationError;
  setFieldsApiErrors?: React.Dispatch<React.SetStateAction<ApiValidationError>>;
  setWizardStepsState?: React.Dispatch<React.SetStateAction<WizardStepState[]>>;
  outboundRelationships?: Record<string, { column: string; result: string[] }>;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const FormEngineContextProvider = ({
  children,
  fieldsState,
  setFieldsState,
  fetchAttributeConfig,
  isWizardStateVisible,
  isCompleterBarVisible,
  fieldsApiErrors,
  setFieldsApiErrors,
  setWizardStepsState,
  outboundRelationships,
  onCancel,
  onSubmit,
}: FormEngineContextProviderProps) => {
  return (
    <FormEngineContext.Provider
      value={{
        fieldsState,
        setFieldsState,
        fetchAttributeConfig,
        isWizardStateVisible,
        isCompleterBarVisible,
        fieldsApiErrors,
        setFieldsApiErrors,
        setWizardStepsState,
        outboundRelationships,
        onCancel,
        onSubmit,
      }}
    >
      {children}
    </FormEngineContext.Provider>
  );
};
