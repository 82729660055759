import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes, FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useURLParams } from '@celito.clients/hooks';
import { ListView } from '@celito.clients/list-view-engine';
import {
  CustomButton,
  FormActionButtonsContainer,
} from '@celito.clients/shared';
import { useFormContext } from 'react-hook-form';

import { ConfirmButton } from '../../components/wizard-container/confirm-button/confirm-button.component';
import { getFormActionsVisibilityRules } from '../../utils/helper';
import { RelatedOjectsViewProps } from './related-objects.model';
import classes from './related-objects.module.css';

export const RelatedObjectsView = ({
  isLoading,
  attributeConfig,
  recordDetails,
  isSubmitting,
  viewDto,
  onSave,
  showFormActions = true,
  className,
  recordfilter,
  pageComponent,
  ...props
}: RelatedOjectsViewProps): JSX.Element => {
  const [, viewName] = pageComponent ? pageComponent.split('/') : ['', ''];

  const isSubmitDisabled =
    isSubmitting ||
    props.wizardStepsState.some((step) => step.isValid === false);
  const { urlParams } = useURLParams();
  const {
    formState: { isDirty },
  } = useFormContext();
  const requestType = urlParams.get('requestType');
  const isUpdateRequest = requestType?.includes('Update');
  const isEditAction = !isUpdateRequest;

  const showSubmitConfirmationPopup = Boolean(
    viewDto?.view?.[0]?.showSubmitConfirmationPopup
  );

  const submitConfirmationPopupMessage =
    viewDto?.view?.[0]?.submitConfirmationPopupMessage;

  const { showSaveButton, showSubmitButton } = getFormActionsVisibilityRules(
    attributeConfig?.lifecycleStageGroups,
    recordDetails?.lifecycleStage as string,
    isEditAction ? 'edit' : 'update',
    props.mode
  );

  return (
    <>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <ListView
            key={viewName}
            viewName={viewName}
            dontSetHeader={true}
            objectName={props.objectName}
            recordName={props.recordName}
            showAddButton={false}
            recordVersion={props.recordData?.version as string}
          />
        </div>
      </div>
      {showFormActions && (
        <FormActionButtonsContainer>
          <CustomButton
            buttonTitle={LocalizationString.CANCEL}
            buttonType={ButtonTypes.Ghost}
            onClick={props.onCancel}
          />
          <div className={classes.formActionBtns}>
            <CustomButton
              buttonTitle={LocalizationString.PREVIOUS}
              leftIcon="Previous"
              buttonType={ButtonTypes.Ghost}
              onClick={props.goToPreviousStep}
            />
            {props.step < props.totalSteps - 1 && (
              <CustomButton
                buttonTitle={LocalizationString.NEXT}
                leftIcon="Next"
                buttonType={ButtonTypes.Ghost}
                onClick={props.goToNextStep}
              />
            )}

            {props.mode === FormEngineModeTypeEnum.Edit && showSaveButton && (
              <CustomButton
                buttonTitle="Save"
                leftIcon="Save"
                buttonType={ButtonTypes.Secondary}
                isLoading={isLoading}
                disabled={isLoading || isSubmitting || !isDirty}
                onSubmit={
                  !isLoading
                    ? () => onSave?.(false) as Promise<void>
                    : undefined
                }
              />
            )}
            {showSubmitButton && props.step === props.totalSteps - 1 && (
              <ConfirmButton
                buttonTitle={'Submit'}
                leftIcon="Submit"
                buttonType={ButtonTypes.Primary}
                isLoading={isSubmitting}
                disabled={isSubmitDisabled}
                onSubmit={
                  !isSubmitting
                    ? () => onSave?.(true) as Promise<void>
                    : undefined
                }
                showSubmitConfirmationPopup={showSubmitConfirmationPopup}
                dialogProps={{
                  primaryButtonText: LocalizationString.SUBMIT,
                  title: LocalizationString.SUBMIT,
                  description: submitConfirmationPopupMessage,
                  secondaryButtonText: LocalizationString.CANCEL,
                  iconSrc: WarningIcon,
                }}
              />
            )}
          </div>
        </FormActionButtonsContainer>
      )}
    </>
  );
};
