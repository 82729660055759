import { makeStyles } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const FormTypeRendererStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    padding: '1rem 1rem',
    backgroundColor: Color.drawer_header_color,
    justifyContent: 'space-between',
  },

  dialogSurface: {
    width: '80%',
    height: '90%',
    maxWidth: 'unset',
    padding: 0,
  },
});
