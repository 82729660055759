import { OperatorsEnum } from '@celito.clients/enums';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledReferenceSelectorProps } from './controlled-reference-selector.model';
import { ControlledReferencePickerView } from './controlled-reference-selector.view';

export const ControlledReferenceSelectorController = <T extends FieldValues>(
  props: ControlledReferenceSelectorProps<T>
) => {
  const getReferencePickerFiltersByObjectName = (
    objectName: string,
    recordDetails: Record<string, any> | undefined
  ): RulesComponentListViewFilter => {
    switch (objectName) {
      case 'controlled_document__a': {
        const filter = {
          conditions: {
            all: [
              {
                all: [
                  {
                    attribute: 'documentStatus',
                    operator: OperatorsEnum.IN,
                    value: [
                      'Approved',
                      'Effective',
                      'In Draft',
                      'In Review',
                      'In Approval',
                    ],
                  },
                ],
              },
            ],
          },
        };
        if (recordDetails?.name) {
          filter.conditions.all[0].all[1] = {
            attribute: 'name',
            operator: OperatorsEnum.NOT_EQUALS,
            value: recordDetails?.name,
          };
        }
        return filter;
      }
      case 'course__a':
      case 'curriculum__a':
        return {
          conditions: {
            all: [
              {
                all: [
                  {
                    attribute: 'version',
                    operator: OperatorsEnum.ENDS_WITH,
                    value: '.0',
                  },
                  {
                    attribute: 'isActive',
                    operator: OperatorsEnum.EQUALS,
                    value: true,
                  },
                  {
                    attribute: 'status',
                    operator: OperatorsEnum.EQUALS,
                    value: 'Approved',
                  },
                ],
              },
            ],
          },
        };

      case 'smart_groups__a':
      case 'user__s':
        return {
          conditions: {
            all: [
              {
                all: [
                  {
                    attribute: 'isActive',
                    operator: OperatorsEnum.EQUALS,
                    value: true,
                  },
                ],
              },
            ],
          },
        };
      default:
        return {};
    }
  };

  return (
    <ControlledReferencePickerView
      {...{ ...props, getReferencePickerFiltersByObjectName }}
    />
  );
};
