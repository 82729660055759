import { useState } from 'react';

import { FilePickerProps } from './file-picker.model';

const useFilePicker = <T extends boolean>({
  value,
  allowedExtensions = [],
  multiple,
  onChange,
  onError,
}: FilePickerProps<T>) => {
  const [files, setFiles] = useState<File[]>(() => {
    let initialFiles: File[] = [];

    if (Array.isArray(value)) {
      initialFiles = value;
    } else if (value) {
      initialFiles = [value];
    }
    return initialFiles;
  });

  const isValidFile = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension) return false;
    return (
      allowedExtensions.length === 0 ||
      allowedExtensions.includes(`.${fileExtension}`)
    );
  };

  const validateFiles = (
    files: File[]
  ): { validFiles: File[]; invalidFiles: File[] } => {
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];
    files.forEach((file) => {
      if (isValidFile(file)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });
    return { validFiles, invalidFiles };
  };

  const handleFiles = (selectedFiles: FileList): void => {
    const { validFiles, invalidFiles } = validateFiles(
      Array.from(selectedFiles)
    );
    const error = invalidFiles.length
      ? `Invalid file type. Only ${allowedExtensions.join(
          ', '
        )} files are allowed.`
      : null;

    const newFiles = multiple ? [...files, ...validFiles] : validFiles;
    setFiles(newFiles);
    onChange(
      (multiple ? newFiles : newFiles[0]) as T extends true ? File[] : File
    );
    onError?.(error);
  };

  const removeFile = (fileToRemove: File): void => {
    const newfiles = files.filter((file) => file.name !== fileToRemove.name);

    setFiles(newfiles);
    onChange(
      (multiple ? newfiles : newfiles[0]) as T extends true ? File[] : File
    );
  };

  return {
    files,
    handleFiles,
    removeFile,
  };
};

export default useFilePicker;
