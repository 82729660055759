import { useBreadcrumbs } from '@celito.clients/shared';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

interface RecordNavigationProviderProps {
  children: React.ReactNode;
}

type RecordNavigationContextType = {
  navItems: RecordNav;
  addRecord: (id: string, record: string) => void;
  setIsRelatedObject: (id: string, isRelatedObject: boolean) => void;
  isRelatedObject: (id: string) => boolean;
  setCurrentIndex: (id: string, currentIndex: number) => void;
  goToNext: (id: string) => void;
  goToPrevious: (id: string) => void;
  updateNavItems: (navItems: RecordNav) => void;
  currentnavItem: NavItem;
  previousNavItem: NavItem;
};

type NavItem = {
  currentIndex: number;
  records: string[];
  page: number;
  totalRecords: number;
  isRelatedObject: boolean;
  isLast: boolean;
  isFirst: boolean;
  position: string;
};

type RecordNav = Record<string, NavItem>;

const RecordNavigationContext = createContext<
  RecordNavigationContextType | undefined
>(undefined);

export const RecordNavigationProvider: React.FC<
  RecordNavigationProviderProps
> = ({ children }) => {
  const [navItems, setNavItems] = useState<RecordNav>({});
  const { breadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const addRecord = useCallback(
    (id: string, record: string) => {
      setNavItems((prev) => {
        const existingRecords = prev[id]?.records || [];

        if (existingRecords.includes(record)) {
          return prev;
        }

        return {
          ...prev,
          [id]: {
            ...prev[id],
            currentIndex: 0,
            records: [...existingRecords, record],
            page: 1,
            totalRecords: existingRecords.length + 1,
            isLast: false,
            isFirst: true,
            position: `1 of ${existingRecords.length + 1}`,
          },
        };
      });
    },
    [setNavItems]
  );

  const updateNavItems = useCallback(
    (navItems: RecordNav) => {
      setNavItems(navItems);
    },
    [setNavItems]
  );

  const setIsRelatedObject = useCallback(
    (id: string, isRelatedObject: boolean) => {
      setNavItems((prev) => {
        return {
          ...prev,
          [id]: {
            ...prev[id],
            isRelatedObject,
          },
        };
      });
    },
    [setNavItems]
  );

  const isRelatedObject = useCallback(
    (id: string) => {
      return navItems[id]?.isRelatedObject || false;
    },
    [navItems]
  );

  const setCurrentIndex = useCallback(
    (id: string, currentIndex: number) => {
      setNavItems((prev) => {
        return {
          ...prev,
          [id]: {
            ...prev[id],
            currentIndex,
            isFirst: currentIndex === 0,
            isLast: currentIndex === prev[id]?.records.length - 1,
            position: `${currentIndex + 1} of ${prev[id]?.records.length}`,
          },
        };
      });
    },
    [setNavItems]
  );

  const goToNext = useCallback(
    (id: string) => {
      setNavItems((prev) => {
        const currentIndex = prev[id]?.currentIndex || 0;
        const records = prev[id]?.records || [];

        if (currentIndex >= records.length - 1) {
          return prev;
        }

        const newIndex = currentIndex + 1;
        navigate(records[newIndex], { replace: true });

        return {
          ...prev,
          [id]: {
            ...prev[id],
            currentIndex: newIndex,
            isFirst: newIndex === 0,
            isLast: newIndex === records.length - 1,
            position: `${newIndex + 1} of ${records.length}`,
          },
        };
      });
    },
    [setNavItems, navigate]
  );

  const goToPrevious = useCallback(
    (id: string) => {
      setNavItems((prev) => {
        const currentIndex = prev[id]?.currentIndex ?? 0;
        const records = prev[id]?.records || [];

        if (currentIndex <= 0) {
          return prev;
        }

        const newIndex = currentIndex - 1;
        navigate(records[newIndex], { replace: true });

        return {
          ...prev,
          [id]: {
            ...prev[id],
            currentIndex: newIndex,
            isFirst: newIndex === 0,
            isLast: newIndex === records.length - 1,
            position: `${newIndex + 1} of ${records.length}`,
          },
        };
      });
    },
    [setNavItems, navigate]
  );

  useEffect(() => {
    const navItemsCopy: RecordNav = {};
    for (let i = 0; i <= breadcrumbs.length; i++) {
      const navigationId = breadcrumbs.slice(0, i + 1).join('_');
      if (navItems[navigationId]) {
        navItemsCopy[navigationId] = navItems[navigationId];
      }
    }
    updateNavItems(navItemsCopy);
  }, [breadcrumbs.join('_')]);

  const getCurrentNavItem = useCallback(() => {
    const navigationId = breadcrumbs.slice(0, -1).join('_');
    return navItems[navigationId];
  }, [navItems]);

  const getPrevioustNavItem = useCallback(() => {
    const navigationId = breadcrumbs.join('_');
    return navItems[navigationId];
  }, [navItems]);

  const value = useMemo(
    () => ({
      navItems,
      addRecord,
      setIsRelatedObject,
      isRelatedObject,
      setCurrentIndex,
      goToNext,
      goToPrevious,
      updateNavItems,
      currentnavItem: getCurrentNavItem(),
      previousNavItem: getPrevioustNavItem(),
    }),
    [
      navItems,
      addRecord,
      setIsRelatedObject,
      isRelatedObject,
      setCurrentIndex,
      goToNext,
      goToPrevious,
      updateNavItems,
    ]
  );

  return (
    <RecordNavigationContext.Provider value={value}>
      {children}
    </RecordNavigationContext.Provider>
  );
};

export const useRecordNavigation = (): RecordNavigationContextType => {
  const context = useContext(RecordNavigationContext);
  if (!context) {
    throw new Error(
      'useRecordNavigation must be used within a RecordNavigationProvider'
    );
  }
  return context;
};
