import { FormEngine } from '@celito.clients/form-engine';

import { UserLearningComponentProps } from './user-learning.model';

export const UserLearningView: React.FC<UserLearningComponentProps> = (
  props
) => {
  return (
    <FormEngine key={`${props.viewName}-${props.recordName}`} {...props} />
  );
};
