import { Dependency, MetadataItem } from '@celito.clients/enums';
import {
  HeadlessFlatTreeItemProps,
  TreeItemValue,
} from '@fluentui/react-components';

export type CustomItem = HeadlessFlatTreeItemProps &
  Pick<MetadataItem, 'errors' | 'dependencies' | 'text'>;

export function transformMetadataToTreeItems(
  metadata: MetadataItem[],
  parentValue: string | null = null,
  currentIndex = 0
) {
  const treeItems: CustomItem[] = [];

  metadata.forEach((item, index) => {
    const value = parentValue ? `${parentValue}-${index}` : `${currentIndex++}`;

    const treeItem: CustomItem = {
      value: value,
      parentValue: parentValue ?? undefined,
      text: item.text,
      errors: item.errors,
      dependencies: item.dependencies,
    };

    treeItems.push(treeItem);

    if (item.children && item.children.length > 0) {
      const childItems = transformMetadataToTreeItems(item.children, value);
      treeItems.push(...childItems);
    }
  });

  return treeItems;
}

export function getSelectedMetadata(
  nestedArray: MetadataItem[],
  checkedItems: Set<TreeItemValue>
): MetadataItem[] {
  function filterRecursively(
    array: MetadataItem[],
    path: string[] = []
  ): MetadataItem[] {
    return array.reduce<MetadataItem[]>((result, node, index) => {
      const currentPath = [...path, index.toString()];
      const key = currentPath.join('-');

      const shouldKeep = checkedItems.has(key);

      if (shouldKeep) {
        result.push(node);
      } else if (node.children) {
        const filteredChildren = filterRecursively(node.children, currentPath);
        if (filteredChildren.length > 0) {
          result.push({ ...node, children: filteredChildren });
        }
      }

      return result;
    }, []);
  }

  return filterRecursively(nestedArray);
}

export function getItem(index: string, metadata: MetadataItem[]) {
  const indexes = index.split('-').map(Number);
  let nestedData: MetadataItem = metadata[indexes[0]];

  for (let i = 1; nestedData && i < indexes.length; i++) {
    nestedData = nestedData?.children?.[indexes[i]] as MetadataItem;
  }

  return nestedData;
}

export function getDependenciesLabels(
  dependencies: Dependency[],
  metadata: MetadataItem[]
) {
  const indexes: string[] = [];
  const labels = new Set<string>();

  dependencies.forEach((dep) => {
    const label: string[] = [];
    const indexNumber: string[] = [];
    let nestedData: MetadataItem[] = metadata;

    dep.valuePath.split('.').forEach((itemName) => {
      let i = 0;
      const temp = nestedData.find((item, index) => {
        i = index;
        return item.value === itemName;
      });
      nestedData = temp?.children || [];
      if (temp) {
        indexNumber.push(i.toString());
        label.push(temp.text);
      }
    });

    indexes.push(indexNumber.join('-'));
    labels.add(label.join(' > '));
  });

  return { labels: Array.from(labels), indexes };
}
