import { MsalProvider } from '@azure/msal-react';
import { LayoutProvider } from '@celito.clients/context';
import {
  RecordNavigationProvider,
  ToastProvider,
} from '@celito.clients/provider';
import { logger } from '@celito.clients/services';
import { CustomRouter } from '@celito.clients/shared';
import { Environment, MsalInstanceManager } from '@celito.clients/singleton';
import { lightTheme, ThemeProvider } from '@celito.clients/theme';
import { history } from '@celito.clients/utils';
import { FluentProvider } from '@fluentui/react-components';
import queryClient from 'libs/core/src/config/query-client';
import * as ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';

import { environment } from '../../../libs/core/src/config/environment';
import App from './app/app';
import { ScriptService } from './utils/script-service';

const root = ReactDOM.createRoot(document.getElementById('root')!);

/* catch preload event */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
window.addEventListener('vite:preloadError', (event: any) => {
  logger.error(event?.payload ?? 'Dynamic import failed');
  window.location.reload();
});

const msalInstance = MsalInstanceManager.getInstance().getMsalInstance();

Environment.getInstance().setEnvironment(environment);
ScriptService.getInstance().loadAllForCurrentEnv();

const Main = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <CustomRouter history={history}>
          <FluentProvider theme={lightTheme}>
            <ThemeProvider>
              <ToastProvider>
                <LayoutProvider>
                  <RecordNavigationProvider>
                    <App />
                  </RecordNavigationProvider>
                </LayoutProvider>
              </ToastProvider>
            </ThemeProvider>
          </FluentProvider>
        </CustomRouter>
      </QueryClientProvider>
    </MsalProvider>
  );
};

root.render(<Main />);
